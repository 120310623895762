import { ACTION, ACTION_SUBJECT, EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import React from 'react';
import AnalyticsContext from '../../analytics/analyticsContext';
import { ElementSelection } from './element-selection';
export var useSelectAllTrap = function useSelectAllTrap() {
  var _React$useContext = React.useContext(AnalyticsContext),
    fireAnalyticsEvent = _React$useContext.fireAnalyticsEvent;
  var ref = React.useRef(null);
  var clicked = React.useRef(false);
  var caught = React.useRef();
  var mac = typeof navigator !== 'undefined' ? /Mac/.test(navigator.platform) : false;
  var onKeyDown = React.useCallback(function (e) {
    var el = ref.current;
    if (!el) {
      return;
    }
    var modKey = mac ? e.metaKey : e.ctrlKey;
    if (!modKey || e.code !== 'KeyA' || e.shiftKey) {
      return;
    }
    var elementSelection = ElementSelection.fromWindow();
    if (elementSelection.eq(caught.current)) {
      fireAnalyticsEvent({
        eventType: EVENT_TYPE.TRACK,
        action: ACTION.SELECT_ALL_ESCAPED,
        actionSubject: ACTION_SUBJECT.RENDERER
      });
      return;
    }
    if (elementSelection.inside(el) || elementSelection.type === 'None' && clicked.current) {
      fireAnalyticsEvent({
        eventType: EVENT_TYPE.TRACK,
        action: ACTION.SELECT_ALL_CAUGHT,
        actionSubject: ACTION_SUBJECT.RENDERER
      });
      e.preventDefault();
      caught.current = elementSelection.select(el);
    }
  }, [mac, ref, fireAnalyticsEvent, clicked, caught]);
  var onClick = React.useCallback(function (e) {
    var _ref$current$contains, _ref$current;
    clicked.current = (_ref$current$contains = (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.contains(e.target)) !== null && _ref$current$contains !== void 0 ? _ref$current$contains : false;
  }, [ref, clicked]);
  React.useEffect(function () {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('click', onClick);
    return function () {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('click', onClick);
    };
  }, [onKeyDown, onClick]);
  return ref;
};