import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { normalizeLocale } from '../common';
import { createDateParser } from '../date-parser';
import { toFormattedParts } from './toFormattedParts';
export var createLocalizationProvider = function createLocalizationProvider(locale, formatterOptions) {
  // Intl.DateFormat expects locales in the format of 'la-CO' however it is
  // common for locale to be provided in the format of 'la_CO', where 'la' is
  // language and 'CO' is country.
  var normalizedLocale = normalizeLocale(locale);
  var formatDate = function formatDate(date) {
    return Intl.DateTimeFormat(normalizedLocale).format(date);
  };
  var formatTime = function formatTime(date) {
    return Intl.DateTimeFormat(normalizedLocale, {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  };
  var getDays = function getDays() {
    var weekStartDay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var length = arguments.length > 1 ? arguments[1] : undefined;
    var dayFormatter = Intl.DateTimeFormat(normalizedLocale, {
      weekday: length
    });

    // Right now there is no way to find out first day of the week based on Intl (locale)
    // api. Check issue here: https://github.com/tc39/ecma402/issues/6
    // So we rotate the weekdays based on #weekStartDay parameter.
    var weekdays = [0, 1, 2, 3, 4, 5, 6];
    var rotatedWeekdays = weekStartDay > 0 ? [].concat(_toConsumableArray(weekdays.slice(weekStartDay)), _toConsumableArray(weekdays.slice(0, weekStartDay))) : weekdays;
    return rotatedWeekdays.map(function (day) {
      // Some short days are longer than 3 characters but are unique if the first
      // three non-white characters are used.
      var result = dayFormatter
      // Date range chosen which has a Sun-Sat range so we can extract the names
      .format(new Date(2000, 9, day + 1, 12))
      // \u200E matches on the Left-to-Right Mark character in IE/Edge
      .replace(/[\s\u200E]/g, '');

      // If short days, only return first three characters. Else return whole thing
      if (length === 'short') {
        return result.substring(0, 3);
      } else {
        return result;
      }
    });
  };
  var getDaysShort = function getDaysShort() {
    var weekStartDay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    return getDays(weekStartDay, 'short');
  };
  var getDaysLong = function getDaysLong() {
    var weekStartDay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    return getDays(weekStartDay, 'long');
  };
  var getMonthsLong = function getMonthsLong() {
    var monthFormatter = Intl.DateTimeFormat(normalizedLocale, {
      month: 'long'
    });
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (month) {
      return (
        // Date chosen for no real reason, the only important part is the month
        // Using 2020 'cos Safari has a faulty implementation when we use the year 2000
        // Intl.DateTimeFormat("en-US", { month: 'long'}).format(new Date(2000, 3, 1))
        // should give "April" but gives "March" in Safari
        monthFormatter.format(new Date(2020, month, 1))
      );
    });
  };
  var parseDate = function parseDate(date) {
    return createDateParser(normalizedLocale)(date);
  };
  var formatToParts = function formatToParts() {
    var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
    var formatter = new Intl.DateTimeFormat(normalizedLocale, formatterOptions);
    /**
     * Safari has a bug that returns the wrong month for years 2005 and before.
     * The error occurs when the passed date has been created with numbers, like new Date(2000, 3, 1)
     * Not all the months fail in each year. To ensure the correct output,
     * we select 2020 as the base year for the whole date,
     * then we replace in the result the original input year
     */
    var fixedDate = new Date(date);
    fixedDate.setFullYear(2020);
    var fixedParts = toFormattedParts(formatter.formatToParts(fixedDate));
    var originalParts = toFormattedParts(formatter.formatToParts(date));
    if (fixedParts.year) {
      fixedParts.year = originalParts.year;
    }

    /**
     * Chrome has a bug that returns hour="00" when it's 12:00pm in certain languages
     * We fix it by detecting the real time with getHours method
     */
    if (fixedParts.hour === '00' && fixedDate.getHours() === 12) {
      fixedParts.hour = '12';
    }
    return fixedParts;
  };
  return {
    getDaysShort: getDaysShort,
    getDaysLong: getDaysLong,
    getMonthsLong: getMonthsLong,
    formatDate: formatDate,
    formatTime: formatTime,
    parseDate: parseDate,
    formatToParts: formatToParts
  };
};