import { useCallback } from 'react';

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default function useFocusing(_ref) {
  var onBlur = _ref.onBlur,
    onFocus = _ref.onFocus;
  var handleContainerBlur = useCallback(function (event) {
    onBlur(event);
  }, [onBlur]);
  var handleContainerFocus = useCallback(function (event) {
    onFocus(event);
  }, [onFocus]);
  return {
    handleContainerBlur: handleContainerBlur,
    handleContainerFocus: handleContainerFocus
  };
}