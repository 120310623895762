import { createMediaSubject } from '../createMediaSubject';
export function fromObservable(observable) {
  return {
    subscribe: function subscribe(observer) {
      var subscription =
      // This is needed to handle "subscribe" function overload.
      // It allows accepting a single "next" callback function as an argument.
      observer instanceof Function ? observable.subscribe(observer) : observable.subscribe(observer);
      return {
        unsubscribe: function unsubscribe() {
          subscription.unsubscribe();
        }
      };
    }
  };
}
export function createMediaSubscribable(item) {
  return fromObservable(createMediaSubject(item));
}