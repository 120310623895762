import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/* eslint-disable @atlaskit/design-system/no-css-tagged-template-expression */
import { css } from '@emotion/react';
import { akEditorLineHeight, akEditorTableCellMinWidth } from '@atlaskit/editor-shared-styles';
export var TaskDecisionSharedCssClassName = {
  DECISION_CONTAINER: 'decisionItemView-content-wrap',
  TASK_CONTAINER: 'taskItemView-content-wrap',
  TASK_ITEM: 'task-item'
};
export var tasksAndDecisionsStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .ProseMirror {\n    .taskItemView-content-wrap,\n    .", " {\n      position: relative;\n      min-width: ", "px;\n    }\n\n    .", " {\n      margin-top: 0;\n    }\n\n    .", " {\n      span[contenteditable='false'] {\n        height: ", "em;\n      }\n    }\n\n    .", " {\n      line-height: ", ";\n    }\n  }\n\n  div[data-task-local-id] {\n    span[contenteditable='false'] {\n      height: ", "em;\n    }\n    span[contenteditable='false'] + div {\n      line-height: ", "em;\n    }\n  }\n\n  div[data-task-list-local-id] {\n    margin: ", " 0 0 0;\n  }\n\n  div[data-task-list-local-id] {\n    // If task item is not first in the list then set margin top to 4px.\n    div + div {\n      margin-top: ", ";\n    }\n  }\n\n  // If task list is not first in the document then set margin top to 4px.\n  div[data-task-list-local-id] div[data-task-list-local-id] {\n    margin-top: ", ";\n    margin-left: ", ";\n  }\n\n  /* When action list is inside panel */\n  .ak-editor-panel__content {\n    > div[data-task-list-local-id]:first-child {\n      margin: 0 0 0 0 !important;\n    }\n  }\n"])), TaskDecisionSharedCssClassName.DECISION_CONTAINER, akEditorTableCellMinWidth, TaskDecisionSharedCssClassName.DECISION_CONTAINER, TaskDecisionSharedCssClassName.TASK_CONTAINER, akEditorLineHeight, TaskDecisionSharedCssClassName.TASK_ITEM, akEditorLineHeight, akEditorLineHeight, akEditorLineHeight, "var(--ds-space-150, 12px)", "var(--ds-space-050, 4px)", "var(--ds-space-050, 4px)", "var(--ds-space-300, 24px)");