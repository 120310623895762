/** @jsx jsx */

import { jsx } from '@emotion/react';
import { Grid } from '@atlaskit/primitives';
/**
 * __Week day grid__
 *
 * A week day grid aligns elements in a 7 wide grid layout.
 *
 */
var WeekDayGrid = function WeekDayGrid(_ref) {
  var testId = _ref.testId,
    children = _ref.children,
    isHidden = _ref.isHidden;
  var row = jsx(Grid, {
    testId: testId,
    templateColumns: "repeat(7, minmax(max-content, 1fr))",
    role: "row"
  }, children);
  return isHidden ? jsx("div", {
    "aria-hidden": "true"
  }, row) : row;
};
export default WeekDayGrid;