import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import { B100, B300, B400, B50, B75, DN50, DN70, N0, N100, N20, N30, N40, N50, N500, N700, N900, R300, R400, R50, R75 } from '@atlaskit/theme/colors';
import { codeFontFamily, fontSize as defaultFontSize,
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
gridSize, layers } from '@atlaskit/theme/constants';
export var akEditorFullPageDefaultFontSize = 16;
export var akEditorCodeFontFamily = codeFontFamily();
export var akEditorInactiveForeground = N500;
export var akEditorFocus = B100;
export var akEditorSubtleAccent = N40;
export var akEditorActiveBackground = N500;
export var akEditorActiveForeground = N0;
export var akEditorBlockquoteBorderColor = N40;
export var akEditorDropdownActiveBackground = N900;
export var akEditorPopupBackground = N700;
export var akEditorPopupText = B50;
export var akEditorPrimaryButton = B400;
export var akEditorCodeBackground = N20;
export var akEditorCodeBlockPadding = '12px';
export var akEditorCodeInlinePadding = '2px 4px';
export var akEditorDeleteBackground = R50;
export var akEditorDeleteBackgroundShaded = R50;
export var akEditorDeleteBackgroundWithOpacity = 'rgba(255, 189, 173, 0.5)'; // R75 with 50% opacity
export var akEditorDeleteBorder = R300;
export var akEditorDeleteIconColor = R400;
export var akEditorCustomIconSize = 20;
export var akEditorSelectedBorderColor = B300;
export var akEditorSelectedBgColor = B50;
export var akEditorSelectedBlanketColor = B75;
export var akEditorSelectedBorderSize = 1;
export var akEditorSelectedBorder = "".concat(akEditorSelectedBorderSize, "px solid ", "var(--ds-border-selected, ".concat(akEditorSelectedBorderColor, ")"));
export var akEditorSelectedBoxShadow = "0 0 0 ".concat(akEditorSelectedBorderSize, "px ", "var(--ds-border-selected, ".concat(akEditorSelectedBorderColor, ")"));
export var akEditorSelectedIconColor = B400;
export var akEditorSelectedBorderBoldSize = 2;
export var akEditorSelectedBlanketOpacity = 0.3;
export var akEditorUnitZIndex = 1;
export var akEditorShadowZIndex = 2;
export var akEditorStickyHeaderZIndex = 11; // filmstrip uses 10 for its shadow
export var akEditorSmallZIndex = akEditorStickyHeaderZIndex + 1;
export var akEditorGridLineZIndex = 1;
// z-index for main menu bar -
// this is highest as it should be above anything else in editor below.
export var akEditorMenuZIndex = layers.blanket();
// z-index used for floating toolbars like code block, table etc
export var akEditorFloatingPanelZIndex = layers.layer();
// z-index used for pickers (date, emoji, mentions) and type-aheads, hyperlinks
export var akEditorFloatingDialogZIndex = akEditorMenuZIndex + 10;
// z-index used for table cell menu options button on a sticky header
export var akEditorTableCellOnStickyHeaderZIndex = akEditorFloatingDialogZIndex - 5;
// z-index used for floating toolbars table cell menu which are above block toolbars
export var akEditorFloatingOverlapPanelZIndex = akEditorFloatingPanelZIndex + 5;
export var akEditorMentionSelected = N100;
export var akEditorTableToolbarSize = 11;
export var akEditorTableBorder = N50;
export var akEditorTableBorderDark = DN70;
export var akEditorTableToolbar = N20;
export var akEditorTableToolbarDark = DN50;
export var akEditorTableFloatingControls = N20;
export var akEditorTableCellSelected = B75;
export var akEditorTableHeaderCellBackground = '#F1F2F4';
export var akEditorTableHeaderCellBackgroundDark = '#2C333A';
export var akEditorTableToolbarSelected = B100;
export var akEditorTableBorderSelected = B300;
export var akEditorTableCellDelete = R50;
export var akEditorTableBorderDelete = R300;
export var akEditorTableToolbarDelete = R75;
export var akEditorTableBorderRadius = '3px';
export var akEditorTableCellBackgroundOpacity = 0.5;
export var akEditorFullPageMaxWidth = 680;
export var akEditorDefaultLayoutWidth = 760;
export var akEditorWideLayoutWidth = 960; // This value is used for tables with breakouts and is used to calculate `breakoutWidthPx`
export var akEditorFullWidthLayoutWidth = 1800;
export var akEditorFullWidthLayoutLineLength = 1792;
export var akEditorTableNumberColumnWidth = 42;
export var akEditorBreakoutPadding = 96;
export var akEditorGutterPadding = 32;
export var akEditorMobileBreakoutPoint = 720;
export var akEditorTableCellMinWidth = 48;
export var akEditorTableLegacyCellMinWidth = 128;
export var akEditorMediaResizeHandlerPaddingWide = 12;
export var akEditorMediaResizeHandlerPadding = 4;
export var akEditorSwoopCubicBezier = "cubic-bezier(0.15, 1, 0.3, 1)";
export var gridMediumMaxWidth = 1024;
export var breakoutWideScaleRatio = 1.33;
export var akEditorCalculatedWideLayoutWidth = 1011; // = akEditorDefaultLayoutWidth * breakoutWideScaleRatio = 1010.8 ~ 1011 This is a resulting width value that is applied to nodes that currently use breakouts (except table) and are set to `wide` when the viewport's width is > 1329px.
export var akRichMediaResizeZIndex = akEditorUnitZIndex * 99;
export var akLayoutGutterOffset = gridSize() * 1.5;
export var akEditorLineHeight = 1.714;
export var akEditorRuleBackground = N30;
export var akEditorRuleBorderRadius = '1px';
export var akEditorToolbarKeylineHeight = 2;
export var akEditorContextPanelWidth = 320;
export var akEditorTableCellBlanketSelected = 'rgba(179, 212, 255, 0.3)';
export var akEditorTableCellBlanketDeleted = 'rgba(255, 235, 230, 0.3)';

// @see typography spreadsheet: https://docs.google.com/spreadsheets/d/1iYusRGCT4PoPfvxbJ8NrgjtfFgXLm5lpDWXzjua1W2E/edit#gid=93913128
export var blockNodesVerticalMargin = '0.75rem';
export var DEFAULT_EMBED_CARD_WIDTH = 680;
export var DEFAULT_EMBED_CARD_HEIGHT = 480;
export var MAX_BROWSER_SCROLLBAR_HEIGHT = 20;
export var ATLASSIAN_NAVIGATION_HEIGHT = '56px';
export var akEditorSelectedNodeClassName = 'ak-editor-selected-node';
export var editorFontSize = function editorFontSize(_ref) {
  var theme = _ref.theme;
  return theme && theme.baseFontSize ? theme.baseFontSize : defaultFontSize();
};
export var relativeSize = function relativeSize(multiplier) {
  return function (_ref2) {
    var theme = _ref2.theme;
    return editorFontSize({
      theme: theme
    }) * multiplier;
  };
};
export var relativeFontSizeToBase16 = function relativeFontSizeToBase16(px) {
  if (typeof px === 'string') {
    px = parseInt(px);
  }
  if (isNaN(px)) {
    throw new Error("Invalid font size: '".concat(px, "'"));
  }
  return "".concat(px / 16, "rem");
};
export var VIEWPORT_SIZES = {
  laptopHiDPI: {
    width: 1440,
    height: 900
  },
  laptopMDPI: {
    width: 1280,
    height: 800
  },
  tabletL: {
    width: 1024,
    height: 1366
  },
  tabletS: {
    width: 768,
    height: 1024
  },
  mobileM: {
    width: 414,
    height: 736
  },
  mobileS: {
    width: 375,
    height: 667
  },
  mobileXS: {
    width: 320,
    height: 568
  }
};

// to be updated in ED-10790: this should be variable threshold based on how many buttons enabled on main toolbar
export var akEditorMobileMaxWidth = 0;
export var getTableCellBackgroundDarkModeColors = [['White', '#000000'], ['Light blue', '#0C294F'], ['Light teal', '#0C343B'], ['Light green', '#052E21'], ['Light yellow', '#484123'], ['Light red', '#441C13'], ['Light purple', '#282249'], ['Light gray', '#202328'], ['Blue', '#0B3165'], ['Teal', '#044853'], ['Green', '#053927'], ['Yellow', '#6F5C25'], ['Red', '#582013'], ['Purple', '#3E327B'], ['Gray', '#475262'], ['Dark blue', '#003884'], ['Dark teal', '#055866'], ['Dark green', '#044932'], ['Dark yellow', '#82641C'], ['Dark red', '#6B2A19'], ['Dark purple', '#4D38B2']].map(function (_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
    colorName = _ref4[0],
    colorValue = _ref4[1];
  return getTableCellBackgroundDarkModeColorCSS(colorName, colorValue);
}).join('\n');
export function getTableCellBackgroundDarkModeColorCSS(colorName, colorValue) {
  return "\n  &[colorname=\"".concat(colorName, "\"] {\n    background-color: ").concat(colorValue, " !important; // !important to override default style color\n  }\n  ");
}