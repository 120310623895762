import { B200, B400, B50, N0, N200, N30, N40, N500, N600, N900 } from '@atlaskit/theme/colors';
export var dateCellStyles = function dateCellStyles() {
  return {
    all: 'unset',
    display: 'block',
    padding: "var(--ds-space-050, 4px)".concat(" 9px"),
    position: 'relative',
    backgroundColor: 'transparent',
    border: '2px solid transparent',
    borderRadius: 3,
    color: "var(--ds-text, ".concat(N900, ")"),
    cursor: 'pointer',
    flexGrow: 1,
    font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
    textAlign: 'center',
    '&[data-sibling]': {
      color: "var(--ds-text-subtlest, ".concat(N200, ")")
    },
    '&[data-today]': {
      color: "var(--ds-text-selected, ".concat(B400, ")"),
      fontWeight: "var(--ds-font-weight-bold, bold)",
      '&::after': {
        display: 'block',
        height: 2,
        position: 'absolute',
        right: "var(--ds-space-025, 2px)",
        bottom: "var(--ds-space-025, 2px)",
        left: "var(--ds-space-025, 2px)",
        backgroundColor: 'currentColor',
        content: '""'
      }
    },
    '&[data-prev-selected]': {
      backgroundColor: "var(--ds-background-selected, ".concat(B50, ")"),
      color: "var(--ds-text-subtle, ".concat(N600, ")")
    },
    '&[data-selected]': {
      backgroundColor: "var(--ds-background-selected, ".concat(N500, ")"),
      color: "var(--ds-text-selected, ".concat(N0, ")")
    },
    '&[data-disabled]': {
      color: "var(--ds-text-disabled, ".concat(N40, ")"),
      cursor: 'not-allowed'
    },
    '&:focus-visible': {
      border: "2px solid ".concat("var(--ds-border-focused, ".concat(B200, ")"))
    },
    '&:hover': {
      backgroundColor: "var(--ds-background-neutral-subtle-hovered, ".concat(N30, ")"),
      color: "var(--ds-text, ".concat(N900, ")")
    },
    '&:active': {
      backgroundColor: "var(--ds-background-neutral-subtle-pressed, ".concat(B50, ")"),
      color: "var(--ds-text, ".concat(N900, ")")
    },
    '&[data-selected]:hover': {
      backgroundColor: "var(--ds-background-selected-hovered, ".concat(B50, ")"),
      color: "var(--ds-text-selected, ".concat(N600, ")")
    },
    '&[data-prev-selected]:hover': {
      color: "var(--ds-text-subtle, ".concat(N600, ")")
    },
    '&[data-sibling]:hover': {
      color: "var(--ds-text-subtlest, ".concat(N200, ")")
    },
    '&[data-disabled]:hover': {
      backgroundColor: 'transparent',
      color: "var(--ds-text-disabled, ".concat(N40, ")")
    }
  };
};