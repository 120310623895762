import _extends from "@babel/runtime/helpers/extends";
/** @jsx jsx */
import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { InsertDraftPosition } from '../types';
import { AnnotationsDraftContext } from '../context';
import { splitText, calcTextSplitOffset, findTextString } from './text';
import { calcInsertDraftPositionOnText } from './position';
import { dataAttributes } from './dom';
import { AnnotationSharedCSSByState } from '@atlaskit/editor-common/styles';
import { segmentText } from '../../../react/utils/segment-text';
import { renderTextSegments } from '../../../react/utils/render-text-segments';
var markStyles = function markStyles() {
  return css({
    color: 'inherit',
    backgroundColor: 'unset',
    WebkitTapHighlightColor: 'transparent'
  }, AnnotationSharedCSSByState().focus);
};
export var AnnotationDraft = function AnnotationDraft(_ref) {
  var draftPosition = _ref.draftPosition,
    children = _ref.children;
  return jsx("mark", _extends({
    "data-renderer-mark": true
  }, dataAttributes(draftPosition), {
    css: markStyles
  }), children);
};
export var getAnnotationIndex = function getAnnotationIndex(annotationPosition, fragmentCount) {
  if (annotationPosition === InsertDraftPosition.START) {
    return 0;
  }
  if (annotationPosition === InsertDraftPosition.END) {
    return fragmentCount - 1;
  }
  if (annotationPosition === InsertDraftPosition.INSIDE && fragmentCount === 3) {
    return 1;
  }
  return -1;
};
export var applyAnnotationOnText = function applyAnnotationOnText(_ref2) {
  var texts = _ref2.texts,
    shouldApplyAnnotationAt = _ref2.shouldApplyAnnotationAt,
    draftPosition = _ref2.draftPosition,
    textHighlighter = _ref2.textHighlighter,
    marks = _ref2.marks;
  var annotateIndex = getAnnotationIndex(shouldApplyAnnotationAt, texts.length);
  return texts.map(function (value, index) {
    var segments = segmentText(value, textHighlighter);
    if (annotateIndex === index) {
      return jsx(AnnotationDraft, {
        key: index,
        draftPosition: draftPosition
      }, renderTextSegments(segments, textHighlighter, marks || [], draftPosition.from));
    }
    return jsx(React.Fragment, {
      key: index
    }, renderTextSegments(segments, textHighlighter, marks || [], draftPosition.from));
  });
};
export var TextWithAnnotationDraft = function TextWithAnnotationDraft(_ref3) {
  var startPos = _ref3.startPos,
    endPos = _ref3.endPos,
    children = _ref3.children,
    textHighlighter = _ref3.textHighlighter,
    marks = _ref3.marks;
  var textPosition = React.useMemo(function () {
    return {
      start: startPos,
      end: endPos
    };
  }, [endPos, startPos]);
  var nextDraftPosition = React.useContext(AnnotationsDraftContext);
  var shouldApplyAnnotationAt = React.useMemo(function () {
    if (!nextDraftPosition) {
      return false;
    }
    return calcInsertDraftPositionOnText(textPosition, nextDraftPosition);
  }, [nextDraftPosition, textPosition]);
  var textString = findTextString(children);
  if (!textString) {
    return jsx(Fragment, null, children);
  }
  if (shouldApplyAnnotationAt === false || !nextDraftPosition) {
    var segments = segmentText(textString, textHighlighter);
    return jsx(Fragment, null, renderTextSegments(segments, textHighlighter, marks || [], startPos));
  }
  if (shouldApplyAnnotationAt === InsertDraftPosition.AROUND_TEXT) {
    var _segments = segmentText(textString, textHighlighter);
    return jsx(AnnotationDraft, {
      key: 0,
      draftPosition: nextDraftPosition
    }, renderTextSegments(_segments, textHighlighter, marks || [], startPos));
  }
  var offsets = calcTextSplitOffset(nextDraftPosition, textPosition, textString);
  var texts = splitText(textString, offsets);
  if (!texts) {
    var _segments2 = segmentText(textString, textHighlighter);
    return jsx(Fragment, null, renderTextSegments(_segments2, textHighlighter, marks || [], startPos));
  }
  var components = applyAnnotationOnText({
    texts: texts,
    shouldApplyAnnotationAt: shouldApplyAnnotationAt,
    draftPosition: nextDraftPosition,
    textHighlighter: textHighlighter,
    marks: marks
  });
  return jsx(Fragment, null, components);
};