import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _typeof from "@babel/runtime/helpers/typeof";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMemo, useRef } from 'react';
import { useAnalyticsEvents } from './useAnalyticsEvents';
export function usePatchedProps() {
  var createEventMap = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var wrappedComponentProps = arguments.length > 1 ? arguments[1] : undefined;
  var _useAnalyticsEvents = useAnalyticsEvents(),
    createAnalyticsEvent = _useAnalyticsEvents.createAnalyticsEvent;
  var handlerCache = useRef({});
  var patchedProps = useMemo(function () {
    var cache = handlerCache.current;

    // Clean up no longer used handlers in cache
    Object.keys(cache).filter(function (key) {
      return !(key in createEventMap);
    }).forEach(function (key) {
      return delete cache[key];
    });
    return Object.keys(createEventMap).reduce(function (p, k) {
      var eventCreator = createEventMap[k];
      if (!['object', 'function'].includes(_typeof(eventCreator))) {
        return p;
      }
      var propValue = wrappedComponentProps[k];
      if (k in cache && cache[k].eventCreator === eventCreator && cache[k].propValue === propValue) {
        return _objectSpread(_objectSpread({}, p), {}, _defineProperty({}, k, cache[k].wrappedCallback));
      }
      var wrappedCallback = function wrappedCallback() {
        var analyticsEvent = typeof eventCreator === 'function' ? eventCreator(createAnalyticsEvent, wrappedComponentProps) : createAnalyticsEvent(eventCreator);
        if (propValue && typeof propValue === 'function') {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }
          propValue.apply(void 0, args.concat([analyticsEvent]));
        }
      };
      cache[k] = {
        eventCreator: eventCreator,
        wrappedCallback: wrappedCallback,
        propValue: propValue
      };
      return _objectSpread(_objectSpread({}, p), {}, _defineProperty({}, k, wrappedCallback));
    }, {});
  }, [createEventMap, wrappedComponentProps, createAnalyticsEvent, handlerCache]);
  return {
    patchedEventProps: patchedProps
  };
}