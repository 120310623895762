/** @jsx jsx */

import { css, jsx } from '@emotion/react';
// eslint-disable-next-line @atlaskit/design-system/use-visually-hidden
var visuallyHiddenStyles = css({
  width: '1px',
  height: '1px',
  padding: '0',
  position: 'absolute',
  border: '0',
  clip: 'rect(1px, 1px, 1px, 1px)',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
});

/**
 * __Visually hidden__
 *
 * A composable element to apply a visually hidden effect to children.
 * Useful for accessibility compliance.
 *
 * @example
 * ```jsx
 * import VisuallyHidden from '@atlaskit/visually-hidden';
 *
 * export default () => (
 *   <div style={{ border: '1px solid black' }}>
 *      There is text hidden between the brackets [
 *      <VisuallyHidden>Can't see me!</VisuallyHidden>]
 *   </div>
 * );
 * ```
 */
var VisuallyHidden = function VisuallyHidden(_ref) {
  var children = _ref.children,
    testId = _ref.testId,
    role = _ref.role,
    id = _ref.id;
  return jsx("span", {
    id: id,
    "data-testid": testId,
    css: visuallyHiddenStyles,
    role: role
  }, children);
};
export default VisuallyHidden;