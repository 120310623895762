/**
 * @name paragraph_node
 */

/**
 * @name paragraph_with_no_marks_node
 */

/**
 * NOTE: Need this because TS is too smart and inline everything.
 * So we need to give them separate identity.
 * Probably there's a way to solve it but that will need time and exploration.
 * // http://bit.ly/2raXFX5
 * type T1 = X | Y
 * type T2 = A | T1 | B // T2 = A | X | Y | B
 */

/**
 * @name paragraph_with_alignment_node
 */

/**
 * @name paragraph_with_indentation_node
 */

var pDOM = ['p', 0];
export var paragraph = {
  selectable: false,
  content: 'inline*',
  group: 'block',
  marks: 'strong code em link border strike subsup textColor typeAheadQuery underline confluenceInlineComment action annotation unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  parseDOM: [{
    tag: 'p'
  }],
  toDOM: function toDOM() {
    return pDOM;
  }
};