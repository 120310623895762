import { useMemo } from 'react';
import { createLocalizationProvider } from '@atlaskit/locale';
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default function useLocale(_ref) {
  var locale = _ref.locale,
    weekStartDay = _ref.weekStartDay;
  var l10n = useMemo(function () {
    return createLocalizationProvider(locale);
  }, [locale]);
  var monthsLong = useMemo(function () {
    return l10n.getMonthsLong();
  }, [l10n]);
  var daysShort = useMemo(function () {
    return l10n.getDaysShort(weekStartDay);
  }, [l10n, weekStartDay]);
  var daysLong = useMemo(function () {
    return l10n.getDaysLong(weekStartDay);
  }, [l10n, weekStartDay]);
  return {
    monthsLong: monthsLong,
    daysShort: daysShort,
    daysLong: daysLong
  };
}