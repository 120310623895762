import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _css;
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */

import { css } from '@emotion/react';
import { akEditorShadowZIndex } from '@atlaskit/editor-shared-styles';
import { N40A } from '@atlaskit/theme/colors';
import { shadowClassNames } from '../../ui/OverflowShadow';
import { shadowObserverClassNames } from '../../ui/OverflowShadow/shadowObserver';
var shadowWidth = 8;

/**
 * TODO: This is close to working and removes a tone of JS from OverflowShadow component
 *
 *  background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
 *         linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 100% 0,
 *        linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
 *         linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)) 100% 0;
 *       background-repeat: no-repeat;
 *       background-color: white;
 *       background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
 *
 *      background-attachment: local, local, scroll, scroll;
 */

var shadowSharedStyle = css((_css = {}, _defineProperty(_css, "& .".concat(shadowClassNames.RIGHT_SHADOW, "::before, .").concat(shadowClassNames.RIGHT_SHADOW, "::after, .").concat(shadowClassNames.LEFT_SHADOW, "::before, .").concat(shadowClassNames.LEFT_SHADOW, "::after"), {
  display: 'none',
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: akEditorShadowZIndex,
  width: "".concat(shadowWidth, "px"),
  content: "''",
  height: 'calc(100%)'
}), _defineProperty(_css, "& .".concat(shadowClassNames.RIGHT_SHADOW, ", .").concat(shadowClassNames.LEFT_SHADOW), {
  position: 'relative'
}), _defineProperty(_css, "& .".concat(shadowClassNames.LEFT_SHADOW, "::before"), {
  background: "linear-gradient( to left, transparent 0, ".concat("var(--ds-shadow-overflow-spread, ".concat(N40A, ")"), " 140% ), linear-gradient( to right, ", "var(--ds-shadow-overflow-perimeter, transparent)", " 0px, transparent 1px )"),
  top: '0px',
  left: 0,
  display: 'block'
}), _defineProperty(_css, "& .".concat(shadowClassNames.RIGHT_SHADOW, "::after"), {
  background: "linear-gradient( to right, transparent 0, ".concat("var(--ds-shadow-overflow-spread, ".concat(N40A, ")"), " 140% ), linear-gradient( to left, ", "var(--ds-shadow-overflow-perimeter, transparent)", " 0px, transparent 1px )"),
  right: '0px',
  top: '0px',
  display: 'block'
}), _defineProperty(_css, "& .".concat(shadowObserverClassNames.SENTINEL_LEFT), {
  height: '100%',
  width: '0px',
  minWidth: '0px'
}), _defineProperty(_css, "& .".concat(shadowObserverClassNames.SENTINEL_RIGHT), {
  height: '100%',
  width: '0px',
  minWidth: '0px'
}), _css));
export { shadowSharedStyle };