import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @jsx jsx */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { jsx } from '@emotion/react';
import { bind } from 'bind-event-listener';
import { usePlatformLeafSyntheticEventHandler } from '@atlaskit/analytics-next';
import noop from '@atlaskit/ds-lib/noop';
import useCloseOnEscapePress from '@atlaskit/ds-lib/use-close-on-escape-press';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';
import { mediumDurationMs } from '@atlaskit/motion/durations';
import { Popper } from '@atlaskit/popper';
import Portal from '@atlaskit/portal';
import { layers } from '@atlaskit/theme/constants';
import { show } from './internal/tooltip-manager';
import useUniqueId from './internal/use-unique-id';
import TooltipContainer from './TooltipContainer';
import { getMousePosition } from './utilities';
var tooltipZIndex = layers.tooltip();
var analyticsAttributes = {
  componentName: 'tooltip',
  packageName: "@atlaskit/tooltip",
  packageVersion: "17.8.10"
};

// Inverts motion direction
var invertedDirection = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left'
};

/**
 * Converts a Popper placement to it's general direction.
 *
 * @param position - Popper Placement value, e.g. 'top-start'
 * @returns Popper Direction, e.g. 'top'
 */
var getDirectionFromPlacement = function getDirectionFromPlacement(placement) {
  return placement.split('-')[0];
};
function Tooltip(_ref) {
  var children = _ref.children,
    _ref$position = _ref.position,
    position = _ref$position === void 0 ? 'bottom' : _ref$position,
    _ref$mousePosition = _ref.mousePosition,
    mousePosition = _ref$mousePosition === void 0 ? 'bottom' : _ref$mousePosition,
    content = _ref.content,
    _ref$truncate = _ref.truncate,
    truncate = _ref$truncate === void 0 ? false : _ref$truncate,
    _ref$component = _ref.component,
    Container = _ref$component === void 0 ? TooltipContainer : _ref$component,
    _ref$tag = _ref.tag,
    TargetContainer = _ref$tag === void 0 ? 'div' : _ref$tag,
    testId = _ref.testId,
    _ref$delay = _ref.delay,
    delay = _ref$delay === void 0 ? 300 : _ref$delay,
    _ref$onShow = _ref.onShow,
    onShow = _ref$onShow === void 0 ? noop : _ref$onShow,
    _ref$onHide = _ref.onHide,
    onHide = _ref$onHide === void 0 ? noop : _ref$onHide,
    _ref$hideTooltipOnCli = _ref.hideTooltipOnClick,
    hideTooltipOnClick = _ref$hideTooltipOnCli === void 0 ? false : _ref$hideTooltipOnCli,
    _ref$hideTooltipOnMou = _ref.hideTooltipOnMouseDown,
    hideTooltipOnMouseDown = _ref$hideTooltipOnMou === void 0 ? false : _ref$hideTooltipOnMou,
    analyticsContext = _ref.analyticsContext,
    _ref$strategy = _ref.strategy,
    strategy = _ref$strategy === void 0 ? 'fixed' : _ref$strategy;
  var tooltipPosition = position === 'mouse' ? mousePosition : position;
  var onShowHandler = usePlatformLeafSyntheticEventHandler(_objectSpread({
    fn: onShow,
    action: 'displayed',
    analyticsData: analyticsContext
  }, analyticsAttributes));
  var onHideHandler = usePlatformLeafSyntheticEventHandler(_objectSpread({
    fn: onHide,
    action: 'hidden',
    analyticsData: analyticsContext
  }, analyticsAttributes));
  var apiRef = useRef(null);
  var _useState = useState('hide'),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  var targetRef = useRef(null);
  var containerRef = useRef(null);

  // This function is deliberately _not_ memoized as it needs to re-run every render
  // to pick up any child ref changes. If you use render props you don't have this issue.
  var setImplicitRefFromChildren = function setImplicitRefFromChildren(node) {
    containerRef.current = node;
    targetRef.current = node ? node.firstElementChild : null;
  };

  // This is memoized and passed into the render props callback.
  var setDirectRef = useCallback(function (node) {
    targetRef.current = node;
  }, []);

  // Putting a few things into refs so that we don't have to break memoization
  var lastState = useRef(state);
  var lastDelay = useRef(delay);
  var lastHandlers = useRef({
    onShowHandler: onShowHandler,
    onHideHandler: onHideHandler
  });
  var hasCalledShowHandler = useRef(false);
  useEffect(function () {
    lastState.current = state;
    lastDelay.current = delay;
    lastHandlers.current = {
      onShowHandler: onShowHandler,
      onHideHandler: onHideHandler
    };
  }, [delay, onHideHandler, onShowHandler, state]);
  var start = useCallback(function (api) {
    // @ts-ignore
    apiRef.current = api;
    hasCalledShowHandler.current = false;
  }, []);
  var done = useCallback(function () {
    if (!apiRef.current) {
      return;
    }
    // Only call onHideHandler if we have called onShowHandler
    if (hasCalledShowHandler.current) {
      lastHandlers.current.onHideHandler();
    }
    // @ts-ignore
    apiRef.current = null;
    // @ts-ignore
    hasCalledShowHandler.current = false;
    // just in case
    setState('hide');
  }, []);
  var abort = useCallback(function () {
    if (!apiRef.current) {
      return;
    }
    apiRef.current.abort();
    // Only call onHideHandler if we have called onShowHandler
    if (hasCalledShowHandler.current) {
      lastHandlers.current.onHideHandler();
    }
    // @ts-ignore
    apiRef.current = null;
  }, []);
  useEffect(function mount() {
    return function unmount() {
      if (apiRef.current) {
        abort();
      }
    };
  }, [abort]);
  var showTooltip = useCallback(function (source) {
    if (apiRef.current && !apiRef.current.isActive()) {
      abort();
    }

    // Tell the tooltip to keep showing
    if (apiRef.current && apiRef.current.isActive()) {
      apiRef.current.keep();
      return;
    }
    var entry = {
      source: source,
      delay: lastDelay.current,
      show: function show(_ref2) {
        var isImmediate = _ref2.isImmediate;
        // Call the onShow handler if it hasn't been called yet
        if (!hasCalledShowHandler.current) {
          hasCalledShowHandler.current = true;
          lastHandlers.current.onShowHandler();
        }
        setState(isImmediate ? 'show-immediate' : 'fade-in');
      },
      hide: function hide(_ref3) {
        var isImmediate = _ref3.isImmediate;
        if (isImmediate) {
          setState('hide');
        } else {
          setState('before-fade-out');
        }
      },
      done: done
    };
    var api = show(entry);
    start(api);
  }, [abort, done, start]);
  var hideTooltipOnEsc = useCallback(function () {
    var _apiRef$current;
    (_apiRef$current = apiRef.current) === null || _apiRef$current === void 0 || _apiRef$current.requestHide({
      isImmediate: true
    });
  }, [apiRef]);
  useCloseOnEscapePress({
    onClose: hideTooltipOnEsc,
    isDisabled: state === 'hide' || state === 'fade-out'
  });
  useEffect(function () {
    if (state === 'hide') {
      return noop;
    }
    if (state === 'before-fade-out') {
      setState('fade-out');
    }
    var unbind = bind(window, {
      type: 'scroll',
      listener: function listener() {
        if (apiRef.current) {
          apiRef.current.requestHide({
            isImmediate: true
          });
        }
      },
      options: {
        capture: true,
        passive: true,
        once: true
      }
    });
    return unbind;
  }, [state]);
  var onMouseDown = useCallback(function () {
    if (hideTooltipOnMouseDown && apiRef.current) {
      apiRef.current.requestHide({
        isImmediate: true
      });
    }
  }, [hideTooltipOnMouseDown]);
  var onClick = useCallback(function () {
    if (hideTooltipOnClick && apiRef.current) {
      apiRef.current.requestHide({
        isImmediate: true
      });
    }
  }, [hideTooltipOnClick]);

  // Ideally we would be using onMouseEnter here, but
  // because we are binding the event to the target parent
  // we need to listen for the mouseover of all sub elements
  // This means when moving along a tooltip we are quickly toggling
  // between api.requestHide and api.keep. This it not ideal
  var onMouseOver = useCallback(function (event) {
    // Ignoring events from the container ref
    if (containerRef.current && event.target === containerRef.current) {
      return;
    }

    // Using prevent default as a signal that parent tooltips
    if (event.defaultPrevented) {
      return;
    }
    event.preventDefault();
    var source = position === 'mouse' ? {
      type: 'mouse',
      // TODO: ideally not recalculating this object each time
      mouse: getMousePosition({
        left: event.clientX,
        top: event.clientY
      })
    } : {
      type: 'keyboard'
    };
    showTooltip(source);
  }, [position, showTooltip]);

  // Ideally we would be using onMouseEnter here, but
  // because we are binding the event to the target parent
  // we need to listen for the mouseout of all sub elements
  // This means when moving along a tooltip we are quickly toggling
  // between api.requestHide and api.keep. This it not ideal
  var onMouseOut = useCallback(function (event) {
    // Ignoring events from the container ref
    if (containerRef.current && event.target === containerRef.current) {
      return;
    }

    // Using prevent default as a signal that parent tooltips
    if (event.defaultPrevented) {
      return;
    }
    event.preventDefault();
    if (apiRef.current) {
      apiRef.current.requestHide({
        isImmediate: false
      });
    }
  }, []);
  var onMouseMove = position === 'mouse' ? function (event) {
    var _apiRef$current2;
    if ((_apiRef$current2 = apiRef.current) !== null && _apiRef$current2 !== void 0 && _apiRef$current2.isActive()) {
      apiRef.current.mousePosition = getMousePosition({
        left: event.clientX,
        top: event.clientY
      });
    }
  } : undefined;
  var onMouseOverTooltip = useCallback(function () {
    if (apiRef.current && apiRef.current.isActive()) {
      apiRef.current.keep();
      return;
    }
  }, []);
  var onFocus = useCallback(function () {
    showTooltip({
      type: 'keyboard'
    });
  }, [showTooltip]);
  var onBlur = useCallback(function () {
    if (apiRef.current) {
      apiRef.current.requestHide({
        isImmediate: false
      });
    }
  }, []);
  var onAnimationFinished = useCallback(function (transition) {
    // Using lastState here because motion is not picking up the latest value
    if (transition === 'exiting' && lastState.current === 'fade-out' && apiRef.current) {
      // @ts-ignore: refs are writeable
      apiRef.current.finishHideAnimation();
    }
  }, []);

  // Doing a cast because typescript is struggling to narrow the type
  var CastTargetContainer = TargetContainer;
  var shouldRenderTooltipContainer = state !== 'hide' && Boolean(content);
  var shouldRenderTooltipChildren = state !== 'hide' && state !== 'fade-out';
  var getReferenceElement = function getReferenceElement() {
    var _apiRef$current3;
    if (position === 'mouse' && (_apiRef$current3 = apiRef.current) !== null && _apiRef$current3 !== void 0 && _apiRef$current3.mousePosition) {
      var _apiRef$current4;
      return (_apiRef$current4 = apiRef.current) === null || _apiRef$current4 === void 0 ? void 0 : _apiRef$current4.mousePosition;
    }
    return targetRef.current || undefined;
  };
  var tooltipId = useUniqueId('tooltip', shouldRenderTooltipContainer);
  var tooltipTriggerProps = {
    onMouseOver: onMouseOver,
    onMouseOut: onMouseOut,
    onMouseMove: onMouseMove,
    onMouseDown: onMouseDown,
    onClick: onClick,
    onFocus: onFocus,
    onBlur: onBlur
  };

  // Don't set `data-testid` unless it's defined, as it's not in the interface.
  if (testId) {
    // @ts-expect-error - Adding `data-testid` to the TriggerProps interface breaks Buttons.
    tooltipTriggerProps['data-testid'] = "".concat(testId, "--container");
  }

  // This useEffect is purely for managing the aria attribute when using the
  // wrapped children approach.
  useEffect(function () {
    // If there is no container element, we should exit early, because that
    // means they are using the render prop API, and that is implemented in a
    // different way. If there is no target element yet or tooltipId, we also
    // shouldn't do anything because there is nothing to operate on or with.
    if (!containerRef.current || !targetRef.current || !tooltipId) {
      return;
    }
    var target = targetRef.current;
    if (shouldRenderTooltipContainer) {
      target.setAttribute('aria-describedby', tooltipId);
    } else {
      target.removeAttribute('aria-describedby');
    }
  }, [shouldRenderTooltipContainer, tooltipId]);
  return jsx(React.Fragment, null, typeof children === 'function' ?
  // once we deprecate the wrapped approach, we can put the aria
  // attribute back into the tooltipTriggerProps and make it required
  // instead of optional in `types`
  children(_objectSpread(_objectSpread({}, tooltipTriggerProps), {}, {
    'aria-describedby': tooltipId,
    ref: setDirectRef
  })) : jsx(CastTargetContainer, _extends({}, tooltipTriggerProps, {
    ref: setImplicitRefFromChildren,
    role: "presentation"
  }), children), shouldRenderTooltipContainer ? jsx(Portal, {
    zIndex: tooltipZIndex
  }, jsx(Popper, {
    placement: tooltipPosition,
    referenceElement: getReferenceElement(),
    strategy: strategy
  }, function (_ref4) {
    var ref = _ref4.ref,
      style = _ref4.style,
      update = _ref4.update,
      placement = _ref4.placement;
    // Invert the entrance and exit directions.
    // E.g. a tooltip's position is on the 'right', it should enter from and exit to the 'left'
    // This gives the effect the tooltip is appearing from the target
    var direction = position === 'mouse' ? undefined : invertedDirection[getDirectionFromPlacement(placement)];
    return jsx(ExitingPersistence, {
      appear: true
    }, shouldRenderTooltipChildren && jsx(FadeIn, {
      distance: "constant",
      entranceDirection: direction,
      exitDirection: direction,
      onFinish: onAnimationFinished,
      duration: state === 'show-immediate' ? 0 : mediumDurationMs
    }, function (_ref5) {
      var className = _ref5.className;
      return jsx(Container, {
        ref: ref
        /**
         * "Tooltip" classname is a hook used by tests to manipulate
         * and hide tooltips, including in VR snapshots
         **/,
        className: "Tooltip ".concat(className),
        style: style,
        truncate: truncate,
        placement: tooltipPosition,
        testId: getReferenceElement() ? testId : testId && "".concat(testId, "--unresolved"),
        onMouseOut: onMouseOut,
        onMouseOver: onMouseOverTooltip,
        id: tooltipId
      }, typeof content === 'function' ? content({
        update: update
      }) : content);
    }));
  })) : null);
}
Tooltip.displayName = 'Tooltip';
export default Tooltip;