import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback, useEffect, useRef } from 'react';
import { daysPerWeek, monthsPerYear } from '../constants';
import dateToString from '../utils/date-to-string';
import getDaysInMonth from '../utils/get-days-in-month';
var getNextMonth = function getNextMonth(monthValue, yearValue) {
  var month = monthValue;
  var year = yearValue;
  if (month === monthsPerYear) {
    month = 1;
    year += 1;
  } else {
    month += 1;
  }
  return {
    month: month,
    year: year
  };
};
var getPrevMonth = function getPrevMonth(monthValue, yearValue) {
  var month = monthValue;
  var year = yearValue;
  if (month === 1) {
    month = monthsPerYear;
    year -= 1;
  } else {
    month -= 1;
  }
  return {
    month: month,
    year: year
  };
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default function useHandleDateChange(_ref) {
  var _ref$day = _slicedToArray(_ref.day, 2),
    dayValue = _ref$day[0],
    setDayValue = _ref$day[1],
    _ref$month = _slicedToArray(_ref.month, 2),
    monthValue = _ref$month[0],
    setMonthValue = _ref$month[1],
    _ref$year = _slicedToArray(_ref.year, 2),
    yearValue = _ref$year[0],
    setYearValue = _ref$year[1],
    _ref$shouldSetFocus = _slicedToArray(_ref.shouldSetFocus, 2),
    _shouldSetFocus = _ref$shouldSetFocus[0],
    setShouldSetFocus = _ref$shouldSetFocus[1],
    onChange = _ref.onChange;
  var dateRef = useRef({
    day: dayValue,
    month: monthValue,
    year: yearValue
  });
  useEffect(function () {
    dateRef.current = {
      day: dayValue,
      month: monthValue,
      year: yearValue
    };
  }, [dayValue, monthValue, yearValue]);
  var triggerOnChange = useCallback(function (_ref2) {
    var year = _ref2.year,
      month = _ref2.month,
      day = _ref2.day,
      type = _ref2.type;
    var iso = dateToString({
      year: year,
      month: month,
      day: day
    });
    var isFocusableType = ['up', 'down', 'left', 'right'].includes(type);
    onChange({
      day: day,
      month: month,
      year: year,
      iso: iso,
      type: type
    });
    setDayValue(day);
    setMonthValue(month);
    setYearValue(year);
    setShouldSetFocus(isFocusableType);
  }, [onChange, setDayValue, setMonthValue, setYearValue, setShouldSetFocus]);
  var navigate = useCallback(function (type) {
    var _dateRef$current = dateRef.current,
      day = _dateRef$current.day,
      month = _dateRef$current.month,
      year = _dateRef$current.year;
    if (type === 'down') {
      var next = day + daysPerWeek;
      var daysInMonth = getDaysInMonth(year, month - 1);
      if (next > daysInMonth) {
        var _getNextMonth = getNextMonth(month, year),
          nextMonth = _getNextMonth.month,
          nextYear = _getNextMonth.year;
        triggerOnChange({
          year: nextYear,
          month: nextMonth,
          day: next - daysInMonth,
          type: type
        });
      } else {
        triggerOnChange({
          year: year,
          month: month,
          day: next,
          type: type
        });
      }
    } else if (type === 'left') {
      var prev = day - 1;
      if (prev < 1) {
        var _getPrevMonth = getPrevMonth(month, year),
          prevMonth = _getPrevMonth.month,
          prevYear = _getPrevMonth.year;
        var prevDay = getDaysInMonth(prevYear, prevMonth - 1);
        triggerOnChange({
          year: prevYear,
          month: prevMonth,
          day: prevDay,
          type: type
        });
      } else {
        triggerOnChange({
          year: year,
          month: month,
          day: prev,
          type: type
        });
      }
    } else if (type === 'right') {
      var _next = day + 1;
      var _daysInMonth = getDaysInMonth(year, month - 1);
      if (_next > _daysInMonth) {
        var _getNextMonth2 = getNextMonth(month, year),
          _nextMonth = _getNextMonth2.month,
          _nextYear = _getNextMonth2.year;
        triggerOnChange({
          year: _nextYear,
          month: _nextMonth,
          day: 1,
          type: type
        });
      } else {
        triggerOnChange({
          year: year,
          month: month,
          day: _next,
          type: type
        });
      }
    } else if (type === 'up') {
      var _prev = day - daysPerWeek;
      if (_prev < 1) {
        var _getPrevMonth2 = getPrevMonth(month, year),
          _prevMonth = _getPrevMonth2.month,
          _prevYear = _getPrevMonth2.year;
        var _prevDay = getDaysInMonth(_prevYear, _prevMonth - 1) + _prev;
        triggerOnChange({
          year: _prevYear,
          month: _prevMonth,
          day: _prevDay,
          type: type
        });
      } else {
        triggerOnChange({
          year: year,
          month: month,
          day: _prev,
          type: type
        });
      }
    }
  }, [triggerOnChange]);
  var handleClickNext = useCallback(function () {
    var _dateRef$current$getN = _objectSpread(_objectSpread({}, dateRef.current), getNextMonth(dateRef.current.month, dateRef.current.year)),
      day = _dateRef$current$getN.day,
      month = _dateRef$current$getN.month,
      year = _dateRef$current$getN.year;
    triggerOnChange({
      day: day,
      month: month,
      year: year,
      type: 'next'
    });
  }, [triggerOnChange]);
  var handleClickPrev = useCallback(function () {
    var _dateRef$current$getP = _objectSpread(_objectSpread({}, dateRef.current), getPrevMonth(dateRef.current.month, dateRef.current.year)),
      day = _dateRef$current$getP.day,
      month = _dateRef$current$getP.month,
      year = _dateRef$current$getP.year;
    triggerOnChange({
      day: day,
      month: month,
      year: year,
      type: 'prev'
    });
  }, [triggerOnChange]);
  return {
    navigate: navigate,
    handleClickNext: handleClickNext,
    handleClickPrev: handleClickPrev
  };
}