import { useEffect, useRef } from 'react';
import { uuid } from '@atlaskit/adf-schema';
import { getPropsDifference, getShallowPropsDifference } from '../../../compare-props';
export function useComponentRenderTracking(_ref) {
  var onRender = _ref.onRender,
    propsDiffingOptions = _ref.propsDiffingOptions,
    _ref$zeroBasedCount = _ref.zeroBasedCount,
    zeroBasedCount = _ref$zeroBasedCount === void 0 ? true : _ref$zeroBasedCount;
  var propsRef = useRef();
  var renderCountRef = useRef(zeroBasedCount ? 0 : 1);
  var _useRef = useRef(uuid.generate()),
    componentId = _useRef.current;
  useEffect(function () {
    var lastProps = propsRef.current;
    var renderCount = renderCountRef.current;
    var propsDifference;
    if (propsDiffingOptions !== null && propsDiffingOptions !== void 0 && propsDiffingOptions.enabled && lastProps) {
      propsDifference = propsDiffingOptions !== null && propsDiffingOptions !== void 0 && propsDiffingOptions.useShallow ? getShallowPropsDifference(lastProps, propsDiffingOptions.props) : getPropsDifference(lastProps, propsDiffingOptions.props, 0, 2, propsDiffingOptions === null || propsDiffingOptions === void 0 ? void 0 : propsDiffingOptions.propsToIgnore);
    }
    var result = {
      renderCount: renderCount,
      propsDifference: propsDifference,
      componentId: componentId
    };
    onRender(result);
    if (propsDiffingOptions !== null && propsDiffingOptions !== void 0 && propsDiffingOptions.enabled) {
      propsRef.current = propsDiffingOptions.props;
    }
    renderCountRef.current = renderCountRef.current + 1;
  }); // No dependencies run on each render
}