import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useHeading } from './heading-context';
var sizeTagMap = {
  xxlarge: 'h1',
  xlarge: 'h1',
  large: 'h2',
  medium: 'h3',
  small: 'h4',
  xsmall: 'h5',
  xxsmall: 'h6'
};
var headingResetStyles = css({
  color: "var(--ds-text, #172B4D)",
  letterSpacing: 'normal',
  marginBlock: 0,
  textTransform: 'none'
});
var inverseStyles = css({
  color: "var(--ds-text-inverse, #FFF)"
});

/**
 * __Heading__
 *
 * Heading is a typography component used to display text in defined sizes and styles.
 *
 * @example
 *
 * ```jsx
 * <Heading size="xxlarge">Page title</Heading>
 * ```
 */
var Heading = function Heading(_ref) {
  var children = _ref.children,
    size = _ref.size,
    variant = _ref.variant,
    id = _ref.id,
    testId = _ref.testId,
    as = _ref.as,
    _ref$color = _ref.color,
    color = _ref$color === void 0 ? 'default' : _ref$color;
  if (typeof process !== 'undefined' && process.env.NODE_ENV !== 'production' && as && typeof as !== 'string') {
    throw new Error('`as` prop should be a string.');
  }

  // TODO: Temporary to move variant over to size
  var localSize = variant || size;

  // Technically size can be undefined here due to how the types work.
  // Once removing the level prop this assertion can be removed since size will be a required prop.
  var _useHeading = useHeading(sizeTagMap[localSize]),
    _useHeading2 = _slicedToArray(_useHeading, 2),
    hLevel = _useHeading2[0],
    inferredElement = _useHeading2[1];
  var Component = as || inferredElement;
  var needsAriaRole = Component === 'div' && hLevel;
  return jsx(Component, {
    id: id,
    "data-testid": testId,
    role: needsAriaRole ? 'heading' : undefined,
    "aria-level": needsAriaRole ? hLevel : undefined,
    css: [headingResetStyles, localSize && headingSizeStylesMap[localSize], color === 'inverse' && inverseStyles]
  }, children);
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::057c0fe2015c2071afe3d694c5afcc0e>>
 * @codegenId typography
 * @codegenCommand yarn workspace @atlaskit/heading codegen
 */
var headingSizeStylesMap = {
  xxlarge: css({
    font: "var(--ds-font-heading-xxlarge, normal 500 35px/40px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  xlarge: css({
    font: "var(--ds-font-heading-xlarge, normal 600 29px/32px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  large: css({
    font: "var(--ds-font-heading-large, normal 500 24px/28px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  medium: css({
    font: "var(--ds-font-heading-medium, normal 500 20px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  small: css({
    font: "var(--ds-font-heading-small, normal 600 16px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  xsmall: css({
    font: "var(--ds-font-heading-xsmall, normal 600 14px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  }),
  xxsmall: css({
    font: "var(--ds-font-heading-xxsmall, normal 600 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
  })
};
/**
 * @codegenEnd
 */

export default Heading;