import { CVESeverityMapping, Cvss } from "../utils/cve-severity-mapping";
import { getCveById, getProducts, getProductVersions, Resource } from "../rest/";

export interface DetailsDataObject {
    cve_id: string;
    cve_summary: string;
    cve_details: CveDetails[];
    cve_vector: string;
    cve_severity: number;
    cvss_severity: Cvss;
    cve_publish_date: string;
    atl_tracking_url: string;
    advisory_url: string;
    affected_products: {
        [product_name: string]: ProductData;
    };
}

export interface CveDetails {
    summary: string;
    details: any;
    publish_date: string;
}
export interface ProductData {
    [version: string]: string; //FIXED or AFFECTED
}

export interface CVETableData {
    cve_id: string;
    cve_summary: string;
    cve_publish_date: string;
    cve_severity: number;
    cvss_severity: Cvss;
    affected_products: {
        [product_name: string]: ProductData;
    };
}

export interface CVEObject {
    [cve_id: string]: CVETableData;
}

export interface ProductVersionsObject {
    [product_name: string]: string[]; //list of versions
}

export interface TableDataObject {
    cveData: CVEObject;
    productOptions: ProductVersionsObject;
}

export const getTableData = (): Promise<TableDataObject> => {
    const dataObject = {
        cveData: {},
        productOptions: {},
    } as TableDataObject;

    return Promise.all([getProducts(), getProductVersions()])
        .then(([productsData, versionsData]) => {
            for (const [cve_id, object] of Object.entries(productsData.cve_metadata)) {
                dataObject.cveData[cve_id] = {
                    cve_id: cve_id,
                    cve_summary: object.cve_summary,
                    cve_publish_date: object.cve_publish_date,
                    cve_severity: object.cve_severity,
                    cvss_severity: CVESeverityMapping(object.cve_severity),
                    affected_products: {},
                };
            }

            //now for each CVE ID we build the product data
            for (const [productName, product] of Object.entries(productsData.products)) {
                let affectedVersions: string[] = [];
                for (const [version, statusList] of Object.entries(product.versions)) {
                    statusList.forEach(element => {
                        const cve = Object.keys(element)[0];
                        const status = Object.values(element)[0];
                        const productData: ProductData = {
                            [version]: status,
                        };
                        if (status === "AFFECTED" && affectedVersions.indexOf(version) === -1) {
                            affectedVersions.push(version);
                        }
                        if (dataObject.cveData[cve]) {
                            if (dataObject.cveData[cve].affected_products[productName]) {
                                //we add new key
                                dataObject.cveData[cve].affected_products[productName][`${version}`] = status;
                            } else {
                                //brand new so just assign
                                dataObject.cveData[cve].affected_products[productName] = productData;
                            }
                        }
                        //else there is a data discrepancy and I'm not handling that in the FE
                    });
                }

                const additionalVersions = versionsData[productName] || [];
                // Merge affectedVersions with additionalVersions, ensuring there are no duplicates
                dataObject.productOptions[productName] = [...new Set([...affectedVersions, ...additionalVersions])];
            }
            return dataObject;
        })
        .catch(e => {
            throw new Error(e);
        });
};

export const getDetailsData = (cve_id: string): Promise<DetailsDataObject> => {
    return getCveById([cve_id]).then(data => {
        if (cve_id != "" && data.resources[0]) {
            const resource = data.resources[0];

            let details: CveDetails[] = [];
            details = data.resources.map((r: Resource) => ({
                summary: r.cve_summary,
                details: JSON.parse(r.cve_details),
                publish_date: r.cve_publish_date,
            }));

            return {
                cve_id: resource.cve_id,
                cve_summary: resource.cve_summary,
                cve_publish_date: resource.cve_publish_date,
                cve_details: details,
                cve_vector: resource.cve_vector,
                cve_severity: resource.cve_severity,
                cvss_severity: CVESeverityMapping(resource.cve_severity),
                atl_tracking_url: `https://jira.atlassian.com/issues/?jql=%22CVE%20ID%22%20~%20${resource.cve_id}`,
                advisory_url: resource.advisory_url,
                affected_products: {}, //this will need to be filled out from the already known data
            } as DetailsDataObject;
        }

        return {} as DetailsDataObject;
    });
};
