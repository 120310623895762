/** @jsx jsx */

import { css } from '@emotion/react';
// Wraps the navigation bar and extensionFrames
var mbeExtensionContainer = css({
  background: 'transparent !important',
  'padding:': {
    left: "var(--ds-space-100, 8px)".concat(" !important"),
    right: "var(--ds-space-100, 8px)".concat(" !important")
  },
  paddingBottom: "var(--ds-space-100, 8px)",
  '&.remove-padding': {
    paddingBottom: 0
  },
  position: 'relative',
  verticalAlign: 'middle',
  cursor: 'pointer',
  '.multiBodiedExtension-handler-result': {
    marginLeft: "var(--ds-space-100, 8px)"
  },
  ".multiBodiedExtension-content-dom-wrapper > [data-extension-frame='true'], .multiBodiedExtension--frames > [data-extension-frame='true']": {
    display: 'none',
    background: "var(--ds-surface, white)"
  },
  '.multiBodiedExtension-content-dom-wrapper, .multiBodiedExtension--frames': {
    "[data-extension-frame='true'] > :not(style):first-child, [data-extension-frame='true'] > style:first-child + *": {
      marginTop: 0
    }
  }
});
var mbeNavigation = css({
  borderTopLeftRadius: "var(--ds-border-radius, 3px)",
  borderTopRightRadius: "var(--ds-border-radius, 3px)",
  userSelect: 'none',
  WebkitUserModify: 'read-only',
  borderBottom: 'none !important',
  background: "var(--ds-surface, white)",
  marginLeft: "var(--ds-space-100, 8px)",
  marginRight: "var(--ds-space-100, 8px)",
  '&.remove-margins': {
    marginLeft: 0,
    marginRight: 0
  },
  '&.remove-border': {
    border: 'none'
  }
});
var extensionFrameContent = css({
  padding: "var(--ds-space-100, 8px)".concat(" !important"),
  display: 'block',
  minHeight: '100px',
  background: "var(--ds-surface, white)",
  borderBottomLeftRadius: "var(--ds-border-radius, 3px)",
  borderBottomRightRadius: "var(--ds-border-radius, 3px)",
  marginLeft: "var(--ds-space-100, 8px)",
  marginRight: "var(--ds-space-100, 8px)",
  cursor: 'initial',
  '.pm-table-with-controls': {
    marginLeft: "var(--ds-space-150, 12px)".concat(" !important"),
    paddingRight: "var(--ds-space-150, 12px)".concat(" !important")
  },
  '.bodiedExtensionView-content-wrap': {
    marginTop: "var(--ds-space-150, 12px)".concat(" !important")
  },
  '.extensionView-content-wrap': {
    marginTop: "var(--ds-space-100, 8px)".concat(" !important")
  }
});
export var removeMarginsAndBorder = css({
  marginLeft: 0,
  marginRight: 0,
  border: 'none'
});
export var sharedMultiBodiedExtensionStyles = {
  mbeExtensionContainer: mbeExtensionContainer,
  mbeNavigation: mbeNavigation,
  extensionFrameContent: extensionFrameContent
};