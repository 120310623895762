import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useMemo, useContext } from 'react';
import { InlineCommentsStateContext } from '../context';
export var useInlineCommentsFilter = function useInlineCommentsFilter(_ref) {
  var annotationIds = _ref.annotationIds,
    stateFilter = _ref.filter.state;
  var states = useContext(InlineCommentsStateContext);
  return useMemo(function () {
    return annotationIds.reduce(function (acc, id) {
      if (states && states[id] === stateFilter) {
        return [].concat(_toConsumableArray(acc), [id]);
      }
      return acc;
    }, []);
  }, [annotationIds, states, stateFilter]);
};