import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import useControlled from '@atlaskit/ds-lib/use-controlled';
import useLazyRef from '@atlaskit/ds-lib/use-lazy-ref';
import pad from '../utils/pad';

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default function useControlledDateState(_ref) {
  var day = _ref.day,
    defaultDay = _ref.defaultDay,
    month = _ref.month,
    defaultMonth = _ref.defaultMonth,
    year = _ref.year,
    defaultYear = _ref.defaultYear,
    today = _ref.today,
    selected = _ref.selected,
    defaultSelected = _ref.defaultSelected,
    previouslySelected = _ref.previouslySelected,
    defaultPreviouslySelected = _ref.defaultPreviouslySelected;
  var _useLazyRef = useLazyRef(function () {
      var now = new Date();
      var thisDay = now.getDate();
      var thisMonth = now.getMonth() + 1;
      var thisYear = now.getFullYear();
      return {
        thisDay: thisDay,
        thisMonth: thisMonth,
        thisYear: thisYear
      };
    }),
    _useLazyRef$current = _useLazyRef.current,
    thisDay = _useLazyRef$current.thisDay,
    thisMonth = _useLazyRef$current.thisMonth,
    thisYear = _useLazyRef$current.thisYear;
  var _useControlled = useControlled(day, function () {
      return defaultDay || thisDay;
    }),
    _useControlled2 = _slicedToArray(_useControlled, 2),
    dayValue = _useControlled2[0],
    setDayValue = _useControlled2[1];
  var _useControlled3 = useControlled(month, function () {
      return defaultMonth || thisMonth;
    }),
    _useControlled4 = _slicedToArray(_useControlled3, 2),
    monthValue = _useControlled4[0],
    setMonthValue = _useControlled4[1];
  var _useControlled5 = useControlled(year, function () {
      return defaultYear || thisYear;
    }),
    _useControlled6 = _slicedToArray(_useControlled5, 2),
    yearValue = _useControlled6[0],
    setYearValue = _useControlled6[1];
  var _useControlled7 = useControlled(today, function () {
      return today || "".concat(thisYear, "-").concat(pad(thisMonth), "-").concat(pad(thisDay));
    }),
    _useControlled8 = _slicedToArray(_useControlled7, 1),
    todayValue = _useControlled8[0];
  var _useControlled9 = useControlled(selected, function () {
      return defaultSelected;
    }),
    _useControlled10 = _slicedToArray(_useControlled9, 2),
    selectedValue = _useControlled10[0],
    setSelectedValue = _useControlled10[1];
  var _useControlled11 = useControlled(previouslySelected, function () {
      return defaultPreviouslySelected;
    }),
    _useControlled12 = _slicedToArray(_useControlled11, 2),
    previouslySelectedValue = _useControlled12[0],
    setPreviouslySelectedValue = _useControlled12[1];
  return {
    day: [dayValue, setDayValue],
    month: [monthValue, setMonthValue],
    year: [yearValue, setYearValue],
    today: [todayValue],
    selected: [selectedValue, setSelectedValue],
    previous: [previouslySelectedValue, setPreviouslySelectedValue]
  };
}