import React, { useState, useEffect } from "react";
import { DatePicker } from "@atlaskit/datetime-picker";
import DynamicTableStateless from "@atlaskit/dynamic-table";
import Button, { ButtonGroup } from "@atlaskit/button";
import Heading from "@atlaskit/heading";
import { N200, N600 } from "@atlaskit/theme/colors";
import { token } from "@atlaskit/tokens";
import {
    AtlassianIcon,
    BitbucketIcon,
    CompassIcon,
    ConfluenceIcon,
    JiraIcon,
    JiraServiceManagementIcon,
    JiraSoftwareIcon,
    OpsgenieIcon,
    TrelloIcon,
} from "@atlaskit/logo";
import Select, { CheckboxSelect, CreatableSelect, OptionsType, OptionType } from "@atlaskit/select";
import TextField from "@atlaskit/textfield";
import Tooltip from "@atlaskit/tooltip";
import Lozenge from "@atlaskit/lozenge";
import { Cvss } from "./utils/cve-severity-mapping";
import {
    CVEObject,
    CVETableData,
    DetailsDataObject,
    getDetailsData,
    ProductVersionsObject,
    TableDataObject,
    ProductData,
} from "./domain/cvedata";
import { DetailDrawer } from "./details";
import "./style.css";
import Badge from "@atlaskit/badge";
import { TableRowsPerPage, MaxNumberOfTableIcons } from "./utils/consts";
import { parse, valid } from "semver";

// Used to set the headers of the table, keys must match the data in the 'rows' object
const head = {
    cells: [
        {
            key: "cve",
            content: "CVE ID",
            isSortable: true,
            width: 20, // percentage
        },
        {
            key: "summary",
            content: "Summary",
            isSortable: true,
        },
        {
            key: "published",
            content: "Publish Date",
            isSortable: true,
            width: 15, // percentage
        },
        {
            key: "severity",
            content: "Severity",
            isSortable: true,
            width: 10, // percentage
        },
        {
            key: "affected",
            content: "Affected Products",
            isSortable: false,
        },
    ],
};

const sevOptions: OptionsType = [
    {
        label: Cvss.Critical,
        value: Cvss.Critical,
    },
    {
        label: Cvss.High,
        value: Cvss.High,
    },
];

interface TableProps {
    tableData: TableDataObject;
    isLoading: boolean;
    errorData: any;
}

const CVETable = (props: TableProps) => {
    const [cves, setCves] = useState({} as CVEObject);
    const [versionOptions, setVersionOptions] = useState([] as OptionsType);
    const [affectedProduct, setAffectedProduct] = useState({} as OptionType);
    const [affectedVersion, setAffectedVersion] = useState({} as OptionType);
    const [publishDate, setPublishDate] = useState("");
    const [severities, setSeverities] = useState([] as OptionsType);
    const [searchCriteria, setSearchCriteria] = useState("");
    const [open, setOpen] = useState<boolean>(false);

    const productOptions = Object.entries(props.tableData.productOptions).map(e => ({
        label: e[0],
        value: e[0],
    }));

    const [selectedCVEDetails, setSelectedCVEDetails] = useState<DetailsDataObject>({} as DetailsDataObject);

    const handleAffectedProductsChange = (newValue: OptionType) => {
        let versionValues: OptionsType = [];
        if (newValue == null) {
            newValue = {} as OptionType;
        }
        versionValues = calculateVersionValues(newValue, props.tableData.productOptions);
        setAffectedProduct(newValue);
        setVersionOptions(versionValues);

        setCves(
            applyFilters(newValue, affectedVersion, publishDate, severities, searchCriteria, props.tableData.cveData)
        );
    };

    const handleAffectedVersionChange = (newValue: OptionType) => {
        if (newValue == null) {
            newValue = {} as OptionType;
        }
        setAffectedVersion(newValue);
        setCves(
            applyFilters(affectedProduct, newValue, publishDate, severities, searchCriteria, props.tableData.cveData)
        );
    };

    const handleCreateNewAffectedVersion = (newValue: string) => {
        if (Object.keys(affectedProduct).length > 0) {
            let newVersion = {
                label: newValue,
                value: affectedProduct.value + "-" + newValue,
            };
            const newVersionOptions = [...versionOptions, newVersion];
            setVersionOptions(newVersionOptions);
            handleAffectedVersionChange(newVersion);
        }
    };

    const handlePublishDateChange = (newValue: string) => {
        setPublishDate(newValue);
        setCves(
            applyFilters(
                affectedProduct,
                affectedVersion,
                newValue,
                severities,
                searchCriteria,
                props.tableData.cveData
            )
        );
    };

    const validateOption = (newValue: string) => {
        return (
            Object.keys(affectedProduct).length > 0 &&
            !versionOptions.some(v => v.value.toString().includes(newValue)) &&
            !!valid(newValue)
        );
    };

    const handleSeveritiesChange = (newValue: OptionsType) => {
        setSeverities(newValue);
        setCves(
            applyFilters(
                affectedProduct,
                affectedVersion,
                publishDate,
                newValue,
                searchCriteria,
                props.tableData.cveData
            )
        );
    };

    const handleSearch = (newValue: string) => {
        setSearchCriteria(newValue);
        setCves(
            applyFilters(affectedProduct, affectedVersion, publishDate, severities, newValue, props.tableData.cveData)
        );
    };

    const noVersionOptionsMessage = () => {
        if (Object.keys(affectedProduct).length === 0) {
            return "Select an affected product first";
        }
        return "Specify version in semver format. ex: 8.0.1";
    };

    useEffect(() => {
        setCves(props.tableData.cveData);
    }, [props.tableData.cveData]);

    const handleClick = (cveID: string) => {
        toggleDrawer(true);
        getDetailsData(cveID).then(async data => {
            // Check if we actually got a cve response back.
            if (!!data?.cve_id) {
                data.affected_products = cves[cveID].affected_products;
                setSelectedCVEDetails(data);
            } else {
                setSelectedCVEDetails({} as DetailsDataObject);
            }
        });
    };

    const toggleDrawer = (open: boolean) => {
        setOpen(open);
    };

    const rows = getRows(cves, handleClick);
    return (
        <>
            <ButtonGroup>
                <TextField
                    style={{ minWidth: "20em" }}
                    placeholder="CVE ID or summary"
                    onChange={e => handleSearch(e.currentTarget.value)}
                    data-cy="search"
                />
                &ensp;
                <Select
                    inputId="checkbox-select-products"
                    className="checkbox-select wide-checkbox-select"
                    classNamePrefix="select-product"
                    options={productOptions}
                    onChange={handleAffectedProductsChange}
                    placeholder="Affected Product"
                    isClearable
                />
                &ensp;
                <CreatableSelect
                    inputId="checkbox-select-versions"
                    className="checkbox-select wide-checkbox-select"
                    classNamePrefix="select-version"
                    options={versionOptions}
                    onChange={handleAffectedVersionChange}
                    onCreateOption={handleCreateNewAffectedVersion}
                    isValidNewOption={validateOption}
                    placeholder="Affected Version(s)"
                    noOptionsMessage={noVersionOptionsMessage}
                    value={Object.keys(affectedVersion).length === 0 ? null : affectedVersion}
                    isClearable
                />
                &ensp;
                <div style={{ color: token("color.icon.accent.gray", N600) }}>
                    <DatePicker
                        onChange={handlePublishDateChange}
                        selectProps={{
                            inputId: "publish-date",
                            className: "checkbox-select",
                            placeholder: "Publish Date",
                        }}
                    />
                </div>
                &ensp;
                <CheckboxSelect
                    inputId="checkbox-select-severity"
                    className="checkbox-select"
                    classNamePrefix="select-severity"
                    options={sevOptions}
                    onChange={handleSeveritiesChange}
                    placeholder="Severity"
                />
            </ButtonGroup>

            <br />
            <br />
            <div className="cve-table">
                <DynamicTableStateless
                    head={head}
                    rows={rows}
                    rowsPerPage={TableRowsPerPage}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={props.isLoading}
                    isFixedSize
                    defaultSortKey="published"
                    defaultSortOrder="DESC"
                    emptyView={
                        !!props.errorData ? (
                            <h3>There was an error when retrieving data, please try again later.</h3>
                        ) : (
                            <h3>No CVEs were found matching your search</h3>
                        )
                    }
                />
            </div>

            <br />
            <br />
            <Heading level="h600">Security Vulnerability API</Heading>
            <>
                All data reported in this portal is available via the{" "}
                <a
                    href="https://developer.atlassian.com/platform/security-vulnerability-api/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Atlassian Security Vulnerability API
                </a>
                .
            </>

            <br />
            <br />
            <Heading level="h600">Need Help?</Heading>
            <>
                Couldn’t find what you were looking for? Contact:{" "}
                <a href="https://support.atlassian.com/contact/" target="_blank" rel="noreferrer">
                    Atlassian Support
                </a>
                .
            </>

            {DetailDrawer(selectedCVEDetails, open, toggleDrawer)}
        </>
    );
};

// Uses the same keys as the head object
function getRows(cves: CVEObject, handleClick: (cve_id: string) => void) {
    return Object.values(cves).map((ticket: CVETableData, index: number) => ({
        key: `row-${index}-${ticket.cve_id}`,
        cells: [
            {
                key: ticket.cve_id,
                content: (
                    <Button
                        appearance="link"
                        onClick={() => {
                            handleClick(ticket.cve_id);
                        }}
                        data-cy={ticket.cve_id} // for integration tests
                    >
                        {ticket.cve_id}
                    </Button>
                ),
            },
            {
                key: ticket.cve_summary,
                content: (
                    <Button
                        appearance="subtle-link"
                        onClick={() => {
                            handleClick(ticket.cve_id);
                        }}
                    >
                        {ticket.cve_summary}
                    </Button>
                ),
            },
            {
                key: Date.parse(ticket.cve_publish_date),
                content: (
                    <p style={{ color: token("color.text.subtle", N200) }}>
                        {" "}
                        {getDisplayDate(ticket.cve_publish_date)}{" "}
                    </p>
                ),
            },
            {
                key: ticket.cve_severity,
                content: getSeverity(ticket.cvss_severity, ticket.cve_severity),
            },
            {
                key: "affected",
                content: getAffectedIcons(Object.keys(ticket.affected_products)),
            },
        ],
    }));
}

// TODO: verify the correct list of logos/affected services
export function getAffectedIcons(affected: string[]) {
    let affectedLength = affected.length;
    let affectedOverflowCount = affectedLength - MaxNumberOfTableIcons;
    affected = affected.sort();
    if (affectedLength > MaxNumberOfTableIcons) {
        affected.length = MaxNumberOfTableIcons + 1;
        affected.push("overflow");
    }

    return (
        <div>
            {affected.map(aff => {
                let content: any = undefined;
                switch (aff.toLowerCase().replace(/\s/g, "")) {
                    // Forwarding the tooltip props to the Icons doesn't work, so we use an invisible button here to create the product name hover
                    case "jira":
                    case "jiraserver":
                    case "jiracloud":
                    case "jiracore":
                    case "jiracoredatacenter":
                    case "jiracoreserver":
                        content = <JiraIcon appearance="brand" />;
                        break;
                    case "jirasoftwareserver":
                    case "jirasoftwaredatacenter":
                        content = <JiraSoftwareIcon appearance="brand" />;
                        break;
                    case "bitbucketcloud":
                    case "bitbucketserver":
                    case "bitbucketdatacenter":
                        content = <BitbucketIcon appearance="brand" />;
                        break;
                    case "confluencecloud":
                    case "confluenceserver":
                    case "confluencedatacenter":
                        content = <ConfluenceIcon appearance="brand" />;
                        break;
                    case "compass":
                        content = <CompassIcon appearance="brand" />;
                        break;
                    case "trello":
                        content = <TrelloIcon appearance="brand" />;
                        break;
                    case "opsgenie":
                        content = <OpsgenieIcon appearance="brand" />;
                        break;
                    case "jiraservicemanagement":
                    case "jiraservicemanagementdatacenter":
                    case "jiraservicemanagementserver":
                        content = <JiraServiceManagementIcon appearance="brand" />;
                        break;
                    case "overflow":
                        return (
                            <span className="overflow-badge">
                                <Badge key="overflow">+{affectedOverflowCount}</Badge>
                            </span>
                        );
                    default:
                        content = <AtlassianIcon appearance="brand" />;
                        break;
                }

                return (
                    <Tooltip content={aff} key={aff}>
                        {tooltipProps => (
                            <Button spacing="none" appearance="subtle-link" className="logo-button" {...tooltipProps}>
                                {content}
                            </Button>
                        )}
                    </Tooltip>
                );
            })}
        </div>
    );
}

export function getSeverity(cvss_severity: string, cve_severity: number) {
    let formattedSeverity = "";
    if (cvss_severity === "" || cvss_severity === undefined || cve_severity === undefined) {
        formattedSeverity = "None";
    } else {
        formattedSeverity = cve_severity.toFixed(1) + " " + cvss_severity;
    }

    return (
        <span>
            {(() => {
                switch (cvss_severity) {
                    case Cvss.Critical:
                        return <Lozenge appearance="removed">{formattedSeverity}</Lozenge>;
                    case Cvss.High:
                        return <Lozenge appearance="moved">{formattedSeverity}</Lozenge>;
                    case Cvss.Medium:
                        return <Lozenge appearance="new">{formattedSeverity}</Lozenge>;
                    case Cvss.Low:
                        return <Lozenge appearance="default">{formattedSeverity}</Lozenge>;
                    default:
                        return <></>;
                }
            })()}
        </span>
    );
}

export function getDisplayDate(cve_publish_date: string) {
    if (!cve_publish_date) {
        return "";
    }

    let date_parts = cve_publish_date.split("T")[0].split("-");
    let displayDate = date_parts[1] + "/" + date_parts[2] + "/" + date_parts[0];

    return displayDate;
}

export function calculateVersionValues(product: OptionType, productVersionMap: ProductVersionsObject): OptionsType {
    if (Object.keys(product).length === 0) {
        return [];
    }
    return productVersionMap[product.value]
        .map(value => ({
            label: value,
            value: product.label + "-" + value,
        }))
        .sort((a, b) => CompareSemanticVersions(a.label, b.label));
}

export function applyFilters(
    product: OptionType,
    version: OptionType,
    publishDate: string,
    severities: OptionsType,
    searchCriteria: string,
    cveData: CVEObject
): CVEObject {
    if (!product.value && !version.value && publishDate === "" && severities.length === 0 && searchCriteria === "") {
        return cveData;
    }
    return Object.entries(cveData)
        .filter(e => (!product.value && !version.value ? true : productVersionFilter(product, version, e)))
        .filter(e =>
            publishDate === "" ? true : Date.parse(e[1].cve_publish_date.split("T")[0]) >= Date.parse(publishDate)
        )
        .filter(e => (severities.length === 0 ? true : severities.map(e => e.value).includes(e[1].cvss_severity)))
        .filter(e =>
            searchCriteria === ""
                ? true
                : e[1].cve_id.toLowerCase().includes(searchCriteria.toLowerCase()) ||
                  e[1].cve_summary.toLowerCase().includes(searchCriteria.toLowerCase())
        )
        .reduce((res, obj) => {
            res[obj[0]] = obj[1];
            return res;
        }, {} as CVEObject);
}

function productVersionFilter(product: OptionType, version: OptionType, entry: [string, CVETableData]): boolean {
    const pr = Object.entries(entry[1].affected_products).some(p => product.value === p[0]);
    if (Object.keys(version).length > 0) {
        return Object.entries(entry[1].affected_products).some(
            productToVersionsMap =>
                // Check if product is selected
                product.value === productToVersionsMap[0] &&
                versionFilterPredicate(String(version.value), productToVersionsMap)
        );
    }
    return pr;
}

function versionFilterPredicate(value: string, productToVersionsMap: [string, ProductData]) {
    // Get the group and the version from the selected filter option
    let filteredOptions = value.split("-");

    // true if a version is selected for a product
    let isFilterSelected =
        filteredOptions[0] === productToVersionsMap[0] &&
        Object.entries(productToVersionsMap[1])
            .filter(v => v[1] === "AFFECTED")
            // Get a list of the versions from the CVE and check if they exist in the filtered version
            .map(a => a[0])
            .includes(filteredOptions[1].toString());

    // true if user-inputted version is a vulnerable (affected) version
    let affectedUserVersion =
        filteredOptions[0] === productToVersionsMap[0] &&
        !Object.entries(productToVersionsMap[1])
            .map(a => a[0])
            .includes(filteredOptions[1].toString()) &&
        checkVersionAffected(filteredOptions[1], Object.entries(productToVersionsMap[1]));

    // true if no versions are selected for a product
    let noVersionsSelected = !value.includes(productToVersionsMap[0]);

    // show a cve if it is filtered or if there is no version selected for that product.
    return isFilterSelected || affectedUserVersion || noVersionsSelected;
}

// takes a version and checks if it is within the ranges of a list of fixed versions (sorted from lowest to highest)
// returns true if not within the fixed versions (therefore it's a vulnerable/affected version)
// returns false otherwise
function checkVersionAffected(selectedVersion: string, versions: [string, string][]): boolean {
    let searchVersion = parse(selectedVersion);
    let parsedFixedVersion = versions
        .filter(v => v[1] === "FIXED")
        .map(v => parse(v[0]))
        .filter(v => v != null)
        .sort((a, b) => CompareSemanticVersions(String(a), String(b)));
    let parsedIntroducedVersion = versions
        .filter(v => v[1] === "AFFECTED")
        .map(v => parse(v[0]))
        .filter(v => v != null)
        .sort((a, b) => CompareSemanticVersions(String(a), String(b)));

    if (searchVersion == null) {
        return false;
    }

    let affected = false;
    for (let fixed of parsedFixedVersion) {
        // only compare patch version ranges if the version's major & minor values are the same as any fixed version
        if (searchVersion.major === fixed?.major && searchVersion.minor === fixed?.minor) {
            return fixed?.patch === undefined || searchVersion.patch < fixed?.patch;
        }

        // only compare minor version ranges if the version's major values are the same as any fixed version
        if (searchVersion.major === fixed?.major) {
            affected = fixed?.minor === undefined || searchVersion.minor < fixed?.minor;
        }
    }

    // any version outside of the latest fixed version range isn't affected
    if (
        parsedFixedVersion[parsedFixedVersion.length - 1]?.major !== undefined &&
        searchVersion.major > parsedFixedVersion[parsedFixedVersion.length - 1]?.major
    ) {
        return false;
    }

    // any version under the earliest fixed version and still in the introduced version is affected
    if (
        parsedFixedVersion[0]?.major !== undefined &&
        parsedIntroducedVersion[0]?.major !== undefined &&
        searchVersion.major < parsedFixedVersion[0]?.major &&
        searchVersion.major >= parsedIntroducedVersion[0]?.major
    ) {
        return true;
    }

    return affected;
}

export const CompareSemanticVersions = (a: string, b: string) => {
    // 1. Split the strings into their parts.
    const a1 = a.split(".");
    const b1 = b.split(".");
    // 2. Contingency in case there's a 4th or 5th version
    const len = Math.min(a1.length, b1.length);
    // 3. Look through each version number and compare.
    for (let i = 0; i < len; i++) {
        const a2 = +a1[i] || 0;
        const b2 = +b1[i] || 0;

        if (a2 !== b2) {
            return a2 > b2 ? 1 : -1;
        }
    }

    // 4. We hit this if the all checked versions so far are equal
    return b1.length - a1.length;
};

export default CVETable;
