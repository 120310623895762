/**
 * Logic taken from https://github.com/WesSouza/calendar-base which is not maintained for quite sometime.
 * This will help us fixing any issue we might get or any new functionality we might want to support.
 * Not changing much code below. Just removed those parts which we don't need.
 */

import { daysPerWeek, monthsPerYear } from '../constants';
import getDaysInMonth from './get-days-in-month';
export default function getBaseCalendar(year, month) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      weekStartDay: 0
    },
    _ref$weekStartDay = _ref.weekStartDay,
    weekStartDay = _ref$weekStartDay === void 0 ? 0 : _ref$weekStartDay;
  var date = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0));
  var utcYear = date.getUTCFullYear();
  var utcMonth = date.getUTCMonth();
  var calendar = [];
  var firstDay = date.getUTCDay();
  var firstDate = -((daysPerWeek - weekStartDay + firstDay) % daysPerWeek);
  var lastDate = getDaysInMonth(utcYear, utcMonth);
  var lastDay = (lastDate - firstDate) % daysPerWeek;
  var lastDateOfPreviousMonth = getDaysInMonth(utcYear, utcMonth - 1);
  var dateCounter = firstDate;
  var currentDay;
  var currentDate;
  var currentDateObject = null;
  var calculatedMonth;
  var calculatedYear;
  var maxDateCount = lastDate - dateCounter + (lastDay !== 0 ? daysPerWeek - lastDay : 0) + firstDate;
  while (dateCounter < maxDateCount) {
    currentDate = dateCounter + 1;
    currentDay = ((dateCounter < 1 ? daysPerWeek + dateCounter : dateCounter) + firstDay) % daysPerWeek;
    if (currentDate < 1 || currentDate > lastDate) {
      if (currentDate < 1) {
        calculatedMonth = utcMonth - 1;
        calculatedYear = utcYear;
        if (calculatedMonth < 0) {
          calculatedMonth = monthsPerYear - 1;
          calculatedYear--;
        }
        currentDate = lastDateOfPreviousMonth + currentDate;
      } else if (currentDate > lastDate) {
        calculatedMonth = utcMonth + 1;
        calculatedYear = utcYear;
        if (calculatedMonth > monthsPerYear - 1) {
          calculatedMonth = 0;
          calculatedYear++;
        }
        currentDate = dateCounter - lastDate + 1;
      }
      if (calculatedMonth !== undefined && calculatedYear !== undefined) {
        currentDateObject = {
          day: currentDate,
          weekDay: currentDay,
          month: calculatedMonth,
          year: calculatedYear,
          siblingMonth: true
        };
      }
    } else {
      currentDateObject = {
        day: currentDate,
        weekDay: currentDay,
        month: utcMonth,
        year: utcYear
      };
    }
    calendar.push(currentDateObject);
    dateCounter++;
  }
  return calendar;
}