import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
import { normalizeUrl } from '@atlaskit/linking-common/url';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ANALYTICS_CHANNEL } from '../../../../utils/analytics';
var toUrl = function toUrl(url, base) {
  try {
    return new URL(url, base);
  } catch (_unused) {
    return undefined;
  }
};
export var useLinkWarningModal = function useLinkWarningModal() {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    unsafeLinkText = _useState2[0],
    setUnsafeLinkText = _useState2[1];
  var _useState3 = useState(null),
    _useState4 = _slicedToArray(_useState3, 2),
    url = _useState4[0],
    setUrl = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    isOpen = _useState6[0],
    setIsOpen = _useState6[1];
  var _useAnalyticsEvents = useAnalyticsEvents(),
    createAnalyticsEvent = _useAnalyticsEvents.createAnalyticsEvent;
  var sendWarningModalViewedEvent = function sendWarningModalViewedEvent() {
    createAnalyticsEvent({
      action: 'viewed',
      actionSubject: 'linkSafetyWarningModal',
      eventType: 'screen',
      name: 'linkSafetyWarningModal'
    }).fire(ANALYTICS_CHANNEL);
  };
  var sendContinueClickedEvent = function sendContinueClickedEvent() {
    createAnalyticsEvent({
      action: 'clicked',
      actionSubject: 'button',
      actionSubjectId: 'linkSafetyWarningModalContinue',
      eventType: 'ui',
      screen: 'linkSafetyWarningModal'
    }).fire(ANALYTICS_CHANNEL);
  };

  /**
   * It checks and warns if a link text is a URL and different from an actual link destination
   */
  var isLinkSafe = function isLinkSafe(event, href) {
    if (!href) {
      return true;
    }
    var anchor = event.currentTarget;
    var linkText = anchor.innerText;
    var normalisedUrlFromLinkText = normalizeUrl(linkText);
    if (!normalisedUrlFromLinkText) {
      return true;
    }
    var anchorLinkRegex = new RegExp(/^#/im);
    var isLinkTextAnchorLink = anchorLinkRegex.test(linkText);
    var isAnchorLink = anchorLinkRegex.test(href);
    if (isAnchorLink || isLinkTextAnchorLink) {
      return true;
    }
    var relativeLinkRegex = new RegExp(/^\//im);
    var isRelativeHrefLink = relativeLinkRegex.test(href);
    if (isRelativeHrefLink) {
      return true;
    }
    var hrefUrl = toUrl(href, window.location.origin);
    var linkTextUrl = toUrl(normalisedUrlFromLinkText, hrefUrl === null || hrefUrl === void 0 ? void 0 : hrefUrl.origin);
    if (!hrefUrl || !linkTextUrl) {
      return true;
    }
    var httpProtocols = ['http:', 'https:'];
    var areProtocolsEquivalent;
    if (httpProtocols.includes(linkTextUrl.protocol) && httpProtocols.includes(hrefUrl.protocol)) {
      var noUserNameAndPassword = !linkTextUrl.username && !linkTextUrl.password && !hrefUrl.username && !hrefUrl.password;
      areProtocolsEquivalent = noUserNameAndPassword;
    } else {
      areProtocolsEquivalent = linkTextUrl.protocol === hrefUrl.protocol;
    }
    var areEquivalentLinks = linkTextUrl.hostname === hrefUrl.hostname && linkTextUrl.pathname === hrefUrl.pathname && linkTextUrl.search === hrefUrl.search && linkTextUrl.username === hrefUrl.username && linkTextUrl.password === hrefUrl.password && areProtocolsEquivalent;
    if (!areEquivalentLinks) {
      return false;
    }
    return true;
  };
  var showSafetyWarningModal = function showSafetyWarningModal(event, href) {
    event.preventDefault();
    var anchor = event.currentTarget;
    var linkText = anchor.innerText;
    setUnsafeLinkText(linkText);
    href && setUrl(href);
    setIsOpen(true);
    sendWarningModalViewedEvent();
  };
  var onClose = function onClose() {
    setIsOpen(false);
    setUnsafeLinkText(null);
    setUrl(null);
  };
  var onContinue = function onContinue() {
    onClose();
    sendContinueClickedEvent();
    url && window.open(url, '_blank', 'noopener noreferrer');
  };
  return {
    isLinkSafe: isLinkSafe,
    isOpen: isOpen,
    onClose: onClose,
    onContinue: onContinue,
    showSafetyWarningModal: showSafetyWarningModal,
    unsafeLinkText: unsafeLinkText,
    url: url
  };
};