export function isNestedHeaderLinksEnabled(allowHeadingAnchorLinks) {
  // If it's a boolean or undefined, then we don't support nesting.
  if (!allowHeadingAnchorLinks || typeof allowHeadingAnchorLinks === 'boolean') {
    return false;
  }
  var allowNestedHeaderLinks = allowHeadingAnchorLinks.allowNestedHeaderLinks;
  // Explicit cast to a boolean to cover scenario where it's undefined.
  return !!allowNestedHeaderLinks;
}
export function getActiveHeadingId(allowHeadingAnchorLinks) {
  if (!isNestedHeaderLinksEnabled(allowHeadingAnchorLinks)) {
    return undefined;
  }
  return allowHeadingAnchorLinks.activeHeadingId;
}