var isPerformanceAPIAvailable = function isPerformanceAPIAvailable() {
  return typeof window !== 'undefined' && 'performance' in window && ['measure', 'clearMeasures', 'clearMarks', 'getEntriesByName', 'getEntriesByType'].every(function (api) {
    return !!performance[api];
  });
};
var hasPerformanceAPIAvailable = isPerformanceAPIAvailable();
export var getMarkName = function getMarkName(id, status) {
  return "".concat(status, ":").concat(id);
};
export var getMeasureName = function getMeasureName(id, status) {
  return "time-to-".concat(status, ":").concat(id);
};
export var clearMarks = function clearMarks(id) {
  if (hasPerformanceAPIAvailable) {
    var marks = performance.getEntriesByType('mark').filter(function (mark) {
      return mark.name.includes(id);
    });
    marks.forEach(function (mark) {
      return performance.clearMarks(mark.name);
    });
  }
};
export var clearMeasures = function clearMeasures(id) {
  if (hasPerformanceAPIAvailable) {
    var measures = performance.getEntriesByType('measure').filter(function (measure) {
      return measure.name.includes(id);
    });
    measures.forEach(function (measure) {
      return performance.clearMeasures(measure.name);
    });
  }
};
var getMark = function getMark(id, status) {
  if (hasPerformanceAPIAvailable) {
    var name = getMarkName(id, status);
    var marks = performance.getEntriesByName(name);
    if (marks.length > 0) {
      return marks[0];
    }
    return undefined;
  }
};
export var getMeasure = function getMeasure(id, status) {
  if (hasPerformanceAPIAvailable) {
    var name = getMeasureName(id, status);
    var measures = performance.getEntriesByName(name);
    if (measures.length > 0) {
      return measures[0];
    }
    return undefined;
  }
};
export var mark = function mark(id, status) {
  if (hasPerformanceAPIAvailable) {
    var name = getMarkName(id, status);
    performance.mark(name);
  }
};
export var create = function create(id, status) {
  if (hasPerformanceAPIAvailable) {
    var name = getMeasureName(id, status);
    var measure = getMeasure(id, status);
    if (!measure) {
      var startMark = getMark(id, 'pending');
      var endMark = getMark(id, status);
      if (startMark && endMark) {
        performance.measure(name, startMark.name, endMark.name);
      }
    }
  }
};