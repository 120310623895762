import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import { css } from '@emotion/react';
import { B300 } from '@atlaskit/theme/colors';
import { DEFAULT_INLINE_IMAGE_ASPECT_RATIO, DEFAULT_INLINE_IMAGE_BORDER_SIZE, referenceHeights } from './constants';
export var INLINE_IMAGE_WRAPPER_CLASS_NAME = 'media-inline-image-wrapper';
export var INLINE_IMAGE_ASPECT_RATIO_CSS_VAR_KEY = '--editor-media-inline-image-aspect-ratio';
export var INLINE_IMAGE_BORDER_SIZE_CSS_VAR_KEY = '--editor-media-inline-image-border-size';
export var INLINE_IMAGE_BORDER_COLOR_CSS_VAR_KEY = '--editor-media-inline-image-border-color';

// We implemented such selectors to ensure specificity:
// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Nested and dynamic css calls are violations, needs manual remediation
var inlineImageHeight = function inlineImageHeight(height) {
  var margin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  /* Editor style */\n  > .mediaInlineView-content-wrap > .", ",\n  > :is(a, span[data-mark-type='border']) .mediaInlineView-content-wrap > .", ",\n  /* Renderer style */\n  > .", ",\n  > :is(a, span[data-mark-type='border']) .", " {\n    height: ", "px;\n    transform: translateY(", "px);\n  }\n"])), INLINE_IMAGE_WRAPPER_CLASS_NAME, INLINE_IMAGE_WRAPPER_CLASS_NAME, INLINE_IMAGE_WRAPPER_CLASS_NAME, INLINE_IMAGE_WRAPPER_CLASS_NAME, height, margin);
};

/**
 * Shifting the mediaInline image component (renders image) to align nicely with
 * mediaInline (renders text) is a bit of a testing ground. This numbers represent
 * shift in top and bottom and size adjustments to make up for lack of 1to1 size
 * mapping
 */
// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Nested and dynamic css calls are violations, needs manual remediation
export var mediaInlineImageStyles = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  // p, h3, and action items\n  .", " {\n    height: ", "px;\n    transform: translateY(-2px);\n  }\n\n  h1 {\n    ", "\n  }\n\n  h2 {\n    ", "\n  }\n\n  h3 {\n    ", "\n  }\n\n  h4 {\n    ", "\n  }\n\n  h5 {\n    ", "\n  }\n\n  h6 {\n    ", "\n  }\n"])), INLINE_IMAGE_WRAPPER_CLASS_NAME, referenceHeights['p'], inlineImageHeight(referenceHeights['h1'], -3), inlineImageHeight(referenceHeights['h2'], -3), inlineImageHeight(referenceHeights['h3'], -2), inlineImageHeight(referenceHeights['h4'], -2), inlineImageHeight(referenceHeights['h5'], -2), inlineImageHeight(referenceHeights['h6'], -2));
export var wrapperStyle = css({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  borderRadius: "var(--ds-border-radius, 3px)",
  aspectRatio: "var(".concat(INLINE_IMAGE_ASPECT_RATIO_CSS_VAR_KEY, ", ").concat(DEFAULT_INLINE_IMAGE_ASPECT_RATIO, ")"),
  maxWidth: '100%'
});
export var selectedStyle = css({
  cursor: 'pointer',
  boxShadow: "0 0 0 1px ".concat("var(--ds-border-selected, ".concat(B300, ")")),
  outline: 'none'
});
export var interactiveStyle = css({
  cursor: 'pointer'
});
export var borderStyle = css({
  borderColor: "var(".concat(INLINE_IMAGE_BORDER_COLOR_CSS_VAR_KEY, ")"),
  borderStyle: 'solid',
  borderRadius: "calc(var(".concat(INLINE_IMAGE_BORDER_SIZE_CSS_VAR_KEY, ", ").concat(DEFAULT_INLINE_IMAGE_BORDER_SIZE, ") * 2px)"),
  borderWidth: "calc(var(".concat(INLINE_IMAGE_BORDER_SIZE_CSS_VAR_KEY, ", ").concat(DEFAULT_INLINE_IMAGE_BORDER_SIZE, ") * 1px)"),
  boxSizing: "border-box"
});