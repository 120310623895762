import { range } from 'rxjs/observable/range';
import { map } from 'rxjs/operators/map';
export var slicenator = function slicenator(blob, _ref) {
  var chunkSize = _ref.size;
  var totalChunks = Math.ceil(blob.size / chunkSize);
  return range(0, totalChunks).pipe(map(function (index) {
    return {
      partNumber: index + 1,
      blob: blob.slice(index * chunkSize, (index + 1) * chunkSize)
    };
  }));
};