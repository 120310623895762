import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/** @jsx jsx */
import React, { useCallback, useRef } from 'react';
import { css, jsx } from '@emotion/react';
import { injectIntl } from 'react-intl-next';
import { relativeFontSizeToBase16 } from '@atlaskit/editor-shared-styles';
import QuestionsIcon from '@atlaskit/icon/glyph/question-circle';
import { N30, N50 } from '@atlaskit/theme/colors';
import { borderRadius, fontSize } from '@atlaskit/theme/constants';
import Tooltip from '@atlaskit/tooltip';
import { ACTION_SUBJECT_ID } from '../../analytics';
import { unsupportedContentMessages } from '../../messages/unsupportedContent';
import { trackUnsupportedContentTooltipDisplayedFor } from '../../utils/track-unsupported-content';
import { getUnsupportedContent } from '../unsupported-content-helper';
var blockNodeStyle = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n  border: 1px dashed ", ";\n  border-radius: ", "px;\n  box-sizing: border-box;\n  cursor: default;\n  display: flex;\n  font-size: ", ";\n  margin: ", " 0;\n  min-height: 24px;\n  padding: ", ";\n  text-align: center;\n  vertical-align: text-bottom;\n  min-width: 120px;\n  align-items: center;\n  justify-content: center;\n"])), "var(--ds-background-disabled, ".concat(N30, ")"), "var(--ds-border-disabled, ".concat(N50, ")"), borderRadius(), relativeFontSizeToBase16(fontSize()), "var(--ds-space-100, 8px)", "var(--ds-space-150, 12px)");
var UnsupportedBlockNode = function UnsupportedBlockNode(_ref) {
  var node = _ref.node,
    intl = _ref.intl,
    dispatchAnalyticsEvent = _ref.dispatchAnalyticsEvent;
  var message = getUnsupportedContent(unsupportedContentMessages.unsupportedBlockContent, unsupportedContentMessages.unsupportedBlockContent.defaultMessage + ':', node, intl);
  var tooltipContent = intl.formatMessage(unsupportedContentMessages.unsupportedContentTooltip);
  var _useRef = useRef({
      padding: '4px'
    }),
    style = _useRef.current;
  var originalNodeType = node === null || node === void 0 ? void 0 : node.attrs.originalValue.type;
  var tooltipOnShowHandler = useCallback(function () {
    return dispatchAnalyticsEvent && trackUnsupportedContentTooltipDisplayedFor(dispatchAnalyticsEvent, ACTION_SUBJECT_ID.ON_UNSUPPORTED_BLOCK, originalNodeType);
  }, [dispatchAnalyticsEvent, originalNodeType]);
  return jsx("div", {
    css: blockNodeStyle,
    className: "unsupported"
  }, message, jsx(Tooltip, {
    content: tooltipContent,
    hideTooltipOnClick: false,
    position: "bottom",
    onShow: tooltipOnShowHandler,
    strategy: "absolute"
  }, jsx("span", {
    style: style
  }, jsx(QuestionsIcon, {
    label: "?",
    size: "small"
  }))));
};
export default injectIntl(UnsupportedBlockNode);