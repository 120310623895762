import { useEffect, useRef } from 'react';
import { useAnnotationRangeDispatch, useAnnotationRangeState } from '../contexts/AnnotationRangeContext';
import { isRangeInsideOfRendererContainer } from './utils';
export var useUserSelectionRange = function useUserSelectionRange(props) {
  var rendererDOM = props.rendererRef.current;
  var selectionTimeoutRef = useRef();
  var _useAnnotationRangeDi = useAnnotationRangeDispatch(),
    clearRange = _useAnnotationRangeDi.clearRange,
    setRange = _useAnnotationRangeDi.setRange;
  var _useAnnotationRangeSt = useAnnotationRangeState(),
    range = _useAnnotationRangeSt.range,
    draftRange = _useAnnotationRangeSt.draftRange,
    type = _useAnnotationRangeSt.type;
  useEffect(function () {
    if (!document || !rendererDOM) {
      return;
    }
    var onSelectionChange = function onSelectionChange(event) {
      if (selectionTimeoutRef.current) {
        clearTimeout(selectionTimeoutRef.current);
      }
      selectionTimeoutRef.current = setTimeout(function () {
        var sel = document.getSelection();
        if (!sel || sel.type !== 'Range' || sel.rangeCount !== 1) {
          return;
        }
        var _range = sel.getRangeAt(0);
        if (rendererDOM && isRangeInsideOfRendererContainer(rendererDOM, _range)) {
          setRange(_range.cloneRange());
        }
      }, 250);
    };
    document.addEventListener('selectionchange', onSelectionChange);
    return function () {
      document.removeEventListener('selectionchange', onSelectionChange);
      clearRange();
    };
  }, [rendererDOM, setRange, clearRange]);
  return [type === 'selection' ? range : null, draftRange, clearRange];
};