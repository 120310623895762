import axios from "axios";
import { ApiURLMap } from "../../utils/consts";

export interface RemediationStatus {
    status: string;
    affected_products: string[]; // we turn into a dictionary<string "Product": key "Versions [strings]"> to combine both calls
}

export interface Resource {
    affected_products: string[];
    cve_id: string;
    cve_summary: string;
    cve_publish_date: string;
    cve_details: string;
    cve_vector: string;
    cve_severity: number;
    atl_tracking_url: string;
    advisory_url: string;
    remediation_status: RemediationStatus;
}

export interface CVEResponse {
    resources: Resource[];
    resources_count: number;
    next_page_id: string;
    total_count: number;
}

export const getCves = (): Promise<CVEResponse> => {
    const base_url = process.env.REACT_APP_ENVIRONMENT
        ? ApiURLMap[process.env.REACT_APP_ENVIRONMENT] || ApiURLMap["development"]
        : ApiURLMap["development"];

    const uri = `${base_url}/v1/cves`;
    let config: any = {
        format: "adf",
    };
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
        config = {
            format: "adf",
            Authorization: `Slauth ${process.env.SLAUTH_TOKEN}`,
        };
    }

    return axios
        .get(uri, config)
        .then((response): CVEResponse => response.data as CVEResponse)
        .catch(e => {
            throw new Error(e);
        });
};

export const getCveById = (cve_ids: string[]): Promise<CVEResponse> => {
    const base_url = process.env.REACT_APP_ENVIRONMENT
        ? ApiURLMap[process.env.REACT_APP_ENVIRONMENT] || ApiURLMap["development"]
        : ApiURLMap["development"];

    const uri = `${base_url}/v1/cves`;
    let config: any = {};

    if (cve_ids) {
        config.params = {
            cve_ids: cve_ids.join(","),
            format: "adf",
        };
    }
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
        config.headers = {
            Authorization: `Slauth ${process.env.SLAUTH_TOKEN}`,
        };
    }
    return axios
        .get(uri, config)
        .then((response): CVEResponse => response.data as CVEResponse)
        .catch(e => {
            throw new Error(e);
        });
};
