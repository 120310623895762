import { getUnsupportedContentLevelData, getAnalyticsAppearance } from '@atlaskit/editor-common/utils';
import { ACTION, ACTION_SUBJECT, EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { PLATFORM } from './events';
var rendersMap = {};
var processLevelsAndTrack = function processLevelsAndTrack(item, thresholds, dispatchAnalyticsEvent) {
  try {
    var _getUnsupportedConten = getUnsupportedContentLevelData(item.doc, thresholds),
      severity = _getUnsupportedConten.severity,
      percentage = _getUnsupportedConten.percentage,
      _getUnsupportedConten2 = _getUnsupportedConten.counts,
      supportedNodes = _getUnsupportedConten2.supportedNodes,
      unsupportedNodes = _getUnsupportedConten2.unsupportedNodes,
      unsupportedNodeTypeCount = _getUnsupportedConten2.unsupportedNodeTypeCount;
    dispatchAnalyticsEvent({
      action: ACTION.UNSUPPORTED_CONTENT_LEVELS_TRACKING_SUCCEEDED,
      actionSubject: ACTION_SUBJECT.RENDERER,
      attributes: {
        appearance: getAnalyticsAppearance(item.appearance),
        platform: PLATFORM.WEB,
        unsupportedContentLevelSeverity: severity,
        unsupportedContentLevelPercentage: percentage,
        unsupportedNodesCount: unsupportedNodes,
        supportedNodesCount: supportedNodes,
        unsupportedNodeTypeCount: unsupportedNodeTypeCount
      },
      eventType: EVENT_TYPE.OPERATIONAL
    });
  } catch (err) {
    dispatchAnalyticsEvent({
      action: ACTION.UNSUPPORTED_CONTENT_LEVELS_TRACKING_ERRORED,
      actionSubject: ACTION_SUBJECT.RENDERER,
      attributes: {
        platform: PLATFORM.WEB,
        error: err instanceof Error ? err.message : String(err)
      },
      eventType: EVENT_TYPE.OPERATIONAL
    });
  }
};
var schedule = function schedule(fn) {
  if (typeof window.requestIdleCallback === 'function') {
    window.requestIdleCallback(fn);
  } else {
    setTimeout(fn, 0);
  }
};
var DEFAULT_SAMPLING_RATE = 100;
export var trackUnsupportedContentLevels = function trackUnsupportedContentLevels(item, trackingOptions, dispatchAnalyticsEvent) {
  var _item$appearance;
  var thresholds = trackingOptions.thresholds,
    samplingRates = trackingOptions.samplingRates;
  var appearance = (_item$appearance = item.appearance) !== null && _item$appearance !== void 0 ? _item$appearance : 'unknown';
  if (!rendersMap[appearance]) {
    rendersMap[appearance] = new Set();
  }

  // bail out if already processed a render from a given renderer instance
  var didProcessRenderer = rendersMap[appearance].has(item.rendererId);
  if (didProcessRenderer) {
    return;
  }

  // otherwise track the render
  rendersMap[appearance].add(item.rendererId);
  var sampleRate = samplingRates && samplingRates[appearance] || DEFAULT_SAMPLING_RATE;

  // sample from the first available tracked render
  if (rendersMap[appearance].size === 1) {
    schedule(function () {
      return processLevelsAndTrack(item, thresholds, dispatchAnalyticsEvent);
    });
  }
  // cleanup/refresh tracked renders at the sampling rate
  if (rendersMap[appearance].size % sampleRate === 0) {
    rendersMap[appearance] = new Set();
  }
};