import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { parseISO } from 'date-fns';
import { daysPerWeek } from '../constants';
import dateToString from '../utils/date-to-string';
import getBaseCalendar from '../utils/get-base-calendar';
function useGetCalendarWithSixthWeek(calendar, _ref) {
  var month = _ref.month,
    year = _ref.year,
    weekStartDay = _ref.weekStartDay;
  var shouldDisplaySixthWeek = calendar.length % 6;
  var calendarLastValue = calendar[calendar.length - 1];
  return useMemo(function () {
    // Some months jump between 5 and 6 weeks to display. In some cases 4 (Feb
    // with the 1st on a Monday etc). This ensures the UI doesn't jump around by
    // catering to always showing 6 weeks.
    if (shouldDisplaySixthWeek) {
      var lastDayIsSibling = calendarLastValue.siblingMonth;
      var sliceStart = lastDayIsSibling ? daysPerWeek : 0;
      return getBaseCalendar(year, month, {
        weekStartDay: weekStartDay
      }).slice(sliceStart, sliceStart + daysPerWeek).map(function (date) {
        return _objectSpread(_objectSpread({}, date), {}, {
          siblingMonth: true
        });
      });
    }
  }, [calendarLastValue, month, shouldDisplaySixthWeek, weekStartDay, year]);
}

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default function useGetWeeks(_ref2) {
  var day = _ref2.day,
    daysLong = _ref2.daysLong,
    month = _ref2.month,
    year = _ref2.year,
    today = _ref2.today,
    disabled = _ref2.disabled,
    disabledDateFilter = _ref2.disabledDateFilter,
    minDateString = _ref2.minDate,
    maxDateString = _ref2.maxDate,
    selected = _ref2.selected,
    previouslySelected = _ref2.previouslySelected,
    weekStartDay = _ref2.weekStartDay;
  var calendar = useMemo(function () {
    return getBaseCalendar(year, month - 1, {
      weekStartDay: weekStartDay
    });
  }, [month, weekStartDay, year]);
  var calendarWithSixthWeek = useGetCalendarWithSixthWeek(calendar, {
    month: month,
    year: year,
    weekStartDay: weekStartDay
  });
  if (calendarWithSixthWeek) {
    calendar.push.apply(calendar, _toConsumableArray(calendarWithSixthWeek));
  }
  var minDate = minDateString ? parseISO(minDateString) : undefined;
  var maxDate = maxDateString ? parseISO(maxDateString) : undefined;
  var needJsDate = minDateString || maxDateString;
  return useMemo(function () {
    var weeks = [];
    calendar.forEach(function (date) {
      var dateAsString = dateToString(date, {
        fixMonth: true
      });
      var JSDate = needJsDate ? parseISO(dateAsString) : undefined;
      var week;
      if (date.weekDay === weekStartDay) {
        week = {
          id: dateAsString,
          values: []
        };
        weeks.push(week);
      } else {
        week = weeks[weeks.length - 1];
      }
      var weekDayOffset = (date.weekDay - weekStartDay) % 7;

      // Define a bunch of `const`s
      var weekDayName = daysLong[weekDayOffset];
      var isDisabledByArray = disabled ? disabled.indexOf(dateAsString) > -1 : false;
      var isDisabledByFilter = disabledDateFilter ? disabledDateFilter(dateAsString) : false;
      var isDisabledByMin = minDate && JSDate ? JSDate < minDate : false;
      var isDisabledByMax = maxDate && JSDate ? JSDate > maxDate : false;
      var isDisabled = isDisabledByArray || isDisabledByFilter || isDisabledByMin || isDisabledByMax;
      var isFocused = day === date.day && !date.siblingMonth;
      var isPreviouslySelected = !isDisabled && previouslySelected.indexOf(dateAsString) > -1;
      var isSelected = !isDisabled && selected.indexOf(dateAsString) > -1;
      var isSiblingMonth = !!date.siblingMonth;
      var isToday = today === dateAsString;
      week.values.push({
        id: dateAsString,
        isDisabled: isDisabled,
        isFocused: isFocused,
        isToday: isToday,
        month: date.month + 1,
        isPreviouslySelected: isPreviouslySelected,
        isSelected: isSelected,
        isSiblingMonth: isSiblingMonth,
        year: date.year,
        day: date.day,
        weekDayName: weekDayName
      });
    });
    return weeks;
  }, [calendar, day, disabled, disabledDateFilter, minDate, maxDate, needJsDate, previouslySelected, selected, today, weekStartDay, daysLong]);
}