import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @jsx jsx */

import { useMemo, useCallback } from 'react';
import { css, jsx } from '@emotion/react';
import { AnnotationSharedCSSByState } from '@atlaskit/editor-common/styles';
import { AnnotationMarkStates } from '@atlaskit/adf-schema';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- `AnnotationSharedCSSByState` is not object-safe
var markStyles = function markStyles() {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: inherit;\n  background-color: unset;\n  -webkit-tap-highlight-color: transparent;\n\n  &[data-mark-annotation-state='", "'] {\n    ", ";\n\n    &:focus,\n    &[data-has-focus='true'] {\n      ", ";\n    }\n  }\n"])), AnnotationMarkStates.ACTIVE, AnnotationSharedCSSByState().blur, AnnotationSharedCSSByState().focus);
};
export var MarkComponent = function MarkComponent(_ref) {
  var annotationParentIds = _ref.annotationParentIds,
    children = _ref.children,
    dataAttributes = _ref.dataAttributes,
    id = _ref.id,
    state = _ref.state,
    hasFocus = _ref.hasFocus,
    onClick = _ref.onClick,
    useBlockLevel = _ref.useBlockLevel;
  var annotationIds = useMemo(function () {
    return _toConsumableArray(new Set([].concat(_toConsumableArray(annotationParentIds), [id])));
  }, [id, annotationParentIds]);
  var onMarkClick = useCallback(function (event) {
    // prevents multiple callback on overlapping annotations
    if (event.defaultPrevented || state !== AnnotationMarkStates.ACTIVE) {
      return;
    }

    // prevents from opening link URL inside webView in Safari
    event.preventDefault();
    if (getBooleanFF('platform.editor.allow-inline-comments-for-inline-nodes')) {
      event.stopPropagation();
    }
    onClick({
      eventTarget: event.target,
      annotationIds: annotationIds
    });
  }, [annotationIds, onClick, state]);
  var overriddenData = !state ? dataAttributes : _objectSpread(_objectSpread({}, dataAttributes), {}, {
    'data-mark-annotation-state': state,
    'data-has-focus': hasFocus
  });
  var accessibility = state !== AnnotationMarkStates.ACTIVE ? {
    'aria-disabled': true
  } : {
    'aria-details': annotationIds.join(', ')
  };
  return jsx(useBlockLevel ? 'div' : 'mark', _objectSpread(_objectSpread(_objectSpread(_defineProperty({
    id: id
  }, getBooleanFF('platform.editor.allow-inline-comments-for-inline-nodes') ? 'onClickCapture' : 'onClick', onMarkClick), accessibility), overriddenData), !useBlockLevel && {
    css: markStyles
  }), children);
};