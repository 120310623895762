/** Helper type for single arg function */

/**
 * Compose 1 to n functions.
 * @param func first function
 * @param funcs additional functions
 */
export function compose(func) {
  for (var _len = arguments.length, funcs = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    funcs[_key - 1] = arguments[_key];
  }
  var allFuncs = [func].concat(funcs);
  return function composed(raw) {
    return allFuncs.reduceRight(function (memo, func) {
      return func(memo);
    }, raw);
  };
}