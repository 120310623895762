import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from '@emotion/react';
var CSS_VAR_WIDTH = '--local-dynamic-table-width';
export var truncationWidthStyles = css({
  width: "var(".concat(CSS_VAR_WIDTH, ")")
});
export var fixedSizeTruncateStyles = css({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});
export var overflowTruncateStyles = css({
  overflow: 'hidden'
});
export var getTruncationStyleVars = function getTruncationStyleVars(_ref) {
  var width = _ref.width;
  return typeof width !== 'undefined' ? _defineProperty({}, CSS_VAR_WIDTH, "".concat(width, "%")) : undefined;
};
export var cellStyles = css({
  padding: "var(--ds-space-050, 4px)".concat(" ", "var(--ds-space-100, 8px)"),
  border: 'none',
  textAlign: 'left',
  '&:first-of-type': {
    paddingLeft: "var(--ds-space-0, 0px)"
  },
  '&:last-child': {
    paddingRight: "var(--ds-space-0, 0px)"
  }
});