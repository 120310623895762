import { mediaState } from '@atlaskit/media-core';
export var globalMediaEventEmitter = {
  on: function on(event, listener) {
    if (mediaState.eventEmitter) {
      mediaState.eventEmitter.on(event, listener);
    }
  },
  off: function off(event, listener) {
    if (mediaState.eventEmitter) {
      mediaState.eventEmitter.off(event, listener);
    }
  },
  emit: function emit(event, payload) {
    if (mediaState.eventEmitter) {
      return mediaState.eventEmitter.emit(event, payload);
    }
    return undefined;
  }
};