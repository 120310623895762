/** @jsx jsx */
import { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { Y75 } from '@atlaskit/theme/colors';
import VisuallyHidden from '@atlaskit/visually-hidden';
var decoration = css({
  // Required as otherwise the following bidi characters cause the span
  // to not receive hover events.
  //
  // U+2066 LEFT-TO-RIGHT ISOLATE (when using pseudo element before)
  // U+202E RIGHT-TO-LEFT OVERRIDE' (when using pseudo element after)
  position: 'relative',
  ':before': {
    display: 'inline-flex',
    padding: "var(--ds-space-0, 0px)".concat(" ", "var(--ds-space-050, 4px)"),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    background: "var(--ds-background-warning, ".concat(Y75, ")"),
    color: "var(--ds-text-warning, #7F5F01)",
    content: '"<"attr(data-bidi-character-code)">"',
    fontSize: "var(--ds-font-size-100, 14px)",
    fontStyle: 'normal',
    lineHeight: '18px',
    /**
     * Ensures the decoration receives pointer events when it occurs with
     * an ancestor that disables them.
     */
    pointerEvents: 'auto'
  },
  ':hover:before': {
    background: "var(--ds-background-warning-hovered, ".concat(Y75, ")"),
    color: "var(--ds-text-warning, #533F04)"
  }
});
export function Decorator(_ref) {
  var bidiCharacter = _ref.bidiCharacter,
    children = _ref.children,
    testId = _ref.testId;
  var bidiCharacterCode = getBidiCharacterCode(bidiCharacter);
  return jsx(Fragment, null, jsx("span", {
    css: decoration,
    "data-testid": testId,
    "data-bidi-character-code": bidiCharacterCode
    // This is set to true so that the content is not read out by
    // screen readers as the content includes angle brackets for
    // visual decoration purposes.
    // We use a visually hidden `mark` element below for screen readers
    ,
    "aria-hidden": "true"
  }, children), jsx(VisuallyHidden, {
    testId: testId && "".concat(testId, "--visually-hidden")
  }, jsx("mark", null, bidiCharacterCode)));
}
function getBidiCharacterCode(bidiCharacter) {
  var _bidiCharacter$codePo;
  var bidiCharacterCode = (_bidiCharacter$codePo = bidiCharacter.codePointAt(0)) === null || _bidiCharacter$codePo === void 0 ? void 0 : _bidiCharacter$codePo.toString(16);
  return "U+".concat(bidiCharacterCode);
}