/** @jsx jsx */
import { jsx } from '@emotion/react';
export default function Border(props) {
  var _props$color, _props$size;
  var borderColor = (_props$color = props.color) !== null && _props$color !== void 0 ? _props$color : '';
  var borderSize = (_props$size = props.size) !== null && _props$size !== void 0 ? _props$size : 0;
  return jsx("span", {
    "data-color": borderColor,
    "data-size": borderSize,
    "data-mark-type": "border"
  }, props.children);
}