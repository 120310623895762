function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import React from 'react';
export var findTextString = function findTextString(reactNode) {
  var result = null;
  var children = React.Children.toArray(reactNode);
  var _iterator = _createForOfIteratorHelper(children),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var childNode = _step.value;
      if (result) {
        break;
      } else if (typeof childNode === 'string') {
        result = childNode;
      } else if (isReactElement(childNode) && childNode.props.children) {
        result = findTextString(childNode.props.children);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return result;
};
function isReactElement(child) {
  return !!child.type;
}
export var splitText = function splitText(text, _ref) {
  var startOffset = _ref.startOffset,
    endOffset = _ref.endOffset;
  if (endOffset > text.length || endOffset - startOffset <= 0) {
    return null;
  }
  return [text.slice(0, startOffset), text.slice(startOffset, endOffset), text.slice(endOffset)].filter(Boolean);
};
export var calcTextSplitOffset = function calcTextSplitOffset(position, textPosition, text) {
  var start = textPosition.start,
    end = textPosition.end;
  var startOffset = Math.max(position.from - start, 0);
  var endOffset = Math.min(Math.abs(end - position.to - text.length), text.length);
  return {
    startOffset: startOffset,
    endOffset: endOffset
  };
};