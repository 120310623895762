import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["component"],
  _excluded2 = ["component"];
/** @jsx jsx */

import { useCallback, useRef } from 'react';
import { jsx } from '@emotion/react';
import ArrowLeft from '@atlaskit/icon/glyph/arrow-left';
import { ExitingPersistence, SlideIn, useExitingPersistence } from '@atlaskit/motion';
import { animationTimingFunction, transitionDurationMs } from '../../constants';
import { createExtender } from '../utils';
import ContentOverrides from './content';
import DrawerWrapper from './drawer-wrapper';
import FocusLock from './focus-lock';
import IconButton from './icon-button';
import SidebarOverrides from './sidebar';

// Misc.
// ------------------------------

var defaults = {
  Sidebar: SidebarOverrides,
  Content: ContentOverrides
};

/**
 * This wrapper is used to specify separate durations for enter and exit.
 */
var CustomSlideIn = function CustomSlideIn(_ref) {
  var children = _ref.children,
    onFinish = _ref.onFinish;
  var _useExitingPersistenc = useExitingPersistence(),
    isExiting = _useExitingPersistenc.isExiting;

  /**
   * The actual duration should be the same for both enter and exit,
   * but motion halves the passed duration for exit animations,
   * so we double it when exiting.
   */
  var duration = isExiting ? transitionDurationMs * 2 : transitionDurationMs;
  return jsx(SlideIn, {
    animationTimingFunction: animationTimingFunction,
    duration: duration,
    enterFrom: "left",
    exitTo: "left",
    fade: "none",
    onFinish: onFinish
  }, children);
};
var DrawerPrimitive = function DrawerPrimitive(_ref2) {
  var children = _ref2.children,
    Icon = _ref2.icon,
    _ref2$closeLabel = _ref2.closeLabel,
    closeLabel = _ref2$closeLabel === void 0 ? 'Close drawer' : _ref2$closeLabel,
    onClose = _ref2.onClose,
    onCloseComplete = _ref2.onCloseComplete,
    onOpenComplete = _ref2.onOpenComplete,
    overrides = _ref2.overrides,
    testId = _ref2.testId,
    isOpen = _ref2.in,
    shouldReturnFocus = _ref2.shouldReturnFocus,
    autoFocusFirstElem = _ref2.autoFocusFirstElem,
    isFocusLockEnabled = _ref2.isFocusLockEnabled,
    width = _ref2.width,
    label = _ref2.label,
    titleId = _ref2.titleId;
  var getOverrides = createExtender(defaults, overrides);
  var _getOverrides = getOverrides('Sidebar'),
    Sidebar = _getOverrides.component,
    sideBarOverrides = _objectWithoutProperties(_getOverrides, _excluded);
  var _getOverrides2 = getOverrides('Content'),
    Content = _getOverrides2.component,
    contentOverrides = _objectWithoutProperties(_getOverrides2, _excluded2);

  /**
   * A ref to point to our wrapper, passed to `onCloseComplete` and `onOpenComplete` callbacks.
   */
  var drawerRef = useRef(null);
  var onFinish = useCallback(function (state) {
    if (state === 'entering') {
      onOpenComplete === null || onOpenComplete === void 0 || onOpenComplete(drawerRef.current);
    } else if (state === 'exiting') {
      onCloseComplete === null || onCloseComplete === void 0 || onCloseComplete(drawerRef.current);
    }
  }, [onCloseComplete, onOpenComplete]);
  return jsx(ExitingPersistence, {
    appear: true
  }, isOpen && jsx(CustomSlideIn, {
    onFinish: onFinish
  }, function (_ref3) {
    var className = _ref3.className;
    return jsx(FocusLock, {
      autoFocusFirstElem: autoFocusFirstElem,
      isFocusLockEnabled: isFocusLockEnabled,
      shouldReturnFocus: shouldReturnFocus
    }, jsx(DrawerWrapper, {
      className: className,
      width: width,
      testId: testId,
      drawerRef: drawerRef,
      label: label,
      titleId: titleId
    }, jsx(Sidebar, sideBarOverrides, jsx(IconButton, {
      onClick: onClose,
      testId: testId && 'DrawerPrimitiveSidebarCloseButton'
    }, Icon ? jsx(Icon, {
      size: "large",
      label: closeLabel
    }) : jsx(ArrowLeft, {
      label: closeLabel
    }))), jsx(Content, contentOverrides, children)));
  }));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default DrawerPrimitive;