import { bufferCount } from 'rxjs/operators/bufferCount';
import { concatMap } from 'rxjs/operators/concatMap';
var processinator = function processinator(blobs$, options) {
  var process = function process(blobs) {
    if (options.processor) {
      return options.processor(blobs).then(function () {
        return blobs;
      });
    } else {
      return Promise.resolve(blobs);
    }
  };
  return blobs$.pipe(bufferCount(options.batchSize)).pipe(concatMap(process));
};
export { processinator };