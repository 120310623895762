import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useLayoutEffect, useState } from 'react';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { AnnotationTypes } from '@atlaskit/adf-schema';
import { ACTION, ACTION_SUBJECT, EVENT_TYPE, ACTION_SUBJECT_ID } from '@atlaskit/editor-common/analytics';
import { FabricChannel } from '@atlaskit/analytics-listeners';
export var useAnnotationStateByTypeEvent = function useAnnotationStateByTypeEvent(_ref) {
  var type = _ref.type,
    updateSubscriber = _ref.updateSubscriber;
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    states = _useState2[0],
    setStates = _useState2[1];
  useLayoutEffect(function () {
    if (!updateSubscriber) {
      return;
    }
    var cb = function cb(payload) {
      if (!payload) {
        return;
      }
      var nextStates = Object.values(payload).reduce(function (acc, curr) {
        if (curr.id && curr.annotationType === type) {
          // Check for empty state to prevent additional renders
          var isEmpty = curr.state === null || curr.state === undefined;
          return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, curr.id, !isEmpty ? curr.state : states[curr.id]));
        }
        return acc;
      }, {});
      setStates(_objectSpread(_objectSpread({}, states), nextStates));
    };
    updateSubscriber.on(AnnotationUpdateEvent.SET_ANNOTATION_STATE, cb);
    return function () {
      updateSubscriber.off(AnnotationUpdateEvent.SET_ANNOTATION_STATE, cb);
    };
  }, [states, type, updateSubscriber]);
  return states;
};
export var useHasFocusEvent = function useHasFocusEvent(_ref2) {
  var id = _ref2.id,
    updateSubscriber = _ref2.updateSubscriber;
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    hasFocus = _useState4[0],
    setHasFocus = _useState4[1];
  useLayoutEffect(function () {
    if (!updateSubscriber) {
      return;
    }
    var cb = function cb(payload) {
      setHasFocus(payload && payload.annotationId === id);
    };
    var removeFocus = function removeFocus() {
      setHasFocus(false);
    };
    updateSubscriber.on(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, cb);
    updateSubscriber.on(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS, removeFocus);
    return function () {
      updateSubscriber.off(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, cb);
      updateSubscriber.off(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS, removeFocus);
    };
  }, [id, updateSubscriber]);
  return hasFocus;
};
export var useAnnotationClickEvent = function useAnnotationClickEvent(props) {
  var _useState5 = useState(null),
    _useState6 = _slicedToArray(_useState5, 2),
    annotationClickEvent = _useState6[0],
    setAnnotationClickEvent = _useState6[1];
  var updateSubscriber = props.updateSubscriber,
    createAnalyticsEvent = props.createAnalyticsEvent;
  useLayoutEffect(function () {
    if (!updateSubscriber) {
      return;
    }
    var clickCb = function clickCb(_ref3) {
      var annotationIds = _ref3.annotationIds,
        eventTarget = _ref3.eventTarget;
      var annotationsByType = annotationIds.filter(function (id) {
        return !!id;
      }).map(function (id) {
        return {
          id: id,
          type: AnnotationTypes.INLINE_COMMENT
        };
      });
      if (createAnalyticsEvent) {
        createAnalyticsEvent({
          action: ACTION.VIEWED,
          actionSubject: ACTION_SUBJECT.ANNOTATION,
          actionSubjectId: ACTION_SUBJECT_ID.INLINE_COMMENT,
          eventType: EVENT_TYPE.TRACK,
          attributes: {
            overlap: annotationsByType.length || 0
          }
        }).fire(FabricChannel.editor);
      }
      setAnnotationClickEvent({
        annotations: annotationsByType,
        clickElementTarget: eventTarget
      });
    };
    var deselectCb = function deselectCb() {
      setAnnotationClickEvent({
        annotations: [],
        clickElementTarget: undefined
      });
    };
    updateSubscriber.on(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, clickCb);
    updateSubscriber.on(AnnotationUpdateEvent.DESELECT_ANNOTATIONS, deselectCb);
    return function () {
      updateSubscriber.off(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, clickCb);
      updateSubscriber.off(AnnotationUpdateEvent.DESELECT_ANNOTATIONS, deselectCb);
    };
  }, [updateSubscriber, createAnalyticsEvent]);
  return annotationClickEvent;
};