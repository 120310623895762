import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from '@emotion/react';

// We use !important to ensure next sibling gets the margin reset no matter what

export var clearNextSiblingMarginTopStyle = css({
  '& + *': {
    // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
    marginTop: '0 !important'
  }
});
var textElements = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
var nextSiblingBlockMarkContentSelectors = textElements.map(function (elem) {
  return "+ .fabric-editor-block-mark > ".concat(elem);
}).join(',');
export var clearNextSiblingBlockMarkMarginTopStyle = css(_defineProperty({}, "".concat(nextSiblingBlockMarkContentSelectors), {
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
  marginTop: '0 !important'
}));