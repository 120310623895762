/**
 * @name heading_node
 */

/**
 * @name heading_with_no_marks_node
 */

// Check `paragraph` node for why we are doing things like this
/**
 * @name heading_with_alignment_node
 */

/**
 * @name heading_with_indentation_node
 */

export var heading = {
  attrs: {
    level: {
      default: 1
    }
  },
  content: "inline*",
  group: 'block',
  defining: true,
  selectable: false,
  parseDOM: [{
    tag: 'h1',
    attrs: {
      level: 1
    }
  }, {
    tag: 'h2',
    attrs: {
      level: 2
    }
  }, {
    tag: 'h3',
    attrs: {
      level: 3
    }
  }, {
    tag: 'h4',
    attrs: {
      level: 4
    }
  }, {
    tag: 'h5',
    attrs: {
      level: 5
    }
  }, {
    tag: 'h6',
    attrs: {
      level: 6
    }
  }],
  toDOM: function toDOM(node) {
    return ['h' + node.attrs['level'], 0];
  }
};