export var convertBase64ToBlob = function convertBase64ToBlob(base64) {
  var sliceSize = 512;
  var base64Data = base64.split(',')[1];
  var byteCharacters = atob(base64Data);
  var byteArrays = [];
  var _loop = function _loop() {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = slice.split('').map(function (_, i) {
      return slice.charCodeAt(i);
    });
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  };
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    _loop();
  }
  return new Blob(byteArrays, {
    type: 'image/jpeg'
  });
};