import React from "react";
import Button, { ButtonGroup } from "@atlaskit/button";
import DynamicTableStateless from "@atlaskit/dynamic-table";
import { ReactRenderer } from "@atlaskit/renderer";
import { RowType } from "@atlaskit/dynamic-table/dist/types/types";
import Heading from "@atlaskit/heading";
import { getSeverity, getDisplayDate, CompareSemanticVersions } from "./cvetable";
import { CveDetails, DetailsDataObject, ProductData } from "./domain/cvedata";
import Drawer from "@atlaskit/drawer";
import { TableRowsPerPage } from "./utils/consts";

export const DetailDrawer = (selectedCVEDetails: DetailsDataObject, open: boolean, toggleDrawer: any) => {
    return (
        <>
            <Drawer
                width={"full"}
                onClose={() => {
                    toggleDrawer(false);
                }}
                isOpen={open}
            >
                {DetailedState(selectedCVEDetails)}
            </Drawer>
        </>
    );
};

const DetailedState = (selectedCVEDetails: DetailsDataObject) => {
    const buttonStyle = { lineHeight: "32px" };
    let rows = !!selectedCVEDetails.affected_products
        ? getAffectedProductRows(selectedCVEDetails.affected_products)
        : ([] as RowType[]);
    const affectedProductHead = {
        cells: [
            {
                key: "product",
                content: "Product",
            },
            {
                key: "affectedVersion",
                content: "Introduced In Version(s)",
            },
            {
                key: "fixedVersion",
                content: "Fixed In Version",
            },
        ],
    };

    return (
        <>
            <div className="Details">
                <Heading level="h600">{selectedCVEDetails.cve_id}</Heading>

                <br />
                <Heading level="h300">
                    CVSS Severity: {getSeverity(selectedCVEDetails.cvss_severity, selectedCVEDetails.cve_severity)}
                </Heading>
                <Heading level="h300">Vector: {selectedCVEDetails.cve_vector}</Heading>
                <br />
                <ButtonGroup>
                    <a href={selectedCVEDetails.atl_tracking_url} target="_blank" rel="noreferrer" data-cy="jac-link">
                        <Button appearance="primary" style={buttonStyle}>
                            Open Jira
                        </Button>
                    </a>

                    <a
                        href={"https://nvd.nist.gov/vuln/detail/" + selectedCVEDetails.cve_id}
                        target="_blank"
                        rel="noreferrer"
                        data-cy="nvd-link"
                    >
                        <Button style={buttonStyle} appearance="primary">
                            Open NVD
                        </Button>
                    </a>

                    {selectedCVEDetails.advisory_url && (
                        <a href={selectedCVEDetails.advisory_url} target="_blank" rel="noreferrer">
                            <Button appearance="primary" style={buttonStyle}>
                                Open Advisory
                            </Button>
                        </a>
                    )}
                </ButtonGroup>
                <br />

                {DetailRender(selectedCVEDetails.cve_details)}
                <br />

                <br />

                <Heading level="h600">Affected Products</Heading>
                <DynamicTableStateless
                    head={affectedProductHead}
                    rows={rows}
                    rowsPerPage={TableRowsPerPage}
                    defaultPage={1}
                    isFixedSize
                    defaultSortKey="product"
                    defaultSortOrder="ASC"
                />
            </div>
        </>
    );
};

const DetailRender = (details: CveDetails[]) => {
    return (
        <>
            {details?.map((d, _) => (
                <div key={"details"}>
                    <br></br>
                    <Heading level="h700">{d.summary}</Heading>

                    <Heading level="h300">Atlassian Publish Date: {getDisplayDate(d?.publish_date)}</Heading>
                    <ReactRenderer document={d.details} />
                </div>
            ))}
        </>
    );
};

// Uses the same keys as the head object
function getAffectedProductRows(affectedProducts: { [product_name: string]: ProductData }) {
    return Object.entries(affectedProducts).map(product => {
        let affectedVersions: string[] = [],
            fixedVersions: string[] = [];
        Object.entries(product[1]).forEach(version =>
            version[1] === "FIXED" ? fixedVersions.push(version[0]) : affectedVersions.push(version[0])
        );

        affectedVersions.sort((a, b) => CompareSemanticVersions(a, b));
        fixedVersions.sort((a, b) => CompareSemanticVersions(a, b));

        return {
            key: `row-${product[0]}`,
            cells: [
                {
                    key: "products",
                    content: product[0],
                },
                {
                    key: "affectedVersion",
                    content: affectedVersions.join(", "),
                },
                {
                    key: "fixedVersion",
                    content: fixedVersions.join(", "),
                },
            ],
        };
    });
}
