import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _css;
import { css } from '@emotion/react';
var richMediaClassName = 'rich-media-item';
var wrappedMediaBreakoutPoint = 410;
var mediaSingleSharedStyle = css((_css = {}, _defineProperty(_css, "li .".concat(richMediaClassName), {
  margin: 0
}), _defineProperty(_css, '&.ua-chrome li > .mediaSingleView-content-wrap::before', {
  content: "''",
  display: 'block',
  height: 0
}), _defineProperty(_css, '&.ua-firefox', {
  '.mediaSingleView-content-wrap': {
    userSelect: 'none'
  },
  '.captionView-content-wrap': {
    userSelect: 'text'
  }
}), _defineProperty(_css, ".mediaSingleView-content-wrap[layout='center']", {
  clear: 'both'
}), _defineProperty(_css, "table .".concat(richMediaClassName), {
  marginTop: "var(--ds-space-150, 12px)",
  marginBottom: "var(--ds-space-150, 12px)",
  clear: 'both',
  '&.image-wrap-left, &.image-wrap-right': {
    clear: 'none',
    '&:first-child': {
      marginTop: "var(--ds-space-150, 12px)"
    }
  }
}), _defineProperty(_css, ".".concat(richMediaClassName, ".image-wrap-right + .").concat(richMediaClassName, ".image-wrap-left"), {
  clear: 'both'
}), _defineProperty(_css, ".".concat(richMediaClassName, ".image-wrap-left + .").concat(richMediaClassName, ".image-wrap-right, .").concat(richMediaClassName, ".image-wrap-right + .").concat(richMediaClassName, ".image-wrap-left, .").concat(richMediaClassName, ".image-wrap-left + .").concat(richMediaClassName, ".image-wrap-left, .").concat(richMediaClassName, ".image-wrap-right + .").concat(richMediaClassName, ".image-wrap-right"), {
  marginRight: 0,
  marginLeft: 0
}), _defineProperty(_css, "@media all and (max-width: ".concat(wrappedMediaBreakoutPoint, "px)"), {
  "div.mediaSingleView-content-wrap[layout='wrap-left'], div.mediaSingleView-content-wrap[data-layout='wrap-left'], div.mediaSingleView-content-wrap[layout='wrap-right'], div.mediaSingleView-content-wrap[data-layout='wrap-right']": {
    float: 'none',
    overflow: 'auto',
    margin: "var(--ds-space-150, 12px)".concat(" 0")
  }
}), _css));
export { mediaSingleSharedStyle, richMediaClassName };