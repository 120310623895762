/* eslint-disable @repo/internal/react/require-jsdoc */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';

// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';
var fixedHeightStyles = css({
  height: "".concat(gridSize() * 18, "px")
});
export var EmptyViewWithFixedHeight = function EmptyViewWithFixedHeight(_ref) {
  var children = _ref.children,
    testId = _ref.testId;
  return jsx("div", {
    css: fixedHeightStyles,
    "data-testid": testId && "".concat(testId, "--empty-view-with-fixed-height")
  }, children);
};
var emptyViewContainerStyles = css({
  width: '50%',
  margin: 'auto',
  // eslint-disable-next-line @atlaskit/design-system/use-tokens-space
  padding: '10px',
  textAlign: 'center'
});
export var EmptyViewContainer = function EmptyViewContainer(props) {
  var children = props.children,
    testId = props.testId;
  return jsx("div", {
    css: emptyViewContainerStyles,
    "data-testid": testId && "".concat(testId, "--empty-view-container")
  }, children);
};