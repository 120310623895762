import _extends from "@babel/runtime/helpers/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/** @jsx jsx */
import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { B400, B300, B500 } from '@atlaskit/theme/colors';
import { getEventHandler } from '../../utils';
import { PLATFORM, MODE } from '../../analytics/events';
import { ACTION, ACTION_SUBJECT, EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import LinkUrl from '@atlaskit/smart-card/link-url';
import { AnalyticsContext } from '@atlaskit/analytics-next';
var anchorStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n\n  &:hover {\n    color: ", ";\n    text-decoration: underline;\n  }\n\n  &:active {\n    color: ", ";\n  }\n"])), "var(--ds-link, ".concat(B400, ")"), "var(--ds-link, ".concat(B300, ")"), "var(--ds-link-pressed, ".concat(B500, ")"));
export default function Link(props) {
  var href = props.href,
    target = props.target,
    eventHandlers = props.eventHandlers,
    fireAnalyticsEvent = props.fireAnalyticsEvent,
    isMediaLink = props.isMediaLink,
    dataAttributes = props.dataAttributes;
  var anchorProps = {
    href: href,
    target: target,
    title: href
  };
  if (target === '_blank') {
    anchorProps.rel = 'noreferrer noopener';
  }
  var handler = getEventHandler(eventHandlers, 'link');
  if (isMediaLink) {
    return jsx(Fragment, null, props.children);
  }
  var analyticsData = {
    attributes: {
      location: 'renderer'
    },
    // Below is added for the future implementation of Linking Platform namespaced analytic context
    location: 'renderer'
  };
  return jsx(AnalyticsContext, {
    data: analyticsData
  }, jsx(LinkUrl, _extends({
    css: anchorStyles,
    onClick: function onClick(e) {
      if (fireAnalyticsEvent) {
        fireAnalyticsEvent({
          action: ACTION.VISITED,
          actionSubject: ACTION_SUBJECT.LINK,
          eventType: EVENT_TYPE.TRACK,
          attributes: {
            platform: PLATFORM.WEB,
            mode: MODE.RENDERER
          }
        });
      }
      if (handler) {
        handler(e, href);
      }
    }
  }, anchorProps, dataAttributes), props.children));
}