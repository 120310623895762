import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { WidthConsumer } from '@atlaskit/editor-common/ui';
import { calcBreakoutWidth } from '@atlaskit/editor-common/utils';
import { blockNodesVerticalMargin } from '@atlaskit/editor-shared-styles';
export var wrapperStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: ", " 0;\n  margin-left: 50%;\n  transform: translateX(-50%);\n"])), blockNodesVerticalMargin);
export default function Breakout(props) {
  return jsx(WidthConsumer, null, function (_ref) {
    var width = _ref.width;
    return jsx("div", {
      css: wrapperStyles,
      "data-mode": props.mode,
      style: {
        width: calcBreakoutWidth(props.mode, width)
      },
      className: "fabric-editor-breakout-mark fabric-editor-block-mark"
    }, props.children);
  });
}