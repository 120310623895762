export function getUnsupportedContent(message, prefix, node, intl) {
  var defaultLocale = 'en';
  var canTranslateToLocale = true;
  var locale = intl ? intl.locale : defaultLocale;
  var finalMessage = message.defaultMessage;
  if (node && locale.startsWith(defaultLocale)) {
    var originalValue = node.attrs.originalValue;
    if (originalValue.text || originalValue.attrs && originalValue.attrs.text) {
      finalMessage = originalValue.text ? originalValue.text : originalValue.attrs.text;
      canTranslateToLocale = false;
    } else if (originalValue.type) {
      finalMessage = "".concat(prefix, " ").concat(originalValue.type);
      canTranslateToLocale = false;
    }
  }
  if (intl && canTranslateToLocale) {
    return intl.formatMessage(message);
  }
  return finalMessage;
}