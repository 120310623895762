import _extends from "@babel/runtime/helpers/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { alignmentPositionMap } from '@atlaskit/adf-schema';
var MarkWrapper = function MarkWrapper(props) {
  var styles = props['data-align'] ? css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        text-align: ", ";\n      "])), alignmentPositionMap[props['data-align']]) : '';
  return jsx("div", _extends({
    css: styles
  }, props), props.children);
};
export default function Alignment(props) {
  return jsx(MarkWrapper, {
    className: "fabric-editor-block-mark fabric-editor-alignment",
    "data-align": props.align
  }, props.children);
}