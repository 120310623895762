export var getImageDimensionsFromBlob = function getImageDimensionsFromBlob(url) {
  return new Promise(function (resolve, reject) {
    var img = new Image();
    img.src = url;
    img.onload = function () {
      return resolve({
        width: img.width,
        height: img.height
      });
    };
    img.onerror = reject;
  });
};