import _extends from "@babel/runtime/helpers/extends";
/** @jsx jsx */
import React from 'react';
import Button from '@atlaskit/button/standard-button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { FormattedMessage, IntlProvider, injectIntl } from 'react-intl-next';
import { breakWordsCss } from './styled';
import { jsx } from '@emotion/react';
import { messages } from '../../../messages';
var WarningModal = function WarningModal(props) {
  var isOpen = props.isOpen,
    unsafeLinkText = props.unsafeLinkText,
    url = props.url,
    onClose = props.onClose,
    onContinue = props.onContinue,
    intl = props.intl;
  var content = jsx(ModalTransition, null, isOpen && jsx(Modal, {
    onClose: onClose,
    testId: "link-with-safety-warning"
  }, jsx(ModalHeader, null, jsx(ModalTitle, {
    appearance: "warning"
  }, jsx(FormattedMessage, messages.check_this_link))), jsx(ModalBody, null, jsx("div", {
    css: breakWordsCss
  }, url && unsafeLinkText && jsx(FormattedMessage, _extends({}, messages.link_safety_warning_message, {
    values: {
      unsafeLinkText: unsafeLinkText,
      a: function a() {
        return jsx("a", {
          href: url,
          target: "_blank",
          rel: "noopener noreferrer"
        }, url);
      }
    }
  })))), jsx(ModalFooter, null, jsx(Button, {
    appearance: "subtle",
    onClick: onClose
  }, jsx(FormattedMessage, messages.go_back)), jsx(Button, {
    appearance: "warning",
    onClick: onContinue,
    autoFocus: true
  }, jsx(FormattedMessage, messages.continue)))));
  return intl ? content : jsx(IntlProvider, {
    locale: "en"
  }, content);
};
export default injectIntl(WarningModal, {
  enforceContext: false
});