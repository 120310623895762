import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Converts the output of Intl.DateTimeFormat.formatToParts of type
 * `[ { type: 'day', value: '17' }, { type: 'month', value: '3' }, ... ]`
 * into an indexed object of type
 * `{ day: '17', month: '3', ... }`
 */
export var toFormattedParts = function toFormattedParts(rawParts) {
  return rawParts.reduce(function (result, datePart) {
    return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, datePart.type, datePart.value));
  }, {});
};