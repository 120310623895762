import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import { css } from '@emotion/react';
import { N20 } from '@atlaskit/theme/colors';
export var overflowShadow = function overflowShadow(_ref) {
  var leftCoverWidth = _ref.leftCoverWidth,
    rightCoverWidth = _ref.rightCoverWidth;
  var width = "var(--ds-space-100, 8px)";
  var leftCoverWidthResolved = leftCoverWidth || width;
  var rightCoverWidthResolved = rightCoverWidth || width;
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n/* shadow cover left */\n  linear-gradient(\n    to right,\n    ", " ", ",\n    transparent ", "\n  ),\n/* shadow cover background left */\n  linear-gradient(\n    to right,\n    ", " ", ",\n    transparent ", "\n  ),\n/* shadow cover right */\n  linear-gradient(\n    to left,\n    ", " ", ",\n    transparent ", "\n  ),\n/* shadow cover background right */\n  linear-gradient(\n    to left,\n    ", " ", ",\n    transparent ", "\n  ),\n/* overflow shadow right spread */\n  linear-gradient(\n    to left,\n    ", " 0,\n    ", "  ", "\n  ),\n  /* overflow shadow right perimeter */\n  linear-gradient(\n    to left,\n    ", " 0,\n    ", "  ", "\n  ),\n  /* overflow shadow left spread */\n  linear-gradient(\n    to right,\n    ", " 0,\n    ", "  ", "\n  ),\n  /* overflow shadow left perimeter */\n  linear-gradient(\n    to right,\n    ", " 0,\n    ", "  ", "\n  )\n"])), "var(--ds-background-neutral, ".concat(N20, ")"), leftCoverWidthResolved, leftCoverWidthResolved, "var(--ds-surface-raised, transparent)", leftCoverWidthResolved, leftCoverWidthResolved, "var(--ds-background-neutral, ".concat(N20, ")"), rightCoverWidthResolved, rightCoverWidthResolved, "var(--ds-surface-raised, transparent)", rightCoverWidthResolved, rightCoverWidthResolved, "var(--ds-shadow-overflow-spread, rgba(9, 30, 66, 0.13))", "var(--ds-UNSAFE-transparent, rgba(99, 114, 130, 0))", width, "var(--ds-shadow-overflow-perimeter, transparent)", "var(--ds-UNSAFE-transparent, transparent)", width, "var(--ds-shadow-overflow-spread, rgba(9, 30, 66, 0.13))", "var(--ds-UNSAFE-transparent, rgba(99, 114, 130, 0))", width, "var(--ds-shadow-overflow-perimeter, transparent)", "var(--ds-UNSAFE-transparent, transparent)", width);
};