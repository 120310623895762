/** @jsx jsx */
import React, { useCallback, useRef } from 'react';
import { css, jsx } from '@emotion/react';
import { injectIntl } from 'react-intl-next';
import { relativeFontSizeToBase16 } from '@atlaskit/editor-shared-styles';
import QuestionsIcon from '@atlaskit/icon/glyph/question-circle';
import { N30, N50 } from '@atlaskit/theme/colors';
import { borderRadius, fontSize } from '@atlaskit/theme/constants';
import Tooltip from '@atlaskit/tooltip';
import { ACTION_SUBJECT_ID } from '../../analytics';
import { unsupportedContentMessages } from '../../messages/unsupportedContent';
import { trackUnsupportedContentTooltipDisplayedFor } from '../../utils/track-unsupported-content';
import { getUnsupportedContent } from '../unsupported-content-helper';
var inlineNodeStyle = css({
  alignItems: 'center',
  background: "var(--ds-background-disabled, ".concat(N30, ")"),
  border: "1px dashed ".concat("var(--ds-border-disabled, ".concat(N50, ")")),
  borderRadius: "".concat(borderRadius(), "px"),
  boxSizing: 'border-box',
  cursor: 'default',
  display: 'inline-flex',
  fontSize: relativeFontSizeToBase16(fontSize()),
  margin: "0 ".concat("var(--ds-space-025, 2px)"),
  minHeight: '24px',
  padding: "0 ".concat("var(--ds-space-100, 8px)"),
  verticalAlign: 'middle',
  whiteSpace: 'nowrap'
});
var UnsupportedInlineNode = function UnsupportedInlineNode(_ref) {
  var node = _ref.node,
    intl = _ref.intl,
    dispatchAnalyticsEvent = _ref.dispatchAnalyticsEvent;
  var message = getUnsupportedContent(unsupportedContentMessages.unsupportedInlineContent, 'Unsupported', node, intl);
  var tooltipContent = intl.formatMessage(unsupportedContentMessages.unsupportedContentTooltip);
  var _useRef = useRef({
      padding: '4px'
    }),
    style = _useRef.current;
  var originalNodeType = node === null || node === void 0 ? void 0 : node.attrs.originalValue.type;
  var tooltipOnShowHandler = useCallback(function () {
    return dispatchAnalyticsEvent && trackUnsupportedContentTooltipDisplayedFor(dispatchAnalyticsEvent, ACTION_SUBJECT_ID.ON_UNSUPPORTED_INLINE, originalNodeType);
  }, [dispatchAnalyticsEvent, originalNodeType]);
  return jsx("span", {
    css: inlineNodeStyle
  }, message, jsx(Tooltip, {
    content: tooltipContent,
    hideTooltipOnClick: false,
    position: "bottom",
    onShow: tooltipOnShowHandler,
    strategy: "absolute"
  }, jsx("span", {
    style: style
  }, jsx(QuestionsIcon, {
    label: "?",
    size: "small"
  }))));
};
export default injectIntl(UnsupportedInlineNode);