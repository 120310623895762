import { findParentNodeOfTypeClosestToPos } from '@atlaskit/editor-prosemirror/utils';
import { akEditorDefaultLayoutWidth, akEditorFullWidthLayoutWidth, akEditorWideLayoutWidth, akLayoutGutterOffset, gridMediumMaxWidth } from '@atlaskit/editor-shared-styles';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';
import { BODIED_EXT_PADDING } from '../styles/shared/extension';
import { LAYOUT_COLUMN_PADDING, LAYOUT_SECTION_MARGIN } from '../styles/shared/layout';
import { absoluteBreakoutWidth } from '../utils/breakout';
export var layoutToWidth = {
  default: akEditorDefaultLayoutWidth,
  wide: akEditorWideLayoutWidth,
  'full-width': akEditorFullWidthLayoutWidth
};

/**
 * Calculates width of parent node of a nested node (inside layouts, extension)
 * If current node selection is not nested will return undefined
 */
export var getParentNodeWidth = function getParentNodeWidth(pos, state, containerWidth, isFullWidthModeEnabled) {
  if (!pos) {
    return;
  }
  var node = getNestedParentNode(pos, state);
  if (!node) {
    return;
  }
  var layout = node.attrs.layout || 'default';
  var schema = state.schema;
  var breakoutMark = schema.marks.breakout && schema.marks.breakout.isInSet(node.marks);
  if (breakoutMark && breakoutMark.attrs.mode) {
    layout = breakoutMark.attrs.mode;
  }
  var parentWidth = calcBreakoutNodeWidth(layout, containerWidth, isFullWidthModeEnabled);

  // TODO: ED-15663
  // Please, do not copy or use this kind of code below
  // @ts-ignore
  var contextPanelPluginKey = {
    key: 'contextPanelPluginKey$',
    getState: function getState(state) {
      return state['contextPanelPluginKey$'];
    }
  };
  if (node.type === schema.nodes.layoutSection) {
    var _contextPanelPluginKe, _contextPanelPluginKe2;
    parentWidth += akLayoutGutterOffset * 2; // extra width that gets added to layout

    // Calculate width of parent layout column when
    // Parallel layout with viewport greater than 1024px
    // OR side panel of an extension is open and change the node width to smaller than containerWidth
    if (containerWidth.width > gridMediumMaxWidth || ((_contextPanelPluginKe = contextPanelPluginKey.getState(state)) === null || _contextPanelPluginKe === void 0 ? void 0 : _contextPanelPluginKe.contents.length) > 0 && ((_contextPanelPluginKe2 = contextPanelPluginKey.getState(state)) === null || _contextPanelPluginKe2 === void 0 ? void 0 : _contextPanelPluginKe2.contents[0]) !== undefined) {
      parentWidth -= (LAYOUT_SECTION_MARGIN + 2) * (node.childCount - 1); // margin between sections
      var $pos = state.doc.resolve(pos);
      var column = findParentNodeOfTypeClosestToPos($pos, [state.schema.nodes.layoutColumn]);
      if (column && column.node && !isNaN(column.node.attrs.width)) {
        // get exact width of parent layout column using node attrs
        parentWidth = Math.round(parentWidth * column.node.attrs.width * 0.01);
      }
    }
  }

  // account for the padding of the parent node
  switch (node.type) {
    case schema.nodes.layoutSection:
      parentWidth -= LAYOUT_COLUMN_PADDING * 2;
      break;
    case schema.nodes.bodiedExtension:
      parentWidth -= BODIED_EXT_PADDING * 2;
      break;
    case schema.nodes.extensionFrame:
      parentWidth -= BODIED_EXT_PADDING * 2;
      break;

    // TODO: Migrate away from gridSize
    // Recommendation: Replace gridSize with 8
    case schema.nodes.expand:
      // padding
      parentWidth -= gridSize() * 2;
      // gutter offset
      parentWidth += gridSize() * 1.5 * 2;
      // padding right
      parentWidth -= gridSize();
      // padding left
      parentWidth -= gridSize() * 4 - gridSize() / 2;
      break;
  }
  parentWidth -= 2; // border

  return parentWidth;
};
var getNestedParentNode = function getNestedParentNode(tablePos, state) {
  if (tablePos === undefined) {
    return null;
  }
  var $pos = state.doc.resolve(tablePos);
  var parent = findParentNodeOfTypeClosestToPos($pos, [state.schema.nodes.bodiedExtension, state.schema.nodes.extensionFrame, state.schema.nodes.layoutSection, state.schema.nodes.expand]);
  return parent ? parent.node : null;
};
var calcBreakoutNodeWidth = function calcBreakoutNodeWidth(layout, containerWidth, isFullWidthModeEnabled) {
  return isFullWidthModeEnabled ? Math.min(containerWidth.lineLength, akEditorFullWidthLayoutWidth) : absoluteBreakoutWidth(layout, containerWidth.width);
};
export var getTableContainerWidth = function getTableContainerWidth(node) {
  if (node !== null && node !== void 0 && node.attrs.width) {
    return node.attrs.width;
  }
  return layoutToWidth[node === null || node === void 0 ? void 0 : node.attrs.layout] || akEditorDefaultLayoutWidth;
};
export var getTableWidthWithNumberColumn = function getTableWidthWithNumberColumn(node, offset) {
  var isNumberColumnEnabled = node.attrs.isNumberColumnEnabled;
  if (isNumberColumnEnabled && offset > 0) {
    return getTableContainerWidth(node) - offset;
  }
  return getTableContainerWidth(node);
};