/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { B50, N30A } from '@atlaskit/theme/colors';
var iconButtonStyles = css({
  padding: "var(--ds-space-100, 8px)",
  backgroundColor: 'inherit',
  border: 'none',
  borderRadius: "var(--ds-space-1000, 80px)",
  color: 'inherit',
  cursor: 'pointer',
  lineHeight: "var(--ds-font-lineHeight-100, 1)",
  '&:hover': {
    backgroundColor: "var(--ds-background-neutral-subtle-hovered, ".concat(N30A, ")")
  },
  '&:active': {
    backgroundColor: "var(--ds-background-neutral-subtle-pressed, ".concat(B50, ")")
  }
});
var IconButton = function IconButton(_ref) {
  var children = _ref.children,
    onClick = _ref.onClick,
    testId = _ref.testId;
  return jsx("button", {
    type: "button",
    css: iconButtonStyles,
    onClick: onClick,
    "data-testid": testId
  }, children);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default IconButton;