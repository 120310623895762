import _defineProperty from "@babel/runtime/helpers/defineProperty";
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { easeInOut } from '@atlaskit/motion/curves';
import { mediumDurationMs } from '@atlaskit/motion/durations';
import { media } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme/constants';
import { gutter, verticalOffset } from '../constants';
var maxWidthDimensions = "calc(100vw - ".concat(gutter * 2, "px)");
var maxHeightDimensions = "calc(100vh - ".concat(gutter * 2 - 1, "px)");

// Flex and min-content are set to constrain the height of the body and support multi-column scrolling experiences
var positionerStyles = css({
  display: 'flex',
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: layers.modal(),
  flexDirection: 'column',
  insetBlockStart: 0,
  insetInlineStart: 0
});
var viewportScrollStyles = css(_defineProperty({
  height: 'auto',
  position: 'relative'
}, media.above.xs, {
  margin: "".concat(gutter, "px auto"),
  pointerEvents: 'none'
}));
var bodyScrollStyles = css(_defineProperty({}, media.above.xs, {
  maxWidth: maxWidthDimensions,
  maxHeight: maxHeightDimensions,
  position: 'absolute',
  insetBlockStart: "".concat(gutter, "px"),
  insetInlineEnd: 0,
  insetInlineStart: 0,
  marginInlineEnd: 'auto',
  marginInlineStart: 'auto',
  pointerEvents: 'none'
}));
var stackTransitionStyles = css({
  transitionDuration: "".concat(mediumDurationMs, "ms"),
  transitionProperty: 'transform',
  transitionTimingFunction: easeInOut,
  /**
   * Duplicated from @atlaskit/motion/accessibility
   * because @repo/internal/styles/consistent-style-ordering
   * doesn't work well with object spreading.
   */
  '@media (prefers-reduced-motion: reduce)': {
    animation: 'none',
    transition: 'none'
  }
});
var stackTransformStyles = css({
  transform: 'translateY(var(--modal-dialog-translate-y))'
});
var stackIdleStyles = css({
  transform: 'none'
});
var Positioner = function Positioner(props) {
  var children = props.children,
    stackIndex = props.stackIndex,
    shouldScrollInViewport = props.shouldScrollInViewport,
    testId = props.testId;
  return jsx("div", {
    style: {
      '--modal-dialog-translate-y': "".concat(stackIndex * (verticalOffset / 2), "px")
    },
    css: [positionerStyles, stackTransitionStyles, /* We only want to apply transform on modals shifting to the back of the stack. */
    stackIndex > 0 ? stackTransformStyles : stackIdleStyles, shouldScrollInViewport ? viewportScrollStyles : bodyScrollStyles],
    "data-testid": testId && "".concat(testId, "--positioner")
  }, children);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Positioner;