import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _editorTextPalette;
// This import will be stripped on build

/**
 * This takes an adf hex color and returns a matching text palette color.
 *
 * By providing a design token, this enables ADF content to be rendered in new themes such as dark mode.
 *
 * Example usage
 * ```tsx
 * const cssValue = hexToTextPaletteColor('#0747A6');
 * //     ^? const cssValue: string
 * <span style={{textColor: cssValue}} />
 * ```
 * The names of tokens can change over time, and the values of tokens will differ between themes.
 * The exact output of this function is an implementation detail and should only be used when rendering
 * content to the user, on a client with a matching major version of `@atlaskit/tokens`.
 * - **DO NOT**: store the output of these functions in any user-generated content or back-end.
 * - **DO**: store the ADF hex color, and use these utilities at render time to display the themed version of the color
 */

export function hexToEditorTextPaletteColor(hexColor) {
  // Ts ignore used to allow use of conditional return type
  // (preferencing better type on consumption over safety in implementation)
  // @ts-ignore
  return editorTextPalette[hexColor.toUpperCase()];
}
// Colors taken from
// https://hello.atlassian.net/wiki/spaces/DST/pages/1790979421/DSTRFC-002+-+Shifting+Editor+s+color+palette+to+design+tokens
// values are asserted to improve generated type declarations
export var editorTextPalette = (_editorTextPalette = {}, _defineProperty(_editorTextPalette, '#B3D4FF', "var(--ds-background-accent-blue-subtler, #B3D4FF)"), _defineProperty(_editorTextPalette, '#4C9AFF', "var(--ds-icon-accent-blue, #4C9AFF)"), _defineProperty(_editorTextPalette, '#0747A6', "var(--ds-text-accent-blue, #0747A6)"), _defineProperty(_editorTextPalette, '#B3F5FF', "var(--ds-background-accent-teal-subtler, #B3F5FF)"), _defineProperty(_editorTextPalette, '#00B8D9', "var(--ds-icon-accent-teal, #00B8D9)"), _defineProperty(_editorTextPalette, '#008DA6', "var(--ds-text-accent-teal, #008DA6)"), _defineProperty(_editorTextPalette, '#ABF5D1', "var(--ds-background-accent-green-subtler, #ABF5D1)"), _defineProperty(_editorTextPalette, '#36B37E', "var(--ds-icon-accent-green, #36B37E)"), _defineProperty(_editorTextPalette, '#006644', "var(--ds-text-accent-green, #006644)"), _defineProperty(_editorTextPalette, '#FFF0B3', "var(--ds-background-accent-yellow-subtler, #FFF0B3)"), _defineProperty(_editorTextPalette, '#FFC400', "var(--ds-background-accent-orange-subtle, #FFC400)"), _defineProperty(_editorTextPalette, '#FF991F', "var(--ds-icon-accent-orange, #FF991F)"), _defineProperty(_editorTextPalette, '#FFBDAD', "var(--ds-background-accent-red-subtler, #FFBDAD)"), _defineProperty(_editorTextPalette, '#FF5630', "var(--ds-icon-accent-red, #FF5630)"), _defineProperty(_editorTextPalette, '#BF2600', "var(--ds-text-accent-red, #BF2600)"), _defineProperty(_editorTextPalette, '#EAE6FF', "var(--ds-background-accent-purple-subtler, #EAE6FF)"), _defineProperty(_editorTextPalette, '#6554C0', "var(--ds-icon-accent-purple, #6554C0)"), _defineProperty(_editorTextPalette, '#403294', "var(--ds-text-accent-purple, #403294)"), _defineProperty(_editorTextPalette, '#FFFFFF', "var(--ds-text-inverse, #FFFFFF)"), _defineProperty(_editorTextPalette, '#97A0AF', "var(--ds-icon-accent-gray, #97A0AF)"), _defineProperty(_editorTextPalette, '#172B4D', "var(--ds-text, #172B4D)"), _editorTextPalette);