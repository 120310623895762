export var isLayoutNode = function isLayoutNode(node) {
  return node.type.name === 'layoutSection';
};
export var hasBreakOutMark = function hasBreakOutMark(node) {
  return node.marks.some(function (m) {
    return m.type.name === 'breakout';
  });
};
export var insideBreakoutLayout = function insideBreakoutLayout(path) {
  return path.some(function (item) {
    return isLayoutNode(item) && hasBreakOutMark(item);
  });
};
export var insideBlockNode = function insideBlockNode(path, schema) {
  var _schema$nodes = schema.nodes,
    expand = _schema$nodes.expand,
    nestedExpand = _schema$nodes.nestedExpand,
    layoutColumn = _schema$nodes.layoutColumn;
  var blockNodeNames = [expand, nestedExpand, layoutColumn].filter(function (node) {
    return Boolean(node);
  }).map(function (node) {
    return node.name;
  });
  return path && path.some(function (n) {
    return n.type && blockNodeNames.indexOf(n.type.name) > -1;
  });
};
export var insideMultiBodiedExtension = function insideMultiBodiedExtension(path, schema) {
  var multiBodiedExtension = schema.nodes.multiBodiedExtension;
  return path.some(function (n) {
    return n.type === multiBodiedExtension;
  });
};