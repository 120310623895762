import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from '@emotion/react';
import { gridMediumMaxWidth } from '@atlaskit/editor-shared-styles';
var columnLayoutSharedStyle = css({
  '[data-layout-section]': _defineProperty({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      flex: 1,
      minWidth: 0
    },
    '& > .unsupportedBlockView-content-wrap': {
      minWidth: 'initial'
    }
  }, "@media screen and (max-width: ".concat(gridMediumMaxWidth, "px)"), {
    flexDirection: 'column'
  })
});
export { columnLayoutSharedStyle };