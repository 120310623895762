import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback } from 'react';
import { useAnalyticsEvents } from './useAnalyticsEvents';
import { useTrackedRef } from './useTrackedRef';
// WARNING: This hook will only function correctly for leaf node components - as in
// no children inside the component will require analytics themselves.
// Ignoring this warning will mean the analytics context of child components will not
// include the context of this component, .e.g, lost data.
// If you are going to have child components that require analytics use withAnalytics
// or AnalyticsContext component instead.
export function usePlatformLeafSyntheticEventHandler(_ref) {
  var fn = _ref.fn,
    action = _ref.action,
    componentName = _ref.componentName,
    packageName = _ref.packageName,
    packageVersion = _ref.packageVersion,
    analyticsData = _ref.analyticsData;
  var _useAnalyticsEvents = useAnalyticsEvents(),
    createAnalyticsEvent = _useAnalyticsEvents.createAnalyticsEvent;

  // We put analyticsData and fn in a ref so that we don't need to break
  // memoization.
  // Generally these could be defined by consumers inline
  // and so we do this to avoid breaking memoization of useCallback
  var dataRef = useTrackedRef(analyticsData);
  var fnRef = useTrackedRef(fn);
  var handler = useCallback(function () {
    var analyticsEvent = createAnalyticsEvent({
      action: action,
      actionSubject: componentName,
      attributes: {
        componentName: componentName,
        packageName: packageName,
        packageVersion: packageVersion
      }
    });

    // To avoid wrapping this component in AnalyticsContext we manually
    // push the parent context's meta data into the context.
    // Note: this new 'context'
    var context = _objectSpread({
      componentName: componentName,
      packageName: packageName,
      packageVersion: packageVersion
    }, dataRef.current);
    analyticsEvent.context.push(context);

    // fire an event on the atlaskit channel
    var clone = analyticsEvent.clone();
    if (clone) {
      clone.fire('atlaskit');
    }
    fnRef.current(analyticsEvent);
  }, [
  // These are strings and won't change
  action, componentName, packageName, packageVersion,
  // This function is memoized in the context
  createAnalyticsEvent,
  // these are a stable ref because of the useTrackedRef hook
  dataRef, fnRef]);
  return handler;
}