import { createTheme } from '../utils/create-theme';

// Create default global light theme
var _createTheme = createTheme(function () {
    return {
      mode: 'light'
    };
  }),
  Provider = _createTheme.Provider,
  Consumer = _createTheme.Consumer,
  useTheme = _createTheme.useTheme;
export { useTheme as useGlobalTheme };
export default {
  Provider: Provider,
  Consumer: Consumer
};