// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';
export var CODE_FONT_SIZE = 12;
export var CODE_LINE_HEIGHT = '20px';
export var HIGHLIGHT_BORDER_WIDTH = '4px';
export var SPACING = gridSize();
export var LINE_NUMBER_GUTTER = SPACING * 2;
export var VAR_CODE_LINE_NUMBER_BG_COLOR = '--ds--code--line-number-bg-color';
export var VAR_CODE_BG_COLOR = '--ds--code--bg-color';

// selector for codeblock
export var CODE_BLOCK_SELECTOR = "data-ds--code--code-block";