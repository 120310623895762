function getSafeValue() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var numericValue = +value;
  if (numericValue < 0) {
    return 0;
  }
  return value;
}
export function formatValue(value, max) {
  var safeValue = getSafeValue(value);
  var safeMax = getSafeValue(max);
  if (safeMax && safeMax < safeValue) {
    return "".concat(safeMax, "+");
  }
  if (safeValue === Infinity) {
    return '∞';
  }
  return safeValue.toString();
}