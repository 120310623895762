// eslint-disable-next-line @repo/internal/react/consistent-types-definitions

/**
 * @deprecated Please avoid using this function as we intend to remote it in a future release. See DSP-2673 for more information.
 */
export var createExtender = function createExtender(defaults) {
  var overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Object.create(Object.prototype);
  if (!defaults) {
    throw new Error('a default set of overrides *must* be passed in as the first argument');
  }
  return function getOverrides(key) {
    var _defaults$key = defaults[key],
      defaultCssFn = _defaults$key.cssFn,
      defaultComponent = _defaults$key.component;
    if (!overrides[key]) {
      return {
        cssFn: defaultCssFn,
        component: defaultComponent
      };
    }
    var _overrides$key = overrides[key],
      customCssFn = _overrides$key.cssFn,
      customComponent = _overrides$key.component;
    var composedCssFn = function composedCssFn(state) {
      return customCssFn(defaultCssFn(state), state);
    };
    return {
      cssFn: customCssFn ? composedCssFn : defaultCssFn,
      component: customComponent || defaultComponent
    };
  };
};