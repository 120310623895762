/**
 * This component is used to hide portions of Select component.
 */
export var EmptyComponent = function EmptyComponent() {
  return null;
};

// This date was chosen to clearly show date and time formats (day > 12)
// e.g. 18/02/1993 vs. 2/18/1993 and 1:00 PM vs 13:00
export var placeholderDatetime = new Date(1993, 1, 18, 13);
export var defaultTimes = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00'];
export var defaultTimeFormat = 'h:mma';
export var defaultDateFormat = 'YYYY/MM/DD';
export function padToTwo(number) {
  return number <= 99 ? "0".concat(number).slice(-2) : "".concat(number);
}
export function formatDateTimeZoneIntoIso(date, time, zone) {
  // 12:00 => 12:00, 1:00 => 01:00
  var needsLeadingZero = /^\d:/;
  var sanitizedTime = needsLeadingZero.test(time) ? "0".concat(time) : time;
  return "".concat(date, "T").concat(sanitizedTime).concat(zone);
}