// START: temporary code https://product-fabric.atlassian.net/browse/ED-10260
export var shouldForceTracking = function shouldForceTracking() {
  try {
    var _ref = window.__buildInfo || {},
      _ref$FRONTEND_VERSION = _ref.FRONTEND_VERSION,
      FRONTEND_VERSION = _ref$FRONTEND_VERSION === void 0 ? '' : _ref$FRONTEND_VERSION;
    return window.location.hostname === 'product-fabric.atlassian.net' && FRONTEND_VERSION.includes('branch-deploy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return false;
  }
};
// END:  temporary code  https://product-fabric.atlassian.net/browse/ED-10260