/**
 * Walks a DOM tree up to the provided `stopElement`, or if falsy before.
 * @param element
 * @param stopElement
 */
export function walkUpTreeUntil(element, shouldStop) {
  var rootElement = element;
  while (rootElement && rootElement.parentElement && !shouldStop(rootElement)) {
    rootElement = rootElement.parentElement;
  }
  return rootElement;
}

/**
 * Takes all children out from wrapped el and puts them directly inside
 * the parent el, at the wrapped el's position
 */
export function unwrap(parent, wrapped) {
  var docsFragment = document.createDocumentFragment();
  Array.from(wrapped.children).forEach(function (child) {
    docsFragment.appendChild(child);
  });
  parent.replaceChild(docsFragment, wrapped);
}

/**
 * Walks up DOM removing elements if they are empty until it finds
 * one that is not
 */
export function removeNestedEmptyEls(el) {
  while (el.parentElement && el.childElementCount === 0 && el.textContent === '') {
    var parentEl = el.parentElement;
    parentEl.removeChild(el);
    el = parentEl;
  }
}

/**
 * IE11 doesn't support classList to SVGElements
 **/
export var containsClassName = function containsClassName(node, className) {
  if (!node) {
    return false;
  }
  if (node.classList && node.classList.contains) {
    return node.classList.contains(className);
  }
  if (!node.className) {
    return false;
  }
  var classNames = typeof node.className.baseVal === 'string' ? node.className.baseVal : node.className;
  return classNames.split(' ').indexOf(className) !== -1;
};
export function closest(node, s) {
  if (!node) {
    return null;
  }
  var el = node;
  if (!document.documentElement || !document.documentElement.contains(el)) {
    return null;
  }
  if (el.closest) {
    return el.closest(s);
  }
  do {
    var matchfn = el.matches ? el.matches : el.msMatchesSelector;
    if (matchfn && matchfn.call(el, s)) {
      return el;
    }
    el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);
  return null;
}

/*
 * @deprecated - Use HTMLElement.protoype.closest instead
 */
export function closestElement(node, s) {
  return closest(node, s);
}

/**
 * Util for converting a css pixel size value to a number (of pixels).
 *
 * ie.
 * ```ts
 * const pixels = parsePx('10px')
 * //    ^$ const pixels: number
 * ```
 * * ```ts
 * const pixels = parsePx('10')
 * //    ^$ const pixels: number | undefined
 * ```
 */
// At time of writting prettier would strip the extend here.
// prettier-ignore

export function parsePx(pxStr) {
  if (!pxStr.endsWith('px')) {
    return undefined;
  }
  var maybeNumber = parseInt(pxStr, 10);
  return !Number.isNaN(maybeNumber) ? maybeNumber : undefined;
}
// does typescript have function templates yet?

export function mapElem(elem, callback) {
  var array = [];
  for (var i = 0; i < elem.childElementCount; i++) {
    array.push(callback(elem.children[i], i, elem));
  }
  return array;
}
export function maphElem(elem, callback) {
  return mapElem(elem, callback);
}