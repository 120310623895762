export var copyTextToClipboard = function copyTextToClipboard(text) {
  return new Promise(function (resolve, reject) {
    var isApiSupported = !!navigator.clipboard && typeof navigator.clipboard.writeText === 'function';
    if (isApiSupported) {
      navigator.clipboard.writeText(text).then(function () {
        return resolve();
      }, function (e) {
        return reject(e);
      });
    } else {
      reject('Clipboard API is not supported');
    }
  });
};