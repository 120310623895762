import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useRef } from 'react';
import { arrowKeys } from '../constants';
import dateToString from '../utils/date-to-string';

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default function useHandleDateSelect(_ref) {
  var _ref$day = _slicedToArray(_ref.day, 1),
    dayValue = _ref$day[0],
    _ref$month = _slicedToArray(_ref.month, 1),
    monthValue = _ref$month[0],
    _ref$year = _slicedToArray(_ref.year, 1),
    yearValue = _ref$year[0],
    _ref$selected = _slicedToArray(_ref.selected, 2),
    selectedValue = _ref$selected[0],
    setSelectedValue = _ref$selected[1],
    _ref$previous = _slicedToArray(_ref.previous, 2),
    setPreviouslySelectedValue = _ref$previous[1],
    onSelect = _ref.onSelect,
    navigate = _ref.navigate;
  var dateRef = useRef({
    day: dayValue,
    month: monthValue,
    year: yearValue
  });
  useEffect(function () {
    dateRef.current = {
      day: dayValue,
      month: monthValue,
      year: yearValue
    };
  }, [dayValue, monthValue, yearValue]);
  var triggerOnSelect = useCallback(function (_ref2) {
    var year = _ref2.year,
      month = _ref2.month,
      day = _ref2.day;
    var iso = dateToString({
      year: year,
      month: month,
      day: day
    });
    onSelect({
      day: day,
      month: month,
      year: year,
      iso: iso
    });
    setPreviouslySelectedValue(selectedValue);
    setSelectedValue([iso]);
  }, [onSelect, selectedValue, setPreviouslySelectedValue, setSelectedValue]);
  var handleClickDay = useCallback(function (_ref3) {
    var year = _ref3.year,
      month = _ref3.month,
      day = _ref3.day;
    triggerOnSelect({
      year: year,
      month: month,
      day: day
    });
  }, [triggerOnSelect]);
  var handleContainerKeyDown = useCallback(function (e) {
    var key = e.key;
    var arrowKey = arrowKeys[key];
    if (key === 'Enter' || key === ' ') {
      e.preventDefault();
      triggerOnSelect(dateRef.current);
    } else if (arrowKey) {
      e.preventDefault();
      navigate(arrowKey);
    }
  }, [triggerOnSelect, navigate]);
  return {
    handleClickDay: handleClickDay,
    handleContainerKeyDown: handleContainerKeyDown
  };
}