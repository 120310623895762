import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
var EMPTY = {};
function isObjectFlagKey(key, value, objectFlagKeys) {
  return Boolean(typeof value === 'string' && (objectFlagKeys === null || objectFlagKeys === void 0 ? void 0 : objectFlagKeys.includes(key)));
}
function isValidJSONObject(value) {
  try {
    var result = JSON.parse(value);
    if (_typeof(result) === 'object' && result !== null) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

/**
 * Normalise and filter a free Record<string, unknown> to match
 * the rules for feature flags in editor and renderer
 *
 * Input has to match to not be filtered output:
 * 1. cased in kebab-case (match [a-z-])
 * 2. has any value
 *
 * Output matches
 * 1. cased in camelCase (match [a-zA-Z])
 * 2. has boolean value or object {} value
 *
 * @param rawFeatureFlags
 */
export function normalizeFeatureFlags(rawFeatureFlags, options) {
  if (!rawFeatureFlags) {
    return EMPTY;
  }
  return Object.entries(rawFeatureFlags).filter(function (e) {
    if (typeof e[1] === 'boolean') {
      return true;
    }
    if (isObjectFlagKey(camelCase(e[0]), e[1], options === null || options === void 0 ? void 0 : options.objectFlagKeys) && isValidJSONObject(e[1])) {
      return true;
    }
    return false;
  }).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      key = _ref2[0];
    return kebabCase(key) === key;
  }).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];
    return [camelCase(key), value];
  }).reduce(function (flags, _ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
      key = _ref6[0],
      value = _ref6[1];
    if (isObjectFlagKey(key, value, options === null || options === void 0 ? void 0 : options.objectFlagKeys)) {
      flags[key] = JSON.parse(value);
    }
    if (typeof value === 'boolean') {
      flags[key] = value;
    }
    return flags;
  }, {});
}

/**
 * Transforms FeatureFlags to a type safe string array of the enabled feature flags.
 *
 * Useful for analytics and analysis purposes.
 */
export function getEnabledFeatureFlagKeys(featureFlags) {
  return Object.keys(featureFlags).filter(function (key) {
    return featureFlags[key] === true;
  });
}