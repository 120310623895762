import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable @atlaskit/design-system/use-tokens-space */
import { css } from '@emotion/react';
import { h100, h300, h400, h500, h600, h700 } from '@atlaskit/theme/typography';

// @see typography spreadsheet: https://docs.google.com/spreadsheets/d/1iYusRGCT4PoPfvxbJ8NrgjtfFgXLm5lpDWXzjua1W2E/edit#gid=93913128
// text sizing prototype: http://proto/fabricrender/
export var headingsSharedStyles = function headingsSharedStyles() {
  return css({
    '& h1': _objectSpread(_objectSpread({}, h700()), {}, {
      marginBottom: 0,
      marginTop: '1.667em'
    }),
    '& h2': _objectSpread(_objectSpread({}, h600()), {}, {
      marginTop: '1.8em',
      marginBottom: 0
    }),
    '& h3': _objectSpread(_objectSpread({}, h500()), {}, {
      marginTop: '2em',
      marginBottom: 0
    }),
    '& h4': _objectSpread(_objectSpread({}, h400()), {}, {
      marginTop: '1.357em'
    }),
    '& h5': _objectSpread(_objectSpread({}, h300()), {}, {
      marginTop: '1.667em',
      textTransform: 'none'
    }),
    '& h6': _objectSpread(_objectSpread({}, h100()), {}, {
      marginTop: '1.455em',
      textTransform: 'none'
    })
  });
};