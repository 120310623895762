/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import AkBlanket from '@atlaskit/blanket';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';
import { animationTimingFunction, transitionDurationMs } from '../constants';
var blanketStyles = css({
  position: 'relative'
});

/**
 * A wrapper around `@atlaskit/blanket` that adds a fade in/out transition.
 */
var Blanket = function Blanket(_ref) {
  var isOpen = _ref.isOpen,
    onBlanketClicked = _ref.onBlanketClicked,
    testId = _ref.testId;
  return jsx(ExitingPersistence, {
    appear: true
  }, isOpen && jsx(FadeIn
  /**
   * We double the duration because the fade in keyframes have
   * `opacity: 1` at `50%`.
   *
   * The fade out animation uses half the passed duration so it evens out.
   */, {
    duration: transitionDurationMs * 2
    /**
     * We don't expose this on `FadeIn` but it does get passed down.
     * TODO: figure out how we want to handle this...
     */
    // @ts-ignore
    ,
    animationTimingFunction: animationTimingFunction
  }, function (_ref2) {
    var className = _ref2.className;
    return jsx("div", {
      css: blanketStyles,
      className: className
    }, jsx(AkBlanket, {
      isTinted: true,
      onBlanketClicked: onBlanketClicked,
      testId: testId && testId
    }));
  }));
};
export default Blanket;