var clPrefix = 'ak-renderer-';
export var RendererCssClassName = {
  DOCUMENT: "".concat(clPrefix, "document"),
  EXTENSION: "".concat(clPrefix, "extension"),
  EXTENSION_CENTER_ALIGN: "".concat(clPrefix, "extension-center-align"),
  EXTENSION_OVERFLOW_CONTAINER: "".concat(clPrefix, "extension-overflow-container"),
  NUMBER_COLUMN: "".concat(clPrefix, "table-number-column"),
  SORTABLE_COLUMN_WRAPPER: "".concat(clPrefix, "tableHeader-sortable-column__wrapper"),
  SORTABLE_COLUMN: "".concat(clPrefix, "tableHeader-sortable-column"),
  SORTABLE_COLUMN_ICON_WRAPPER: "".concat(clPrefix, "tableHeader-sorting-icon__wrapper"),
  SORTABLE_COLUMN_ICON: "".concat(clPrefix, "tableHeader-sorting-icon"),
  SORTABLE_COLUMN_NO_ORDER: "".concat(clPrefix, "tableHeader-sorting-icon__no-order")
};