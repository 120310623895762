import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["cssFn"];
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { N500 } from '@atlaskit/theme/colors';
var defaultStyle = {
  alignItems: 'center',
  boxSizing: 'border-box',
  color: "var(--ds-text-subtle, ".concat(N500, ")"),
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  height: '100vh',
  paddingBottom: "var(--ds-space-200, 16px)",
  paddingTop: "var(--ds-space-300, 24px)",
  width: "var(--ds-space-800, 64px)"
};
var sidebarCSS = function sidebarCSS() {
  return defaultStyle;
};
var Sidebar = function Sidebar(_ref) {
  var cssFn = _ref.cssFn,
    props = _objectWithoutProperties(_ref, _excluded);
  // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @repo/internal/react/no-unsafe-spread-props
  return jsx("div", _extends({
    css: css(cssFn(defaultStyle))
  }, props));
};
export default {
  component: Sidebar,
  cssFn: sidebarCSS
};