import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var bidiCharacterRegex = /[\u202A-\u202E\u2066-\u2069]/g;

/**
 * __Code Bidi Warning Decorator__
 *
 * Checks the code to see if it contains any bidi characters.
 * In case if bidi characters found - returns children with decorated
 * bidi characters. If no bidi characters found - original text returned.
 */
export default function codeBidiWarningDecorator(originalText, decorate) {
  var matches = _toConsumableArray(originalText.matchAll(bidiCharacterRegex));
  if (matches.length === 0) {
    // No matches encountered, so we return the originalText value
    return originalText;
  }
  var children = [];
  var mappedTo = 0;
  var _iterator = _createForOfIteratorHelper(matches),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var match = _step.value;
      if (mappedTo !== match.index) {
        // There were unmatched characters prior to this match which haven't been
        // mapped to the children.
        // Add them as plain text.
        children.push(originalText.substring(mappedTo, match.index));
      }
      children.push(decorate({
        bidiCharacter: match[0],
        index: match.index
      }));

      // While index is guaranteed to be present, it needs to be asserted due
      // to a limitation of typescripts regex handling
      //
      // https://github.com/microsoft/TypeScript/issues/36788
      // Decorate bidi character
      mappedTo = match.index + match[0].length;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  if (mappedTo !== originalText.length) {
    // There is text following the final match, which needs to be mapped
    // to the children.
    // Added as plain text.
    children.push(originalText.substring(mappedTo, originalText.length));
  }

  // return the mapped children with decorated bidi characters
  return children;
}