import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
export function calcTableColumnWidths(node) {
  var tableColumnWidths = [];
  var firstRow = node.firstChild;
  if (firstRow) {
    // Sanity validation, but it should always have a first row
    // Iterate for the cells in the first row
    firstRow.forEach(function (colNode) {
      var colwidth = colNode.attrs.colwidth || [0];

      // If we have colwidth, we added it
      if (colwidth) {
        tableColumnWidths = [].concat(_toConsumableArray(tableColumnWidths), _toConsumableArray(colwidth));
      }
    });
  }
  return tableColumnWidths;
}
export function hasMergedCell(tableNode) {
  var hasSpan = false;
  tableNode.descendants(function (node) {
    if (node.type.name === 'tableRow') {
      return true;
    }
    var _node$attrs = node.attrs,
      colspan = _node$attrs.colspan,
      rowspan = _node$attrs.rowspan;
    if (colspan > 1 || rowspan > 1) {
      hasSpan = true;
    }
    return false;
  });
  return hasSpan;
}
export function convertProsemirrorTableNodeToArrayOfRows(tableNode) {
  var result = [];
  tableNode.forEach(function (rowNode) {
    if (rowNode.type.name === 'tableRow') {
      var row = [];
      rowNode.forEach(function (n) {
        return row.push(n);
      });
      result.push(row);
    }
  });
  return result;
}

/*
  isPositionNearTableRow()
  Returns true when a sibling node, or any  of the parent's sibling
  nodes are a tableRow
 */
export function isPositionNearTableRow(pos, schema, direction) {
  if (!schema.nodes.tableRow) {
    return false;
  }
  var doc = pos.doc;
  var resolved = pos;
  var sibling = direction === 'before' ? 'nodeBefore' : 'nodeAfter';
  while (resolved.depth > 0) {
    var _resolved$sibling;
    var siblingType = (_resolved$sibling = resolved[sibling]) === null || _resolved$sibling === void 0 ? void 0 : _resolved$sibling.type;
    if (siblingType === schema.nodes.tableRow) {
      return true;
    }
    resolved = doc.resolve(resolved[direction]());
  }
  return false;
}