import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ACTION, ACTION_SUBJECT, ACTION_SUBJECT_ID, EVENT_TYPE } from '../analytics';
var whitelistedAttributes = ['align', 'annotationType', 'extensionKey', 'extensionType', 'layout', 'type', 'localId', 'mode', 'language', 'timestamp', 'state', 'originalWidth', 'originalHeight', 'height', 'width', 'shortName', 'level', 'userType', 'order', 'panelType', 'color', 'style', 'isNumberColumnEnabled', 'colspan', 'rowspan', 'colwidth', 'background'];
function concatAncestorHierarchy(node, ancestoryHierarchy) {
  var name = node.type.name;
  // Space concatenator used to reduce analytics payload size
  return ancestoryHierarchy ? "".concat(ancestoryHierarchy, " ").concat(name) : name;
}
var sanitizeMarks = function sanitizeMarks() {
  var marks = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var sanitizedMarks = [];
  marks.forEach(function (mark) {
    if (mark.attrs) {
      var attrs = sanitizeAttributes(mark.attrs);
      sanitizedMarks.push(_objectSpread(_objectSpread({}, mark), {}, {
        attrs: attrs
      }));
    } else {
      sanitizedMarks.push(_objectSpread({}, mark));
    }
  });
  return sanitizedMarks;
};
var sanitizeAttributes = function sanitizeAttributes() {
  var attrs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var sanitizedAttrs = Object.assign({}, attrs);
  Object.keys(attrs).filter(function (key) {
    return !whitelistedAttributes.includes(key);
  }).forEach(function (key) {
    sanitizedAttrs[key] !== null ? sanitizedAttrs[key] = '' : sanitizedAttrs[key] = 'null';
  });
  return sanitizedAttrs;
};
var trackUnsupportedContentTooltipActionFor = function trackUnsupportedContentTooltipActionFor(action, dispatchAnalyticsEvent, unsupportedContentType, originalNodeType) {
  dispatchAnalyticsEvent({
    action: action,
    actionSubjectId: unsupportedContentType,
    actionSubject: ACTION_SUBJECT.TOOLTIP,
    eventType: EVENT_TYPE.UI,
    attributes: {
      unsupportedNodeType: originalNodeType
    }
  });
};
export var findAndTrackUnsupportedContentNodes = function findAndTrackUnsupportedContentNodes(node, schema, dispatchAnalyticsEvent) {
  var ancestorHierarchy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  var nodeType = node.type,
    nodeMarks = node.marks;
  var _schema$marks = schema.marks,
    unsupportedMark = _schema$marks.unsupportedMark,
    unsupportedNodeAttribute = _schema$marks.unsupportedNodeAttribute;
  var _schema$nodes = schema.nodes,
    unsupportedInline = _schema$nodes.unsupportedInline,
    unsupportedBlock = _schema$nodes.unsupportedBlock;
  var parentType = ancestorHierarchy.split(' ').pop() || '';
  if (nodeMarks.length) {
    nodeMarks.forEach(function (mark) {
      if (mark.type === unsupportedMark) {
        var _ref = mark.attrs || {},
          originalValue = _ref.originalValue;
        var _ref2 = originalValue || {},
          type = _ref2.type;
        var unsupportedNode = {
          type: type || '',
          ancestry: ancestorHierarchy,
          parentType: parentType,
          marks: [],
          attrs: originalValue.attrs || {}
        };
        fireUnsupportedEvent(dispatchAnalyticsEvent, ACTION_SUBJECT_ID.UNSUPPORTED_MARK, unsupportedNode);
      } else if (mark.type === unsupportedNodeAttribute) {
        var _ref3 = mark.attrs || {},
          unsupported = _ref3.unsupported;
        var _unsupportedNodeAttribute = {
          type: nodeType.name || '',
          ancestry: ancestorHierarchy,
          parentType: parentType,
          marks: [],
          attrs: unsupported || {}
        };
        fireUnsupportedEvent(dispatchAnalyticsEvent, ACTION_SUBJECT_ID.UNSUPPORTED_NODE_ATTRIBUTE, _unsupportedNodeAttribute);
      }
    });
  }
  if (nodeType === unsupportedInline || nodeType === unsupportedBlock) {
    var _ref4 = node.attrs || {},
      originalValue = _ref4.originalValue;
    var _ref5 = originalValue || [],
      marks = _ref5.marks;
    var _ref6 = originalValue || {},
      attrs = _ref6.attrs;
    var _ref7 = originalValue || {},
      type = _ref7.type;
    var unsupportedNode = {
      type: type || '',
      ancestry: ancestorHierarchy,
      parentType: parentType,
      marks: marks || [],
      attrs: attrs || {}
    };
    var actionSubjectId = nodeType === unsupportedInline ? ACTION_SUBJECT_ID.UNSUPPORTED_INLINE : ACTION_SUBJECT_ID.UNSUPPORTED_BLOCK;
    fireUnsupportedEvent(dispatchAnalyticsEvent, actionSubjectId, unsupportedNode);
  } else {
    // Recursive check for nested content
    node.content.forEach(function (childNode) {
      return findAndTrackUnsupportedContentNodes(childNode, schema, dispatchAnalyticsEvent, concatAncestorHierarchy(node, ancestorHierarchy));
    });
  }
};
export var fireUnsupportedEvent = function fireUnsupportedEvent(dispatchAnalyticsEvent, actionSubjectId, unsupportedNode, errorCode) {
  var sanitizedAttrs = sanitizeAttributes(unsupportedNode.attrs);
  var sanitizedMarks = sanitizeMarks(unsupportedNode.marks);
  var sanitizedUnsupportedNode = {
    type: unsupportedNode.type,
    ancestry: unsupportedNode.ancestry,
    parentType: unsupportedNode.parentType,
    attrs: sanitizedAttrs,
    marks: sanitizedMarks
  };
  var payload = {
    action: ACTION.UNSUPPORTED_CONTENT_ENCOUNTERED,
    actionSubject: ACTION_SUBJECT.DOCUMENT,
    actionSubjectId: actionSubjectId,
    attributes: _objectSpread({
      unsupportedNode: sanitizedUnsupportedNode
    }, !!errorCode && {
      errorCode: errorCode
    }),
    eventType: EVENT_TYPE.TRACK
  };
  dispatchAnalyticsEvent(payload);
};
export var trackUnsupportedContentTooltipDisplayedFor = function trackUnsupportedContentTooltipDisplayedFor(dispatchAnalyticsEvent, unsupportedContentType, originalNodeType) {
  trackUnsupportedContentTooltipActionFor(ACTION.UNSUPPORTED_TOOLTIP_VIEWED, dispatchAnalyticsEvent, unsupportedContentType, originalNodeType);
};