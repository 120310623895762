export enum Cvss {
    None = "None",
    Low = "Low",
    Medium = "Medium",
    High = "High",
    Critical = "Critical",
}

//CVSS v3.0 Rating from https://nvd.nist.gov/vuln-metrics/cvss
export function CVESeverityMapping(severity: number): Cvss {
    if (severity === 0) {
        return Cvss.None;
    } else if (severity >= 9) {
        return Cvss.Critical;
    } else if (severity >= 7) {
        return Cvss.High;
    } else if (severity >= 4) {
        return Cvss.Medium;
    } else {
        return Cvss.Low;
    }
}
