import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { R400 } from '@atlaskit/theme/colors';
import { codeFontFamily, fontFamily, fontSize as getFontSize,
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
gridSize as getGridSize } from '@atlaskit/theme/constants';
import * as componentTokens from './component-tokens';
var fontSize = getFontSize();
var gridSize = getGridSize();
var disabledRules = {
  light: {
    backgroundColor: componentTokens.disabledBackgroundColor.light,
    backgroundColorHover: componentTokens.disabledBackgroundColor.light,
    borderColor: componentTokens.disabledBackgroundColor.light,
    textColor: componentTokens.disabledTextColor.light
  },
  dark: {
    backgroundColor: componentTokens.disabledBackgroundColor.dark,
    backgroundColorHover: componentTokens.disabledBackgroundColor.dark,
    borderColor: componentTokens.disabledBackgroundColor.dark,
    textColor: componentTokens.disabledTextColor.dark
  }
};
var invalidRules = {
  light: {
    // ----
    backgroundColor: componentTokens.defaultBackgroundColor.light,
    backgroundColorHover: componentTokens.defaultBackgroundColorHover.light,
    // ^--- the above values aren't used directly they remain because its exposed by the `textFieldColors` export
    backgroundColorFocus: componentTokens.defaultBackgroundColorFocus.light,
    borderColor: "var(--ds-border-danger, ".concat(R400, ")"),
    borderColorFocus: componentTokens.defaultBorderColorFocus.light
  },
  dark: {
    // ----
    backgroundColor: componentTokens.defaultBackgroundColor.dark,
    backgroundColorHover: componentTokens.defaultBackgroundColorHover.dark,
    // ^--- the above values aren't used directly they remain because its exposed by the `textFieldColors` export
    backgroundColorFocus: componentTokens.defaultBackgroundColorFocus.dark,
    borderColor: "var(--ds-border-danger, ".concat(R400, ")"),
    borderColorFocus: componentTokens.defaultBorderColorFocus.dark
  }
};
var backgroundColor = {
  standard: componentTokens.defaultBackgroundColor,
  subtle: componentTokens.transparent,
  none: componentTokens.transparent
};
var backgroundColorFocus = {
  standard: componentTokens.defaultBackgroundColorFocus,
  subtle: componentTokens.defaultBackgroundColorFocus,
  none: componentTokens.transparent
};
var backgroundColorHover = {
  standard: componentTokens.defaultBackgroundColorHover,
  subtle: componentTokens.defaultBackgroundColorHover,
  none: componentTokens.transparent
};
var borderColor = {
  standard: componentTokens.defaultBorderColor,
  subtle: componentTokens.transparent,
  none: componentTokens.transparent
};
var borderColorFocus = {
  standard: componentTokens.defaultBorderColorFocus,
  subtle: componentTokens.defaultBorderColorFocus,
  none: componentTokens.transparent
};
var borderColorHover = {
  standard: componentTokens.defaultBorderColor,
  subtle: componentTokens.subtleBorderColorHover,
  none: componentTokens.transparent
};
var getContainerTextBgAndBorderColor = function getContainerTextBgAndBorderColor(appearance, mode) {
  return {
    backgroundColor: backgroundColor[appearance][mode],
    borderColor: borderColor[appearance][mode],
    color: componentTokens.textColor[mode],
    cursor: 'text',
    '&:hover:not([data-disabled])': {
      backgroundColor: backgroundColorHover[appearance][mode],
      borderColor: borderColorHover[appearance][mode]
    },
    '&:focus-within:not([data-disabled])': {
      backgroundColor: backgroundColorFocus[appearance][mode],
      borderColor: borderColorFocus[appearance][mode],
      boxShadow: getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') ? "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", borderColorFocus[appearance][mode]) : undefined
    },
    '&[data-disabled]': _objectSpread({
      color: disabledRules[mode].textColor,
      cursor: 'not-allowed'
    }, appearance === 'standard' && {
      backgroundColor: disabledRules[mode].backgroundColor,
      borderColor: disabledRules[mode].borderColor
    }),
    '&[data-invalid], &[data-invalid]:hover': {
      borderColor: invalidRules[mode].borderColor,
      boxShadow: getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') ? "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", invalidRules[mode].borderColor) : undefined
    },
    '&[data-invalid]:focus-within': {
      backgroundColor: invalidRules[mode].backgroundColorFocus,
      borderColor: invalidRules[mode].borderColorFocus,
      boxShadow: getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') ? "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", invalidRules[mode].borderColorFocus) : undefined
    },
    '@media screen and (-ms-high-contrast: active)': {
      '&[data-invalid]:focus-within': {
        borderColor: 'Highlight'
      },
      '&:focus-within': {
        borderColor: 'Highlight'
      },
      '&[data-disabled]': {
        borderColor: 'GrayText'
      }
    }
  };
};
var widthMap = {
  xsmall: 80,
  small: 160,
  medium: 240,
  large: 320,
  xlarge: 480
};
var getMaxWidth = function getMaxWidth(width) {
  return !width ? "100%" : width in widthMap ? widthMap[width] : +width;
};
export var containerStyles = function containerStyles(appearance, mode, width) {
  return _objectSpread(_objectSpread(_objectSpread({
    alignItems: 'center'
  }, getContainerTextBgAndBorderColor(appearance, mode)), {}, {
    borderRadius: 3,
    borderWidth: getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') ? "var(--ds-border-width, 1px)" : 2
  }, getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') && appearance !== 'none' ? {
    padding: "var(--ds-border-width, 1px)".concat(" 0")
  } : {}), {}, {
    borderStyle: appearance === 'none' ? 'none' : 'solid',
    boxSizing: 'border-box',
    display: 'flex',
    flex: '1 1 100%',
    fontSize: fontSize,
    justifyContent: 'space-between',
    maxWidth: getMaxWidth(width),
    overflow: 'hidden',
    transition: "background-color 0.2s ease-in-out, border-color 0.2s ease-in-out",
    wordWrap: 'break-word',
    verticalAlign: 'top',
    pointerEvents: 'auto'
  });
};
export var inputStyles = function inputStyles(mode) {
  return {
    backgroundColor: 'transparent',
    border: 0,
    boxSizing: 'border-box',
    color: 'inherit',
    cursor: 'inherit',
    fontSize: fontSize,
    minWidth: '0',
    outline: 'none',
    width: '100%',
    lineHeight: gridSize * 2.5 / fontSize,
    fontFamily: fontFamily(),
    '&[data-monospaced]': {
      fontFamily: codeFontFamily()
    },
    '&[data-compact]': {
      padding: "var(--ds-space-050, 4px)".concat(" ", "var(--ds-space-075, 6px)"),
      height: "".concat((gridSize * 3.5 / fontSize).toFixed(2), "em")
    },
    '&:not([data-compact])': {
      padding: "var(--ds-space-100, 8px)".concat(" ", "var(--ds-space-075, 6px)"),
      height: "".concat((gridSize * 4.5 / fontSize).toFixed(2), "em")
    },
    '&[disabled]': {
      // Safari (WebKit) adds a -webkit-text-fill-color style to disabled inputs
      // which takes priority over color and makes the text unreadable. Need to
      // override it with the color we want.
      WebkitTextFillColor: disabledRules[mode].textColor
    },
    // Hide the clear indicator on Edge (Windows only)
    '&::-ms-clear': {
      display: 'none'
    },
    '&:invalid': {
      boxShadow: 'none'
    },
    '&:placeholder-shown': {
      textOverflow: 'ellipsis'
    },
    '&::placeholder': {
      color: componentTokens.placeholderTextColor[mode],
      '&:disabled': {
        color: disabledRules[mode].textColor
      }
    },
    '@media screen and (-ms-high-contrast: active)': {
      '&[disabled]': {
        color: 'GrayText'
      }
    }
  };
};

// TODO: Remove when removing legacy theming.
export var textFieldColors = {
  backgroundColor: backgroundColor,
  backgroundColorFocus: backgroundColorFocus,
  backgroundColorHover: backgroundColorHover,
  borderColor: borderColor,
  borderColorFocus: borderColorFocus,
  borderColorHover: borderColorHover,
  placeholderTextColor: componentTokens.placeholderTextColor,
  textColor: componentTokens.textColor,
  invalidRules: invalidRules,
  disabledRules: disabledRules
};