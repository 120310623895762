export const ApiURLMap: { [key: string]: string } = {
    testing: "http://test.url",
    local: "https://vuln-transparency-api.us-west-2.dev.atl-paas.net",
    development: "https://api.stg.atlassian.com/vuln-transparency/api",
    staging: "https://wac.stg.internal.atlassian.com/gateway/api/vuln-transparency",
    production: "https://www.atlassian.com/gateway/api/vuln-transparency",
};

export const MaxNumberOfTableIcons = 5;
export const TableRowsPerPage = 10;
