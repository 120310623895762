import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["cssFn"];
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
var defaultStyles = {
  flex: 1,
  overflow: 'auto',
  marginTop: "var(--ds-space-300, 24px)"
};
var contentCSS = function contentCSS() {
  return defaultStyles;
};
var Content = function Content(_ref) {
  var cssFn = _ref.cssFn,
    props = _objectWithoutProperties(_ref, _excluded);
  return (
    /**
     * I noticed the implementation at @atlaskit/checkbox would send the props to cssFn rather
     * than the defaultStyles as the overrides guide suggests. I went with what the overrides
     * guide suggested as it made more sense as a transformer of the current styles rather than
     * a complete override with no chance of partially changing styles.
     */

    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @repo/internal/react/no-unsafe-spread-props
    jsx("div", _extends({
      css: css(cssFn(defaultStyles))
    }, props))
  );
};
export default {
  component: Content,
  cssFn: contentCSS
};