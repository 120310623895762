import "@atlaskit/css-reset";
import React, { useEffect, useState } from "react";
import CVETable from "./cvetable";
import { TableDataObject, getTableData } from "./domain/cvedata";

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [errorData, setErrorData] = useState(false);
    const [tableData, setTableData] = useState({
        cveData: {},
        productOptions: {},
    } as TableDataObject);

    // Shows a really simple side effect that could be something like loading data from a remote resource. We just use a timeout for this.
    // Passing the empty array as the second argument will mean it is only ran on the initial component mount.
    useEffect(() => {
        getTableData()
            .then(data => {
                setTableData({
                    cveData: data.cveData,
                    productOptions: data.productOptions,
                });
                setIsLoading(false);
            })
            .catch(e => {
                setErrorData(e);
                setIsLoading(false);
            });
    }, []);

    return (
        <div className="App">
            <link rel="stylesheet" href="node_modules/@atlaskit/css-reset/dist/bundle.css" />
            <header className="App-header">
                <br></br>
            </header>
            <CVETable tableData={tableData} errorData={errorData} isLoading={isLoading} />
        </div>
    );
};

export default App;
