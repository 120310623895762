import { akEditorBreakoutPadding, akEditorDefaultLayoutWidth, akEditorFullWidthLayoutWidth, akEditorSwoopCubicBezier, akEditorWideLayoutWidth, breakoutWideScaleRatio } from '@atlaskit/editor-shared-styles';
import commonMessages from '../messages';
import { mapBreakpointToLayoutMaxWidth } from '../ui/BaseTheme';
import { getBreakpoint } from '../ui/WidthProvider';
import { parsePx } from './dom';

/**
 * Variables required to construct a context for breakout ssr inline script.
 *
 * TODO: Clean this up after: https://product-fabric.atlassian.net/browse/ED-8942
 *
 */
var breakoutConsts = {
  padding: akEditorBreakoutPadding,
  defaultLayoutWidth: akEditorDefaultLayoutWidth,
  wideScaleRatio: breakoutWideScaleRatio,
  fullWidthLayoutWidth: akEditorFullWidthLayoutWidth,
  wideLayoutWidth: akEditorWideLayoutWidth,
  mapBreakpointToLayoutMaxWidth: mapBreakpointToLayoutMaxWidth,
  getBreakpoint: getBreakpoint,
  /**
   * This function can return percentage value or px value depending upon the inputs
   */
  calcBreakoutWidth: function calcBreakoutWidth(layout, containerWidth) {
    var effectiveFullWidth = containerWidth - breakoutConsts.padding;
    switch (layout) {
      case 'full-width':
        return "".concat(Math.min(effectiveFullWidth, breakoutConsts.fullWidthLayoutWidth), "px");
      case 'wide':
        if (effectiveFullWidth <= 0) {
          return '100%';
        }
        var wideWidth = breakoutConsts.calcWideWidth(containerWidth);
        if (wideWidth.endsWith('%')) {
          return "".concat(Math.min(effectiveFullWidth, breakoutConsts.fullWidthLayoutWidth), "px");
        }
        return wideWidth;
      default:
        return '100%';
    }
  },
  calcLineLength: function calcLineLength() {
    return breakoutConsts.defaultLayoutWidth;
  },
  calcWideWidth: function calcWideWidth() {
    var containerWidth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : breakoutConsts.defaultLayoutWidth;
    var maxWidth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
    var fallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '100%';
    var effectiveFullWidth = containerWidth - breakoutConsts.padding;
    var layoutMaxWidth = breakoutConsts.mapBreakpointToLayoutMaxWidth(breakoutConsts.getBreakpoint(containerWidth));
    var wideWidth = Math.min(Math.ceil(layoutMaxWidth * breakoutConsts.wideScaleRatio), effectiveFullWidth);
    return layoutMaxWidth > wideWidth ? fallback : "".concat(Math.min(maxWidth, wideWidth), "px");
  }
};
export var absoluteBreakoutWidth = function absoluteBreakoutWidth(layout, containerWidth) {
  var breakoutWidth = breakoutConsts.calcBreakoutWidth(layout, containerWidth);

  // If it's percent, map to max layout size
  if (breakoutWidth.endsWith('%')) {
    switch (layout) {
      case 'full-width':
        return akEditorFullWidthLayoutWidth;
      case 'wide':
        return akEditorWideLayoutWidth;
      default:
        return breakoutConsts.mapBreakpointToLayoutMaxWidth(breakoutConsts.getBreakpoint(containerWidth));
    }
  }
  return parseInt(breakoutWidth, 10);
};
export { breakoutConsts };
export var calcWideWidth = breakoutConsts.calcWideWidth;
export var calcBreakoutWidth = breakoutConsts.calcBreakoutWidth;
export function calculateBreakoutStyles(_ref) {
  var mode = _ref.mode,
    widthStateLineLength = _ref.widthStateLineLength,
    widthStateWidth = _ref.widthStateWidth;
  var breakoutWidth = calcBreakoutWidth(mode, widthStateWidth);
  var breakoutWidthPx = parsePx(breakoutWidth);
  if (!widthStateLineLength) {
    // lineLength is not normally undefined when this is run for,
    // consumers but can be in SSR, initial render or test (jsdom)
    // environments.
    //
    // this approach doesn't work well with position: fixed, so
    // it breaks things like sticky headers.
    //
    // It can also cause bluriness for some child content (such as iframes)

    return {
      type: 'line-length-unknown',
      width: breakoutWidth,
      minWidth: breakoutWidthPx,
      display: 'flex',
      justifyContent: 'center',
      transform: 'none',
      transition: "min-width 0.5s ".concat(akEditorSwoopCubicBezier)
    };
  }

  // NOTE
  // At time of writing -- when toggling between full-width and
  // full-page appearance modes. There is a slight delay before
  // the widthState is updated.
  // During this period -- the marginLeftPx will be incorrect.
  // const marginLeftPx = -(breakoutWidthPx - widthStateLineLength) / 2;

  return {
    type: 'line-length-known',
    width: breakoutWidth,
    minWidth: breakoutWidthPx,
    transition: "min-width 0.5s ".concat(akEditorSwoopCubicBezier),
    transform: "translateX(-50%)",
    marginLeft: "50%"
  };
}
export function calcBreakoutWidthPx(mode, widthStateWidth) {
  return parsePx(calcBreakoutWidth(mode, widthStateWidth));
}
export var getNextBreakoutMode = function getNextBreakoutMode(currentMode) {
  if (currentMode === 'full-width') {
    return 'center';
  } else if (currentMode === 'wide') {
    return 'full-width';
  }
  return 'wide';
};
export var getTitle = function getTitle(layout) {
  switch (layout) {
    case 'full-width':
      return commonMessages.layoutFixedWidth;
    case 'wide':
      return commonMessages.layoutFullWidth;
    default:
      return commonMessages.layoutWide;
  }
};