import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Loadable from 'react-loadable';
import Blockquote from './blockquote';
import BodiedExtension from './bodiedExtension';
import MultiBodiedExtension from './multiBodiedExtension';
import ExtensionFrame from './extensionFrame';
import BulletList from './bulletList';
import Doc, { DocWithSelectAllTrap } from './doc';
import Extension from './extension';
import HardBreak from './hardBreak';
import Heading from './heading';
import InlineExtension from './inlineExtension';
import LayoutSection from './layoutSection';
import LayoutColumn from './layoutColumn';
import ListItem from './listItem';
import Caption from './caption';
import OrderedList from './orderedList';
import Paragraph from './paragraph';
import Placeholder from './placeholder';
import Rule from './rule';
import Table from './table';
import { TableCell, TableHeader } from './tableCell';
import TableRow from './tableRow';
import UnknownBlock from './unknownBlock';
import { UnsupportedBlock, UnsupportedInline } from '@atlaskit/editor-common/ui';
var WindowedCodeBlock = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_WindowedCodeBlock" */
    './codeBlock/windowedCodeBlock').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var CodeBlock = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_CodeBlock" */
    './codeBlock/codeBlock').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var TaskList = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_TaskList" */
    './taskList').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var TaskItem = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_TaskItem" */
    './taskItem').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var DecisionList = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_DecisionList" */
    './decisionList').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var DecisionItem = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_DecisionItem" */
    './decisionItem').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var Date = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_Date" */
    './date').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var Status = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_Status" */
    './status').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var Emoji = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_Emoji" */
    './emoji').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var Panel = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_Panel" */
    './panel').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var EmbedCard = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_EmbedCard" */
    './embedCard').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var InlineCard = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_InlineCard" */
    './inlineCard').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var BlockCard = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_BlockCard" */
    './blockCard').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var Media = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_Media" */
    './media').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var MediaGroup = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_MediaGroup" */
    './mediaGroup').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var MediaInline = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_MediaInline" */
    './mediaInline').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var MediaSingle = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_MediaSingle" */
    './mediaSingle').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var Mention = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_Mention" */
    './mention').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
var Expand = Loadable({
  loader: function loader() {
    return import( /* webpackChunkName: "@atlaskit-internal_renderer-node_Expand" */
    '../../ui/Expand').then(function (mod) {
      return mod.default;
    });
  },
  loading: function loading() {
    return null;
  }
});
export var nodeToReact = {
  blockquote: Blockquote,
  bulletList: BulletList,
  blockCard: BlockCard,
  caption: Caption,
  date: Date,
  decisionItem: DecisionItem,
  decisionList: DecisionList,
  doc: Doc,
  emoji: Emoji,
  extension: Extension,
  bodiedExtension: BodiedExtension,
  multiBodiedExtension: MultiBodiedExtension,
  extensionFrame: ExtensionFrame,
  hardBreak: HardBreak,
  heading: Heading,
  inlineCard: InlineCard,
  inlineExtension: InlineExtension,
  layoutSection: LayoutSection,
  layoutColumn: LayoutColumn,
  listItem: ListItem,
  media: Media,
  mediaGroup: MediaGroup,
  mediaInline: MediaInline,
  mediaSingle: MediaSingle,
  mention: Mention,
  orderedList: OrderedList,
  panel: Panel,
  paragraph: Paragraph,
  placeholder: Placeholder,
  rule: Rule,
  status: Status,
  taskItem: TaskItem,
  taskList: TaskList,
  table: Table,
  tableCell: TableCell,
  tableHeader: TableHeader,
  tableRow: TableRow,
  unknownBlock: UnknownBlock,
  unsupportedBlock: UnsupportedBlock,
  unsupportedInline: UnsupportedInline,
  expand: Expand,
  nestedExpand: Expand,
  embedCard: EmbedCard
};
export var toReact = function toReact(node, flags, nodeComponents) {
  if (node.type.name === 'doc' && (flags === null || flags === void 0 ? void 0 : flags.allowSelectAllTrap) === true) {
    return DocWithSelectAllTrap;
  }
  if (node.type.name === 'codeBlock') {
    if ((flags === null || flags === void 0 ? void 0 : flags.allowWindowedCodeBlock) === true) {
      return WindowedCodeBlock;
    }
    return CodeBlock;
  }

  // Allowing custom components to override those provided in nodeToReact
  var nodes = _objectSpread(_objectSpread({}, nodeToReact), nodeComponents);
  return nodes[node.type.name];
};
/*
 *  Wraps adjacent textnodes in a textWrapper
 *
 *  Input:
 *  [
 *    {
 *      type: 'text',
 *      text: 'Hello'
 *    },
 *    {
 *      type: 'text',
 *      text: 'World!',
 *      marks: [
 *        {
 *          type: 'strong'
 *        }
 *      ]
 *    }
 *  ]
 *
 *  Output:
 *  [
 *    {
 *      type: 'textWrapper',
 *      content: [
 *        {
 *          type: 'text',
 *          text: 'Hello'
 *        },
 *        {
 *          type: 'text',
 *          text: 'World!',
 *          marks: [
 *            {
 *              type: 'strong'
 *            }
 *          ]
 *        }
 *      ]
 *    }
 *  ]
 */
export var mergeTextNodes = function mergeTextNodes(nodes) {
  return nodes.reduce(function (acc, current) {
    if (!isText(current.type.name)) {
      acc.push(current);
      return acc;
    }

    // Append node to previous node, if it was a text wrapper
    if (acc.length > 0 && isTextWrapper(acc[acc.length - 1])) {
      var textWrapper = acc[acc.length - 1];
      textWrapper.content.push(current);
      textWrapper.nodeSize += current.nodeSize;
    } else {
      acc.push({
        type: {
          name: 'textWrapper'
        },
        content: [current],
        nodeSize: current.nodeSize
      });
    }
    return acc;
  }, []);
};
export var isText = function isText(type) {
  return type === 'text';
};
export var isTextWrapper = function isTextWrapper(node) {
  return node.type.name === 'textWrapper';
};
export function isTextNode(node) {
  return node.type.name === 'text';
}
var whitespaceRegex = /^\s*$/;

/**
 * Detects whether a fragment contains a single paragraph node
 * whose content satisfies the condition for an emoji block
 */
export var isEmojiDoc = function isEmojiDoc(doc) {
  if (doc.childCount !== 1) {
    return false;
  }
  var parentNodes = [];
  doc.forEach(function (child) {
    return parentNodes.push(child);
  });
  var node = parentNodes[0];
  return node.type.name === 'paragraph' && isEmojiBlock(node.content);
};
var isEmojiBlock = function isEmojiBlock(pnode) {
  var content = [];
  // Optimisation for long documents - worst case block will be space-emoji-space
  if (pnode.childCount > 7) {
    return false;
  }
  pnode.forEach(function (child) {
    return content.push(child);
  });
  var emojiCount = 0;
  for (var i = 0; i < content.length; ++i) {
    var node = content[i];
    switch (node.type.name) {
      case 'text':
        if (node.text && !node.text.match(whitespaceRegex)) {
          return false;
        }
        continue;
      case 'emoji':
        if (++emojiCount > 3) {
          return false;
        }
        continue;
      default:
        // Only text and emoji nodes are allowed
        return false;
    }
  }
  return emojiCount > 0;
};
export { Blockquote, BodiedExtension, BulletList, BlockCard, Caption, CodeBlock, WindowedCodeBlock, Date, DecisionItem, DecisionList, Doc, DocWithSelectAllTrap, Emoji, Extension, ExtensionFrame, Expand, HardBreak, Heading, ListItem, InlineCard, InlineExtension, LayoutSection, LayoutColumn, Media, MediaGroup, MediaInline, MediaSingle, Mention, MultiBodiedExtension, OrderedList, Panel, Paragraph, Placeholder, Rule, Status, TaskItem, TaskList, Table, TableCell, TableRow, UnknownBlock, EmbedCard };