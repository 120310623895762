import { traverse } from '../traverse/traverse';
// link mark on mediaSingle is deprecated, need to move link mark to child media node
// https://product-fabric.atlassian.net/browse/ED-14043
export var transformMediaLinkMarks = function transformMediaLinkMarks(adf) {
  var isTransformed = false;
  var transformedAdf = traverse(adf, {
    mediaSingle: function mediaSingle(node) {
      var _node$content$;
      if (!node.marks || !node.content || ((_node$content$ = node.content[0]) === null || _node$content$ === void 0 ? void 0 : _node$content$.type) !== 'media') {
        return node;
      }
      var linkMark = null;
      node.marks.forEach(function (mark, i) {
        if (mark.type === 'link') {
          var _node$marks;
          linkMark = mark;
          (_node$marks = node.marks) === null || _node$marks === void 0 || _node$marks.splice(i, 1);
          isTransformed = true;
        }
      });
      if (node.marks.length === 0) {
        delete node.marks;
      }
      if (linkMark) {
        var _mediaNode$marks;
        var mediaNode = node.content[0];
        // only add link mark if media node doesnt already have one
        if ((_mediaNode$marks = mediaNode.marks) !== null && _mediaNode$marks !== void 0 && _mediaNode$marks.every(function (mark) {
          return mark.type !== 'link';
        })) {
          mediaNode.marks.push(linkMark);
        } else if (!mediaNode.marks) {
          mediaNode.marks = [linkMark];
        }
      }
      return node;
    }
  });
  return {
    transformedAdf: transformedAdf,
    isTransformed: isTransformed
  };
};