import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import memoizeOne from 'memoize-one';
import * as colors from '@atlaskit/theme/colors';
import { codeFontFamily } from '@atlaskit/theme/constants';
// Hardcoded values have been used due to the current color palette not having any
// accessible color options for Teal and Yellow and +20A
var T800 = '#067384';
var Y1100 = '#7A5D1A';
export var getBaseTheme = function getBaseTheme() {
  return {
    fontFamily: codeFontFamily(),
    fontFamilyItalic: "SFMono-MediumItalic, ".concat(codeFontFamily()),
    backgroundColor: "var(--ds-background-neutral, ".concat(colors.N20, ")"),
    textColor: "var(--ds-text, ".concat(colors.N800, ")"),
    lineNumberColor: "var(--ds-text-subtlest, ".concat(colors.N400, ")"),
    lineNumberBgColor: "var(--ds-background-neutral, ".concat(colors.N30, ")")
  };
};
export var defaultBaseTheme = getBaseTheme();
export var getColorPalette = memoizeOne(function () {
  return {
    highlightedLineBgColor: "var(--ds-background-neutral, ".concat(colors.N30, ")"),
    highlightedLineBorderColor: "var(--ds-border-focused, ".concat(colors.B200, ")"),
    substringColor: "var(--ds-text-subtlest, ".concat(colors.N400, ")"),
    keywordColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    attributeColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    selectorTagColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    docTagColor: "var(--ds-text-accent-yellow, ".concat(Y1100, ")"),
    nameColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    builtInColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    literalColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    bulletColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    codeColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    regexpColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    symbolColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    variableColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    templateVariableColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    linkColor: "var(--ds-text-accent-purple, ".concat(colors.P300, ")"),
    selectorAttributeColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    selectorPseudoColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    typeColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    stringColor: "var(--ds-text-accent-green, ".concat(colors.G500, ")"),
    selectorIdColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    selectorClassColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    quoteColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    templateTagColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    titleColor: "var(--ds-text-accent-purple, ".concat(colors.P300, ")"),
    sectionColor: "var(--ds-text-accent-purple, ".concat(colors.P300, ")"),
    commentColor: "var(--ds-text-subtlest, ".concat(colors.N400, ")"),
    metaKeywordColor: "var(--ds-text-accent-green, ".concat(colors.G500, ")"),
    metaColor: "var(--ds-text-subtlest, ".concat(colors.N400, ")"),
    functionColor: "var(--ds-text, ".concat(colors.N800, ")"),
    numberColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    prologColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    cdataColor: "var(--ds-text-subtlest, ".concat(colors.N400, ")"),
    punctuationColor: "var(--ds-text, ".concat(colors.N800, ")"),
    propertyColor: "var(--ds-text-accent-purple, ".concat(colors.P300, ")"),
    constantColor: "var(--ds-text-accent-teal, ".concat(T800, ")"),
    booleanColor: "var(--ds-text-accent-blue, ".concat(colors.B400, ")"),
    charColor: "var(--ds-text, ".concat(colors.N800, ")"),
    insertedColor: "var(--ds-text-accent-green, ".concat(colors.G500, ")"),
    deletedColor: "var(--ds-text-accent-red, ".concat(colors.R500, ")"),
    operatorColor: "var(--ds-text, ".concat(colors.N800, ")"),
    atruleColor: "var(--ds-text-accent-green, ".concat(colors.G500, ")"),
    importantColor: "var(--ds-text-accent-yellow, ".concat(Y1100, ")")
  };
});
var getTheme = function getTheme() {
  return _objectSpread(_objectSpread({}, getBaseTheme()), getColorPalette());
};
export default getTheme;