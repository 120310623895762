import { AddMarkStep } from '@atlaskit/editor-prosemirror/transform';
function getStartPos(element) {
  return parseInt(element.dataset.rendererStartPos || '-1', 10);
}
var getNodeType = function getNodeType(element) {
  return element.dataset.nodeType;
};
function isPositionPointer(element) {
  return getStartPos(element) > -1;
}
function findParent(element) {
  var parentElement = element.parentElement;
  if (!parentElement || isRoot(parentElement)) {
    return null;
  }
  if (isPositionPointer(parentElement)) {
    return parentElement;
  }
  return findParent(parentElement);
}
function findParentBeforePointer(element) {
  var parentElement = element.parentElement;
  if (isRoot(parentElement) || !parentElement) {
    return null;
  }
  if (isPositionPointer(parentElement)) {
    return element;
  }
  return findParentBeforePointer(parentElement);
}
function isElementNode(node) {
  return node.nodeType === Node.ELEMENT_NODE;
}
function isTextNode(node) {
  return node.nodeType === Node.TEXT_NODE;
}
function isHighlightTextNode(node) {
  var _dataset;
  return !!(node !== null && node !== void 0 && (_dataset = node.dataset) !== null && _dataset !== void 0 && _dataset.highlighted);
}
function isNodeInlineMark(node) {
  return isElementNode(node) && Boolean(node.dataset.rendererMark);
}
function isElementInlineMark(element) {
  return !!element && Boolean(element.dataset.rendererMark);
}

/**
 * This returns the text content of a node excluding content
 * inside inline cards (spans with data-inline-card="true").
 */
function getPMTextContent(node) {
  if (isTextNode(node)) {
    return node.textContent;
  }
  if (isElementNode(node)) {
    if (node.dataset.inlineCard === 'true') {
      return '';
    }
    return Array.from(node.childNodes).map(getPMTextContent).join('');
  }
  return '';
}
function resolveNodePos(node) {
  var resolvedPos = 0;
  var prev = node.previousSibling;
  while (prev) {
    if (prev && (isTextNode(prev) || isHighlightTextNode(prev))) {
      resolvedPos += (prev.textContent || '').length;
    } else if (prev) {
      if (isNodeInlineMark(prev) && prev.textContent) {
        resolvedPos += getPMTextContent(prev).length;
      } else {
        resolvedPos += 1;
      }
    }
    prev = prev.previousSibling;
  }
  return resolvedPos;
}
function isRoot(element) {
  return !!element && element.classList.contains('ak-renderer-document');
}
export function resolvePos(node, offset) {
  // If the passed node doesn't exist, we should abort
  if (!node) {
    return false;
  }
  if (node instanceof HTMLElement && isPositionPointer(node)) {
    return getStartPos(node) + offset;
  }
  var parent = findParent(node);

  // Similar to above, if we cant find a parent position pointer
  // we should not proceed.
  if (!parent) {
    return false;
  }
  var resolvedPos = getStartPos(parent);
  var current = node;
  if (current.parentElement && current.parentElement !== parent) {
    // Find the parent element that is a direct child of the position pointer
    // the outer most element from our text position.
    var preParentPointer = findParentBeforePointer(current.parentElement);
    // If our range is inside an inline node
    // We need to move our pointers to parent element
    // since we don't want to count text inside inline nodes at all
    if (!(isElementInlineMark(preParentPointer) || isHighlightTextNode(preParentPointer))) {
      current = current.parentElement;
      offset = 0;
    }
    resolvedPos += resolveNodePos(current);
    while (current && current.parentElement !== parent) {
      current = current.parentNode;
      if (current) {
        var nodePos = resolveNodePos(current);
        resolvedPos += nodePos;
      }
    }
  } else {
    resolvedPos += resolveNodePos(current);
  }
  return resolvedPos + offset;
}
export function getPosFromRange(range) {
  var startContainer = range.startContainer,
    startOffset = range.startOffset,
    endContainer = range.endContainer,
    endOffset = range.endOffset;
  var parent = findParent(startContainer);
  if (parent && getNodeType(parent) === 'media') {
    var pos = getStartPos(parent);
    return {
      from: pos,
      to: pos
    };
  }
  var from = resolvePos(startContainer, startOffset);
  var to = resolvePos(endContainer, endOffset);
  if (from === false || to === false) {
    return false;
  }
  return {
    from: from,
    to: to
  };
}
export function createAnnotationStep(from, to, opts) {
  return new AddMarkStep(Math.min(from, to), Math.max(from, to), opts.schema.marks.annotation.create({
    id: opts.annotationId,
    annotationType: opts.annotationType
  }));
}