import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
export var dataAttributes = function dataAttributes(_ref) {
  var _ref2;
  var from = _ref.from,
    to = _ref.to;
  return _ref2 = {}, _defineProperty(_ref2, 'data-annotation-draft-mark', true), _defineProperty(_ref2, 'data-draft-start-at', from), _defineProperty(_ref2, 'data-draft-end-at', to), _ref2;
};
var buildDataAttributesQuery = function buildDataAttributesQuery(pos) {
  var a = dataAttributes(pos);
  return Object.entries(a).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      k = _ref4[0],
      v = _ref4[1];
    return "[".concat(k, "=\"").concat(v, "\"]");
  }).join('');
};
export var updateWindowSelectionAroundDraft = function updateWindowSelectionAroundDraft(pos) {
  var sel = window.getSelection();
  if (!sel) {
    return false;
  }
  var draftNodes = document.querySelectorAll(buildDataAttributesQuery(pos));
  if (!draftNodes || draftNodes.length === 0) {
    return false;
  }
  sel.removeAllRanges();
  var range = document.createRange();
  range.setStart(draftNodes[0], 0);
  range.setEndAfter(draftNodes[draftNodes.length - 1]);
  sel.addRange(range);
  return true;
};