import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createStore } from 'zustand/vanilla';
var mediaStoreWithoutDevtools = createStore()(subscribeWithSelector(immer(function () {
  return {
    files: {}
  };
})));
var mediaStoreWithDevtools = createStore()(devtools(subscribeWithSelector(immer(function () {
  return {
    files: {}
  };
}))));
export var mediaStore = process.env.NODE_ENV === 'development' && !process.env.CI ? mediaStoreWithDevtools : mediaStoreWithoutDevtools;
export var createMediaStore = function createMediaStore(initialStore) {
  return createStore()(subscribeWithSelector(immer(function () {
    return _objectSpread({
      files: {}
    }, initialStore);
  })));
};