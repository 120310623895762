/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import { css } from '@emotion/react';
import { tableBackgroundColorNames } from '@atlaskit/adf-schema';
import { hexToEditorBackgroundPaletteColor } from '@atlaskit/editor-palette';
// This is used in order to support usage of DS tokens. Table cell background-color
// is set inline in '@atlaskit/adf-schema' and the color value is stored in ADF so
// it is not possible to use tokens there without polluting ADF.
// As table cell backgrounds are set inline, this should not break mobile as
// hexToEditorBackgroundPaletteColor() outputs a css variable with fallback hex.
var mapBackgroundColors = function mapBackgroundColors() {
  var cssString = '';
  tableBackgroundColorNames.forEach(function (value, key) {
    var paletteColorValue = hexToEditorBackgroundPaletteColor(value);
    if (paletteColorValue) {
      cssString += "\n        td[colorname='".concat(key, "' i],\n        th[colorname='").concat(key, "' i] {\n          background-color: ").concat(paletteColorValue, " !important;\n        }\n      ");
    }
  });
  return cssString;
};
export var tableCellBackgroundStyleOverride = function tableCellBackgroundStyleOverride() {
  return css(mapBackgroundColors());
};