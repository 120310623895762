import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useContext, useEffect } from 'react';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { ProvidersContext } from '../context';
import { RendererContext as ActionsContext } from '../../RendererActionsContext';
export var useLoadAnnotations = function useLoadAnnotations(_ref) {
  var adfDocument = _ref.adfDocument;
  var actions = useContext(ActionsContext);
  var providers = useContext(ProvidersContext);
  useEffect(function () {
    if (!providers) {
      return;
    }
    var _providers$inlineComm = providers.inlineComment,
      inlineCommentGetState = _providers$inlineComm.getState,
      updateSubscriberInlineComment = _providers$inlineComm.updateSubscriber;
    var annotations = actions.getAnnotationMarks();
    // we don't want to request integrators for state with an empty list of ids.
    if (!annotations.length) {
      return;
    }
    var ids = annotations.map(function (mark) {
      return mark.attrs.id;
    });
    var cb = function cb(data) {
      if (!updateSubscriberInlineComment) {
        return;
      }
      var payload = data.reduce(function (acc, value) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, value.id, value));
      }, {});
      updateSubscriberInlineComment.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, payload);
    };
    inlineCommentGetState(ids).then(cb);
  }, [actions, providers, adfDocument]);
};