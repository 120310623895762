import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { B100, B200, DN10, DN200, DN30, DN40, DN600, DN90, N0, N10, N100, N200, N30, N40, N70, N900 } from '@atlaskit/theme/colors';
export var disabledBackgroundColor = {
  light: "var(--ds-background-disabled, ".concat(N10, ")"),
  dark: "var(--ds-background-disabled, ".concat(DN10, ")")
};
export var defaultBackgroundColor = {
  light: "var(--ds-background-input, ".concat(N10, ")"),
  dark: "var(--ds-background-input, ".concat(DN10, ")")
};
export var defaultBackgroundColorFocus = {
  light: "var(--ds-background-input-pressed, ".concat(N0, ")"),
  dark: "var(--ds-background-input-pressed, ".concat(DN10, ")")
};
export var defaultBackgroundColorHover = {
  light: "var(--ds-background-input-hovered, ".concat(N30, ")"),
  dark: "var(--ds-background-input-hovered, ".concat(DN30, ")")
};
export var defaultBorderColor = {
  light: "var(--ds-border-input, ".concat(getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') ? N100 : N40, ")"),
  dark: "var(--ds-border-input, ".concat(getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') ? DN200 : DN40, ")")
};
export var defaultBorderColorFocus = {
  light: "var(--ds-border-focused, ".concat(B200, ")"),
  dark: "var(--ds-border-focused, ".concat(B100, ")")
};
export var subtleBorderColorHover = {
  light: "var(--ds-border-input, transparent)",
  dark: "var(--ds-border-input, transparent)"
};
export var transparent = {
  light: 'transparent',
  dark: 'transparent'
};
export var textColor = {
  light: "var(--ds-text, ".concat(N900, ")"),
  dark: "var(--ds-text, ".concat(DN600, ")")
};
export var disabledTextColor = {
  light: "var(--ds-text-disabled, ".concat(N70, ")"),
  dark: "var(--ds-text-disabled, ".concat(DN90, ")")
};
export var placeholderTextColor = {
  light: "var(--ds-text-subtlest, ".concat(N200, ")"),
  dark: "var(--ds-text-subtlest, ".concat(DN200, ")")
};