import { SEVERITY } from '../analytics';
import { isPerformanceObserverLongTaskAvailable } from './is-performance-api-available';
import { getDistortedDurationMonitor } from './measure-render';
export function measureTTI(onMeasureComplete) {
  var idleThreshold = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
  var cancelAfter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 60;
  var
  // Dependency Injection for easier testing
  PerfObserver = arguments.length > 3 ? arguments[3] : undefined;
  if (!isPerformanceObserverLongTaskAvailable()) {
    return;
  }
  var start = performance.now();
  // Keeping track of users moving away from the tab, which distorts the TTI measurement
  var distortedDurationMonitor = getDistortedDurationMonitor();
  var prevLongTask;
  var lastLongTask = {
    startTime: start,
    duration: 0
  };
  var cancelAfterMs = cancelAfter * 1000;
  var observer = new (PerfObserver || PerformanceObserver)(function (list) {
    var entries = list.getEntries();
    if (entries.length) {
      prevLongTask = lastLongTask;
      lastLongTask = entries[entries.length - 1];
    }
  });
  observer.observe({
    entryTypes: ['longtask']
  });
  var checkIdle = function checkIdle() {
    // 1. There hasn't been any long task in `idleThreshold` time: Interactive from the start.
    // 2. Only 1 long task: Interactive from the end of the only long task.
    // 3. Several long tasks:
    //    3.1 Interactive from the end of prevLongTask if `lastLongTask.start - prevLongTask.end >= idleThreshold`
    //    3.2 Interactive from the end of lastLongTask if `lastLongTask.start - prevLongTask.end < idleThreshold`

    var now = performance.now();
    var lastEnd = lastLongTask.startTime + lastLongTask.duration;
    var prevEnd = prevLongTask ? prevLongTask.startTime + prevLongTask.duration : lastEnd;
    var elapsedTimeMs = now - start;
    var canceled = elapsedTimeMs > cancelAfterMs;
    if (!prevLongTask) {
      observer.disconnect();
      distortedDurationMonitor.cleanup();
      return onMeasureComplete(prevEnd, 0, false, distortedDurationMonitor.distortedDuration);
    } else if (lastLongTask.startTime - prevEnd >= idleThreshold) {
      observer.disconnect();
      distortedDurationMonitor.cleanup();
      return onMeasureComplete(prevEnd, prevEnd - start, canceled, distortedDurationMonitor.distortedDuration);
    } else if (now - lastEnd >= idleThreshold || canceled) {
      observer.disconnect();
      distortedDurationMonitor.cleanup();
      return onMeasureComplete(lastEnd, lastEnd - start, canceled, distortedDurationMonitor.distortedDuration);
    }
    return setTimeout(checkIdle, idleThreshold);
  };
  setTimeout(checkIdle, idleThreshold);
}
export var TTI_SEVERITY_THRESHOLD_DEFAULTS = {
  NORMAL: 40000,
  DEGRADED: 60000
};
export var TTI_FROM_INVOCATION_SEVERITY_THRESHOLD_DEFAULTS = {
  NORMAL: 5000,
  DEGRADED: 8000
};
export function getTTISeverity(tti, ttiFromInvocation, ttiSeverityNormalThreshold, ttiSeverityDegradedThreshold, ttiFromInvocationSeverityNormalThreshold, ttiFromInvocationSeverityDegradedThreshold) {
  var ttiNormalThreshold = ttiSeverityNormalThreshold || TTI_SEVERITY_THRESHOLD_DEFAULTS.NORMAL;
  var ttiDegradedThreshold = ttiSeverityDegradedThreshold || TTI_SEVERITY_THRESHOLD_DEFAULTS.DEGRADED;
  var ttiSeverity;
  if (tti >= ttiNormalThreshold && tti < ttiDegradedThreshold) {
    ttiSeverity = SEVERITY.DEGRADED;
  } else if (tti >= ttiDegradedThreshold) {
    ttiSeverity = SEVERITY.BLOCKING;
  } else {
    ttiSeverity = SEVERITY.NORMAL;
  }
  var ttiFromInvocationNormalThreshold = ttiFromInvocationSeverityNormalThreshold || TTI_FROM_INVOCATION_SEVERITY_THRESHOLD_DEFAULTS.NORMAL;
  var ttiFromInvocationDegradedThreshold = ttiFromInvocationSeverityDegradedThreshold || TTI_FROM_INVOCATION_SEVERITY_THRESHOLD_DEFAULTS.DEGRADED;
  var ttiFromInvocationSeverity;
  if (ttiFromInvocation >= ttiFromInvocationNormalThreshold && ttiFromInvocation < ttiFromInvocationDegradedThreshold) {
    ttiFromInvocationSeverity = SEVERITY.DEGRADED;
  } else if (ttiFromInvocation >= ttiFromInvocationDegradedThreshold) {
    ttiFromInvocationSeverity = SEVERITY.BLOCKING;
  } else {
    ttiFromInvocationSeverity = SEVERITY.NORMAL;
  }
  return {
    ttiSeverity: ttiSeverity,
    ttiFromInvocationSeverity: ttiFromInvocationSeverity
  };
}