import { Subscription } from 'rxjs/Subscription';
/**
 * This is a helper to transform the first value emitted by an MediaSubscribable into a Promise.
 *
 * @param mediaSubscribable a given MediaSubscribable<MediaSubscribableItem>
 * @param subscription a default Subscription (this parameter exists for testing purpose)
 */

export var toPromise = function toPromise(mediaSubscribable) {
  var subscription = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Subscription();
  return new Promise(function (resolve, reject) {
    return subscription.add(mediaSubscribable.subscribe({
      next: function next(state) {
        resolve(state);
        subscription.unsubscribe();
      },
      error: function error(_error) {
        reject(_error);
        subscription.unsubscribe();
      }
    }));
  });
};