import _typeof from "@babel/runtime/helpers/typeof";
export var isDefined = function isDefined(x) {
  return x != null;
};
export var isNumber = function isNumber(x) {
  return typeof x === 'number' && !isNaN(x) && isFinite(x);
};
export var isInteger = function isInteger(x) {
  return typeof x === 'number' && isFinite(x) && Math.floor(x) === x;
};
export var isBoolean = function isBoolean(x) {
  return x === true || x === false || toString.call(x) === '[object Boolean]';
};

// This is a kludge, might replace with something like _.isString in future
export var isString = function isString(s) {
  return typeof s === 'string' || s instanceof String;
};
export var isPlainObject = function isPlainObject(x) {
  return _typeof(x) === 'object' && x !== null && !Array.isArray(x);
};
export var copy = function copy(source, dest, key) {
  dest[key] = source[key];
  return dest;
};

// Helpers
export var makeArray = function makeArray(maybeArray) {
  return Array.isArray(maybeArray) ? maybeArray : [maybeArray];
};