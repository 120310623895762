import { asyncMap } from './utils';
export var blobToHashedBlob = function blobToHashedBlob(hasher) {
  return function (slicedBlob) {
    return hasher(slicedBlob.blob).then(function (hash) {
      return {
        blob: slicedBlob.blob,
        hash: "".concat(hash, "-").concat(slicedBlob.blob.size),
        partNumber: slicedBlob.partNumber
      };
    });
  };
};
export var hashinator = function hashinator(blobs$, _ref) {
  var hasher = _ref.hasher,
    concurrency = _ref.concurrency;
  return asyncMap(blobToHashedBlob(hasher), concurrency)(blobs$);
};