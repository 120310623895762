import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import memoizeOne from 'memoize-one';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { getValidDocument } from '@atlaskit/editor-common/validator';
import { validateADFEntity, findAndTrackUnsupportedContentNodes } from '@atlaskit/editor-common/utils';
import { ACTION, ACTION_SUBJECT, EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { PLATFORM } from './analytics/events';
import { trackUnsupportedContentLevels } from './analytics/unsupported-content';
import { transformMediaLinkMarks } from '@atlaskit/adf-utils/transforms';
var SUPPORTS_HIRES_TIMER_API = !!(window.performance && performance.now);
var withStopwatch = function withStopwatch(cb) {
  var startTime = SUPPORTS_HIRES_TIMER_API ? performance.now() : Date.now();
  var output = cb();
  var endTime = SUPPORTS_HIRES_TIMER_API ? performance.now() : Date.now();
  var time = endTime - startTime;
  return {
    output: output,
    time: time
  };
};
var _validation = function _validation(doc, schema, adfStage, useSpecBasedValidator, dispatchAnalyticsEvent) {
  var result;
  if (useSpecBasedValidator) {
    // link mark on mediaSingle is deprecated, need to move link mark to child media node
    // https://product-fabric.atlassian.net/browse/ED-14043
    var _transformMediaLinkMa = transformMediaLinkMarks(doc),
      transformedAdf = _transformMediaLinkMa.transformedAdf,
      isTransformed = _transformMediaLinkMa.isTransformed;
    if (isTransformed && dispatchAnalyticsEvent) {
      dispatchAnalyticsEvent({
        action: ACTION.MEDIA_LINK_TRANSFORMED,
        actionSubject: ACTION_SUBJECT.RENDERER,
        eventType: EVENT_TYPE.OPERATIONAL
      });
    }
    result = validateADFEntity(schema, transformedAdf || doc, dispatchAnalyticsEvent);
  } else {
    result = getValidDocument(doc, schema, adfStage);
  }
  if (!result) {
    return result;
  }

  // ProseMirror always require a child under doc
  if (result.type === 'doc' && useSpecBasedValidator) {
    if (Array.isArray(result.content) && result.content.length === 0) {
      result.content.push({
        type: 'paragraph',
        content: []
      });
    }

    // Just making sure doc is always valid
    if (!result.version) {
      result.version = 1;
    }
  }
  return result;
};
var memoValidation = memoizeOne(_validation, function (newArgs, lastArgs) {
  var _newArgs = _slicedToArray(newArgs, 4),
    newDoc = _newArgs[0],
    newSchema = _newArgs[1],
    newADFStage = _newArgs[2],
    newUseSpecValidator = _newArgs[3];
  var _lastArgs = _slicedToArray(lastArgs, 4),
    oldDoc = _lastArgs[0],
    oldSchema = _lastArgs[1],
    oldADFStage = _lastArgs[2],
    oldUseSpecValidator = _lastArgs[3];

  // we're ignoring changes to dispatchAnalyticsEvent in this check
  var result = areDocsEqual(newDoc, oldDoc) && newSchema === oldSchema && newADFStage === oldADFStage && newUseSpecValidator === oldUseSpecValidator;
  return result;
});
var areDocsEqual = function areDocsEqual(docA, docB) {
  if (docA === docB) {
    return true;
  }
  if (typeof docA === 'string' && typeof docB === 'string') {
    return docA === docB;
  }

  // PMNode
  if (docA.type && docA.toJSON && docB.type && docB.toJSON) {
    return JSON.stringify(docA.toJSON()) === JSON.stringify(docB.toJSON());
  }

  // Object
  return JSON.stringify(docA) === JSON.stringify(docB);
};
var _serializeFragment = function _serializeFragment(serializer, doc) {
  return serializer.serializeFragment(doc.content);
};
var memoSerializeFragment = memoizeOne(_serializeFragment, function (newArgs, lastArgs) {
  var _newArgs2 = _slicedToArray(newArgs, 2),
    newSerializer = _newArgs2[0],
    newDoc = _newArgs2[1];
  var _lastArgs2 = _slicedToArray(lastArgs, 2),
    oldSerializer = _lastArgs2[0],
    oldDoc = _lastArgs2[1];
  return newSerializer === oldSerializer && areDocsEqual(newDoc, oldDoc);
});
var _createNodeAndCheck = function _createNodeAndCheck(schema, doc, dispatchAnalyticsEvent) {
  var pmNode = schema.nodeFromJSON(doc);
  try {
    pmNode.check();
  } catch (err) {
    if (dispatchAnalyticsEvent) {
      dispatchAnalyticsEvent({
        action: ACTION.INVALID_PROSEMIRROR_DOCUMENT,
        actionSubject: ACTION_SUBJECT.RENDERER,
        attributes: {
          platform: PLATFORM.WEB,
          errorStack: err instanceof Error ? err.message : String(err)
        },
        eventType: EVENT_TYPE.OPERATIONAL
      });
    }
  }
  return pmNode;
};
var memoCreateNodeAndCheck = memoizeOne(_createNodeAndCheck, function (newArgs, lastArgs) {
  // ignore dispatchAnalyticsEvent
  var _newArgs3 = _slicedToArray(newArgs, 2),
    newSchema = _newArgs3[0],
    newDoc = _newArgs3[1];
  var _lastArgs3 = _slicedToArray(lastArgs, 2),
    oldSchema = _lastArgs3[0],
    oldDoc = _lastArgs3[1];
  return newSchema === oldSchema && areDocsEqual(newDoc, oldDoc);
});
export var renderDocument = function renderDocument(doc, serializer) {
  var schema = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultSchema;
  var adfStage = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'final';
  var useSpecBasedValidator = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var rendererId = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 'noid';
  var dispatchAnalyticsEvent = arguments.length > 6 ? arguments[6] : undefined;
  var unsupportedContentLevelsTracking = arguments.length > 7 ? arguments[7] : undefined;
  var appearance = arguments.length > 8 ? arguments[8] : undefined;
  var stat = {
    sanitizeTime: 0
  };
  var _withStopwatch = withStopwatch(function () {
      return memoValidation(doc, schema, adfStage, useSpecBasedValidator, dispatchAnalyticsEvent);
    }),
    validDoc = _withStopwatch.output,
    sanitizeTime = _withStopwatch.time;

  // save sanitize time to stats
  stat.sanitizeTime = sanitizeTime;
  if (!validDoc) {
    return {
      stat: stat,
      result: null
    };
  }
  var _withStopwatch2 = withStopwatch(function () {
      return memoCreateNodeAndCheck(schema, validDoc, dispatchAnalyticsEvent);
    }),
    node = _withStopwatch2.output,
    buildTreeTime = _withStopwatch2.time;

  // save build tree time to stats
  stat.buildTreeTime = buildTreeTime;
  var _withStopwatch3 = withStopwatch(function () {
      return memoSerializeFragment(serializer, node);
    }),
    result = _withStopwatch3.output,
    serializeTime = _withStopwatch3.time;

  // save serialize tree time to stats
  stat.serializeTime = serializeTime;
  if (dispatchAnalyticsEvent && useSpecBasedValidator) {
    findAndTrackUnsupportedContentNodes(node, schema, dispatchAnalyticsEvent);
    if (unsupportedContentLevelsTracking !== null && unsupportedContentLevelsTracking !== void 0 && unsupportedContentLevelsTracking.enabled) {
      var documentData = {
        doc: validDoc,
        appearance: appearance,
        rendererId: rendererId
      };
      trackUnsupportedContentLevels(documentData, unsupportedContentLevelsTracking, dispatchAnalyticsEvent);
    }
  }
  return {
    result: result,
    stat: stat,
    pmDoc: node
  };
};