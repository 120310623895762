import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { WidthProvider, clearNextSiblingMarginTopStyle, clearNextSiblingBlockMarkMarginTopStyle } from '@atlaskit/editor-common/ui';
var layoutColumnClearMarginTopStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  ", "\n"])), clearNextSiblingMarginTopStyle, clearNextSiblingBlockMarkMarginTopStyle);
export default function LayoutSection(props) {
  return jsx("div", {
    "data-layout-column": true,
    "data-column-width": props.width,
    style: {
      flexBasis: "".concat(props.width, "%")
    }
  }, jsx(WidthProvider, null, jsx("div", {
    css: layoutColumnClearMarginTopStyles
  }), props.children));
}