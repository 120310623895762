import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
import { css } from '@emotion/react';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { N60A, Y300, Y75 } from '@atlaskit/theme/colors';
export var annotationPrefix = 'ak-editor-annotation';
export var AnnotationSharedClassNames = {
  focus: "".concat(annotationPrefix, "-focus"),
  blur: "".concat(annotationPrefix, "-blur"),
  draft: "".concat(annotationPrefix, "-draft")
};
export var blockAnnotationPrefix = 'ak-editor-block-annotation';
export var BlockAnnotationSharedClassNames = {
  focus: "".concat(blockAnnotationPrefix, "-focus"),
  blur: "".concat(blockAnnotationPrefix, "-blur"),
  draft: "".concat(blockAnnotationPrefix, "-draft")
};
var Y75a = 'rgba(255, 240, 179, 0.5)';
var Y200a = 'rgba(255, 196, 0, 0.82)';
export var AnnotationSharedCSSByState = function AnnotationSharedCSSByState() {
  return getBooleanFF('platform.editor.allow-inline-comments-for-inline-nodes') ? {
    focus: css({
      background: "var(--ds-background-accent-yellow-subtler, ".concat(Y75, ")"),
      borderBottom: "2px solid ".concat("var(--ds-border-accent-yellow, ".concat(Y300, ")")),
      boxShadow: "var(--ds-shadow-overlay, ".concat("1px 2px 3px ".concat(N60A, ", -1px 2px 3px ").concat(N60A), ")"),
      cursor: 'pointer',
      '&:has(.card)': {
        fontSize: '1.5rem',
        lineHeight: '1.2rem',
        '& > *': {
          fontSize: '16px'
        }
      }
    }),
    blur: css({
      background: "var(--ds-background-accent-yellow-subtlest, ".concat(Y75a, ")"),
      borderBottom: "2px solid ".concat("var(--ds-border-accent-yellow, ".concat(Y200a, ")")),
      cursor: 'pointer',
      '&:has(.card)': {
        fontSize: '1.5rem',
        lineHeight: '1.2rem',
        '& > *': {
          fontSize: '16px'
        }
      }
    })
  } : {
    focus: css({
      // Background is not coming through in confluence, suspecting to be caused by some specific combination of
      // emotion and token look up
      background: "var(--ds-background-accent-yellow-subtler, ".concat(Y75, ")"),
      borderBottom: "2px solid ".concat("var(--ds-border-accent-yellow, ".concat(Y300, ")")),
      // TODO: https://product-fabric.atlassian.net/browse/DSP-4147
      boxShadow: "var(--ds-shadow-overlay, ".concat("1px 2px 3px ".concat(N60A, ", -1px 2px 3px ").concat(N60A), ")"),
      cursor: 'pointer'
    }),
    blur: css({
      background: "var(--ds-background-accent-yellow-subtlest, ".concat(Y75a, ")"),
      borderBottom: "2px solid ".concat("var(--ds-border-accent-yellow, ".concat(Y200a, ")")),
      cursor: 'pointer'
    })
  };
};

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- `AnnotationSharedCSSByState()` is not safe in object syntax
export var annotationSharedStyles = function annotationSharedStyles() {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .ProseMirror {\n    .", " {\n      ", ";\n    }\n\n    .", " {\n      ", ";\n      cursor: initial;\n    }\n\n    .", " {\n      ", ";\n    }\n  }\n"])), AnnotationSharedClassNames.focus, AnnotationSharedCSSByState().focus, AnnotationSharedClassNames.draft, AnnotationSharedCSSByState().focus, AnnotationSharedClassNames.blur, AnnotationSharedCSSByState().blur);
};