import axios from "axios";
import { ApiURLMap } from "../utils/consts";

export const getHealthcheck = () => {
    const base_url = process.env.REACT_APP_ENVIRONMENT
        ? ApiURLMap[process.env.REACT_APP_ENVIRONMENT] || ApiURLMap["development"]
        : ApiURLMap["development"];

    const uri = `${base_url}/healthcheck`;

    return axios
        .get(uri)
        .then(r => r.data)
        .catch(e => {
            throw new Error(e);
        });
};
