import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _editorBackgroundPale;
// This import will be stripped on build
import { getTokenValue } from '@atlaskit/tokens';

/**
 * This takes an adf hex color and returns a matching background palette
 * color.
 *
 * By providing a design token, this enables ADF content to be rendered in new themes such as dark mode.
 *
 * Example usage
 * ```tsx
 * const cssValue = hexToBackgroundPaletteColor('#FFFFFF');
 * //     ^? const cssValue: string
 * <div style={{backgroundColor: cssValue}} />
 * ```
 *
 * The names of tokens can change over time, and the values of tokens will differ between themes.
 * The exact output of this function is an implementation detail and should only be used when rendering
 * content to the user, on a client with a matching major version of `@atlaskit/tokens`.
 * - **DO NOT**: store the output of these functions in any user-generated content or back-end.
 * - **DO**: store the ADF hex color, and use these utilities at render time to display the themed version of the color.
 */
export function hexToEditorBackgroundPaletteColor(hexColor) {
  // Ts ignore used to allow use of conditional return type
  // (preferencing better type on consumption over safety in implementation)
  // @ts-ignore
  var tokenData = editorBackgroundPalette[hexColor.toUpperCase()];
  return tokenData ? tokenData.token : undefined;
}

/**
 * Takes an ADF hex color and returns the rendered hex code for the associated background palette design token using getTokenValue.
 * If the provided color does not exist in the Editor color palette, this function returns undefined.
 *
 * This should only be used when rendering content where CSS variables are not feasible, such as a non-CSS environment
 * or to enable cross-app copy/paste.
 *
 * WARNING: If the rendered theme changes (such as from light -> dark mode) the value returned here will no longer match
 * the surrounding UI and will need to be re-fetched.
 * In addition, the values of tokens will differ between themes and the value for a given theme can and will change.
 * - **DO NOT**: store the output of these functions in any user-generated content or back-end.
 * - **DO**: store the ADF hex color, and use these utilities at render time to display the themed version of the color.
 */
export function hexToEditorBackgroundPaletteRawValue(hexColor) {
  // Ts ignore used to allow use of conditional return type
  // (preferencing better type on consumption over safety in implementation)
  // @ts-ignore
  var tokenData = editorBackgroundPalette[hexColor.toUpperCase()];
  return tokenData ? tokenData.getValue(hexColor) : undefined;
}
// Colors taken from
// https://hello.atlassian.net/wiki/spaces/DST/pages/1790979421/DSTRFC-002+-+Shifting+Editor+s+color+palette+to+design+tokens

/**
 * Values are asserted to improve generated type declarations
 * Using object structure as getValue() function needed for table values, and other
 * properties may be needed in the future.
 */
export var editorBackgroundPalette = (_editorBackgroundPale = {}, _defineProperty(_editorBackgroundPale, '#DEEBFF', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.blue.subtlest', fallback);
  },
  token: "var(--ds-background-accent-blue-subtlest, #DEEBFF)"
}), _defineProperty(_editorBackgroundPale, '#B3D4FF', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.blue.subtler', fallback);
  },
  token: "var(--ds-background-accent-blue-subtler, #B3D4FF)"
}), _defineProperty(_editorBackgroundPale, '#4C9AFF', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.blue.subtle', fallback);
  },
  token: "var(--ds-background-accent-blue-subtle, #4C9AFF)"
}), _defineProperty(_editorBackgroundPale, '#E6FCFF', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.teal.subtlest', fallback);
  },
  token: "var(--ds-background-accent-teal-subtlest, #E6FCFF)" // source for hex code was legacy token T50,
}), _defineProperty(_editorBackgroundPale, '#B3F5FF', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.teal.subtler', fallback);
  },
  token: "var(--ds-background-accent-teal-subtler, #B3F5FF)" // source for hex code was legacy token T75,
}), _defineProperty(_editorBackgroundPale, '#79E2F2', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.teal.subtle', fallback);
  },
  token: "var(--ds-background-accent-teal-subtle, #79E2F2)" // source for hex code was legacy token T100,
}), _defineProperty(_editorBackgroundPale, '#E3FCEF', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.green.subtlest', fallback);
  },
  token: "var(--ds-background-accent-green-subtlest, #E3FCEF)" // source for hex code was legacy token G50,
}), _defineProperty(_editorBackgroundPale, '#ABF5D1', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.green.subtler', fallback);
  },
  token: "var(--ds-background-accent-green-subtler, #ABF5D1)" // source for hex code was legacy token G75,
}), _defineProperty(_editorBackgroundPale, '#57D9A3', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.green.subtle', fallback);
  },
  token: "var(--ds-background-accent-green-subtle, #57D9A3)" // source for hex code was legacy token G200,
}), _defineProperty(_editorBackgroundPale, '#FFFAE6', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.yellow.subtlest', fallback);
  },
  token: "var(--ds-background-accent-yellow-subtlest, #FFFAE6)" // source for hex code was legacy token Y50,
}), _defineProperty(_editorBackgroundPale, '#FFF0B3', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.yellow.subtler', fallback);
  },
  token: "var(--ds-background-accent-yellow-subtler, #FFF0B3)" // source for hex code was legacy token Y75,
}), _defineProperty(_editorBackgroundPale, '#FFC400', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.orange.subtle', fallback);
  },
  token: "var(--ds-background-accent-orange-subtle, #FFC400)" // source for hex code was legacy token Y200,
}), _defineProperty(_editorBackgroundPale, '#FFEBE6', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.red.subtlest', fallback);
  },
  token: "var(--ds-background-accent-red-subtlest, #FFEBE6)" // source for hex code was legacy token R50,
}), _defineProperty(_editorBackgroundPale, '#FFBDAD', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.red.subtler', fallback);
  },
  token: "var(--ds-background-accent-red-subtler, #FFBDAD)" // source for hex code was legacy token R75,
}), _defineProperty(_editorBackgroundPale, '#FF8F73', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.red.subtle', fallback);
  },
  token: "var(--ds-background-accent-red-subtle, #FF8F73)" // source for hex code was legacy token R100,
}), _defineProperty(_editorBackgroundPale, '#EAE6FF', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.purple.subtlest', fallback);
  },
  token: "var(--ds-background-accent-purple-subtlest, #EAE6FF)" // source for hex code was legacy token P50,
}), _defineProperty(_editorBackgroundPale, '#C0B6F2', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.purple.subtler', fallback);
  },
  token: "var(--ds-background-accent-purple-subtler, #C0B6F2)" // source for hex code was legacy token P75,
}), _defineProperty(_editorBackgroundPale, '#998DD9', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.purple.subtle', fallback);
  },
  token: "var(--ds-background-accent-purple-subtle, #998DD9)" // source for hex code was legacy token P100,
}), _defineProperty(_editorBackgroundPale, '#FFFFFF', {
  getValue: function getValue(fallback) {
    return getTokenValue('elevation.surface', fallback);
  },
  token: "var(--ds-surface, #FFFFFF)" // source for hex code was legacy token N0,
}), _defineProperty(_editorBackgroundPale, '#F4F5F7', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.gray.subtlest', fallback);
  },
  token: "var(--ds-background-accent-gray-subtlest, #F4F5F7)" // source for hex code was legacy token N20,
}), _defineProperty(_editorBackgroundPale, '#B3BAC5', {
  getValue: function getValue(fallback) {
    return getTokenValue('color.background.accent.gray.subtle', fallback);
  },
  token: "var(--ds-background-accent-gray-subtle, #B3BAC5)" // source for hex code was legacy token N60,
}), _editorBackgroundPale);