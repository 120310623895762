import { tap } from 'rxjs/operators/tap';
import { concatMap } from 'rxjs/operators/concatMap';
import { bufferCount } from 'rxjs/operators/bufferCount';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { slicenator } from './slicenator';
import { hashinator } from './hashinator';
import { uploadinator } from './uploadinator';
import { processinator } from './processinator';
import { fetchBlob } from './utils';
import { from } from 'rxjs/observable/from';
export var getObservableFromFile = function getObservableFromFile(file, options, callbacks) {
  return fromPromise(fetchBlob(file)).pipe(concatMap(function (blob) {
    var chunkSize = options.chunkSize;
    var onProgress = callbacks.onProgress;
    var totalChunks = Math.ceil(blob.size / chunkSize);
    var slicenatedBlobs = slicenator(blob, {
      size: chunkSize
    });
    var hashinatedBlobs = hashinator(slicenatedBlobs, {
      concurrency: options.hashingConcurrency,
      hasher: options.hashingFunction
    });
    var uploadedChunks = 0;
    var uploadedBlobs = uploadinator(hashinatedBlobs, {
      concurrency: options.uploadingConcurrency,
      uploader: options.uploadingFunction
    });
    if (onProgress) {
      uploadedBlobs = uploadedBlobs.pipe(tap(function () {
        uploadedChunks += 1;
        onProgress(uploadedChunks / totalChunks);
      }));
    }
    return processinator(uploadedBlobs, {
      batchSize: options.processingBatchSize,
      processor: options.processingFunction
    }).pipe(concatMap(function (batchedChunks) {
      return from(batchedChunks);
    }), bufferCount(totalChunks));
  }));
};
export var chunkinator = function chunkinator(file, options, callbacks) {
  return getObservableFromFile(file, options, callbacks);
};