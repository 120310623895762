/** @jsx jsx */

import { jsx, css } from '@emotion/react';
var containerCSS = css({
  minHeight: '100px'
});
var ExtensionFrame = function ExtensionFrame(props) {
  return jsx("div", {
    css: containerCSS,
    "data-extension-frame": "true",
    style: {
      flexBasis: "100%"
    }
  }, props.children);
};
export default ExtensionFrame;