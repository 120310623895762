import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export var isUndefined = function isUndefined(value) {
  return value === undefined;
};
export var pick = function pick(obj) {
  var keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  if (obj === undefined) {
    return {};
  }
  return Object.fromEntries(Object.entries(obj).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      k = _ref2[0];
    return keys.includes(k);
  }));
};
export var omitBy = function omitBy(obj, predicate) {
  return Object.fromEntries(Object.entries(obj).filter(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      v = _ref4[1];
    return !predicate(v);
  }));
};
export var debounce = function debounce(func, wait) {
  var timeout;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      return func.apply(void 0, args);
    }, wait);
  };
};
export var matches = function matches(srcObj) {
  return function (obj) {
    var key;
    for (key in srcObj) {
      if (obj[key] === undefined || obj[key] !== srcObj[key]) {
        return false;
      }
    }
    return true;
  };
};
function getRandomHexValues(byte) {
  return _toConsumableArray(Array(byte * 2)).map(function () {
    return Math.floor(Math.random() * 16).toString(16);
  }).join('');
}
export function getRandomHex(byte) {
  var _window;
  var randomHex;
  if ((_window = window) !== null && _window !== void 0 && _window.crypto) {
    try {
      randomHex = Array.from(window.crypto.getRandomValues(new Uint8Array(byte))).map(function (b) {
        return b.toString(16).padStart(2, '0');
      }).join('');
    } catch (e) {
      randomHex = getRandomHexValues(byte);
    }
  } else {
    randomHex = getRandomHexValues(byte);
  }
  return randomHex;
}