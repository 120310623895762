export var downloadUrl = function downloadUrl(url, options) {
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  var iframeName = 'media-download-iframe';
  var link = document.createElement('a');
  var iframe = document.getElementById(iframeName);
  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.id = iframeName;
    iframe.name = iframeName;
    document.body.appendChild(iframe);
  }
  link.href = url;
  if (options && options.name) {
    link.download = options.name;
  }
  link.target = isIE11 || isSafari ? '_blank' : iframeName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};