import axios from "axios";
import { ApiURLMap } from "../../utils/consts";

interface CVEMetadataObj {
    [cve_id: string]: {
        cve_summary: string;
        cve_publish_date: string;
        cve_severity: number;
        atl_tracking_url: string;
    };
}

interface CVEStatus {
    [cve_id: string]: string; //status
}

interface ProductObj {
    [product_name: string]: {
        versions: {
            [version: string]: CVEStatus[];
        };
    };
}

export interface ProductsResponse {
    products: ProductObj;
    cve_metadata: CVEMetadataObj;
}

export interface ProductVersionsResponse {
    [product_name: string]: string[]; //list of versions
}

export const getProducts = (): Promise<ProductsResponse> => {
    const base_url = process.env.REACT_APP_ENVIRONMENT
        ? ApiURLMap[process.env.REACT_APP_ENVIRONMENT] || ApiURLMap["development"]
        : ApiURLMap["development"];

    const uri = `${base_url}/v1/products`;
    let config = {};
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
        config = {
            headers: {
                Authorization: `Slauth ${process.env.SLAUTH_TOKEN}`,
            },
        };
    }
    return axios
        .get(uri, config)
        .then((response): ProductsResponse => response.data as ProductsResponse)
        .catch(e => {
            throw new Error(e);
        });
};

export const getProductVersions = (): Promise<ProductVersionsResponse> => {
    const base_url = process.env.REACT_APP_ENVIRONMENT
        ? ApiURLMap[process.env.REACT_APP_ENVIRONMENT] || ApiURLMap["development"]
        : ApiURLMap["development"];

    const uri = `${base_url}/v1/products/versions`;
    let config = {};
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
        config = {
            headers: {
                Authorization: `Slauth ${process.env.SLAUTH_TOKEN}`,
            },
        };
    }
    return axios
        .get(uri, config)
        .then((response): ProductVersionsResponse => response.data as ProductVersionsResponse)
        .catch(e => {
            throw new Error(e);
        });
};

// get versions
