import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var isUploadingFileState = function isUploadingFileState(fileState) {
  return fileState.status === 'uploading';
};
export var isProcessingFileState = function isProcessingFileState(fileState) {
  return fileState.status === 'processing';
};
export var isProcessedFileState = function isProcessedFileState(fileState) {
  return fileState.status === 'processed';
};
export var isErrorFileState = function isErrorFileState(fileState) {
  return fileState.status === 'error';
};
export var isPreviewableFileState = function isPreviewableFileState(fileState) {
  return !isErrorFileState(fileState) && !!fileState.preview;
};
export var isFinalFileState = function isFinalFileState(fileState) {
  return ['processed', 'failed-processing', 'error'].includes(fileState.status);
};
export var isImageRepresentationReady = function isImageRepresentationReady(fileState) {
  switch (fileState.status) {
    case 'processing':
    case 'processed':
    case 'failed-processing':
      return !!(fileState.representations && fileState.representations.image);
    default:
      return false;
  }
};
export var mapMediaFileToFileState = function mapMediaFileToFileState(mediaFile) {
  var _mediaFile$data = mediaFile.data,
    id = _mediaFile$data.id,
    name = _mediaFile$data.name,
    size = _mediaFile$data.size,
    processingStatus = _mediaFile$data.processingStatus,
    artifacts = _mediaFile$data.artifacts,
    mediaType = _mediaFile$data.mediaType,
    mimeType = _mediaFile$data.mimeType,
    representations = _mediaFile$data.representations,
    createdAt = _mediaFile$data.createdAt,
    metadataTraceContext = _mediaFile$data.metadataTraceContext;
  var baseState = {
    id: id,
    name: name,
    size: size,
    mediaType: mediaType,
    mimeType: mimeType,
    artifacts: artifacts,
    representations: representations,
    createdAt: createdAt,
    metadataTraceContext: metadataTraceContext
  };
  switch (processingStatus) {
    case 'pending':
    case undefined:
      return _objectSpread(_objectSpread({}, baseState), {}, {
        status: 'processing'
      });
    case 'succeeded':
      return _objectSpread(_objectSpread({}, baseState), {}, {
        status: 'processed'
      });
    case 'failed':
      return _objectSpread(_objectSpread({}, baseState), {}, {
        status: 'failed-processing'
      });
  }
};
export var mapMediaItemToFileState = function mapMediaItemToFileState(id, item) {
  return mapMediaFileToFileState({
    data: _objectSpread({
      id: id
    }, item)
  });
};