function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export function segmentText(text, highlighter) {
  if (!highlighter || !text) {
    return [{
      type: 'plain',
      text: text !== null && text !== void 0 ? text : ''
    }];
  }
  var segments = [];
  var pos = 0;
  try {
    var markTextSegmentMatches = text.matchAll(highlighter.pattern);
    var _iterator = _createForOfIteratorHelper(markTextSegmentMatches),
      _step;
    try {
      var _loop = function _loop() {
        var markTextMatch = _step.value;
        if (markTextMatch.index !== 0) {
          segments.push({
            type: 'plain',
            text: text.substring(pos, markTextMatch.index)
          });
        }
        segments.push({
          type: 'match',
          text: markTextMatch[0],
          groups: markTextMatch.groups && Object.keys(markTextMatch.groups).filter(function (key) {
            return markTextMatch.groups[key];
          })
        });
        pos = markTextMatch.index + markTextMatch[0].length;
      };
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _loop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    if (pos < text.length) {
      segments.push({
        type: 'plain',
        text: text.substring(pos)
      });
    }
    return segments;
  } catch (_e) {}
  return [{
    type: 'plain',
    text: text
  }];
}