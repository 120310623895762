import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["width", "isFixedSize", "shouldTruncate", "innerRef"];
/** @jsx jsx */

import { jsx } from '@emotion/react';
import { cellStyles, fixedSizeTruncateStyles, getTruncationStyleVars, overflowTruncateStyles, truncationWidthStyles } from './constants';

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var TableBodyCell = function TableBodyCell(_ref) {
  var width = _ref.width,
    isFixedSize = _ref.isFixedSize,
    shouldTruncate = _ref.shouldTruncate,
    innerRef = _ref.innerRef,
    props = _objectWithoutProperties(_ref, _excluded);
  return jsx("td", _extends({
    style: getTruncationStyleVars({
      width: width
    }),
    css: [truncationWidthStyles, isFixedSize && shouldTruncate && fixedSizeTruncateStyles, isFixedSize && overflowTruncateStyles, cellStyles]
    // HOC withDimensions complains about the types but it is working fine
    // @ts-ignore
    ,
    ref: innerRef
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, props));
};