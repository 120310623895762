import { easeOut } from '@atlaskit/motion/curves';
export var transitionDuration = '0.22s';
export var transitionDurationMs = 220;
export var transitionTimingFunction = easeOut;
export var widths = ['narrow', 'medium', 'wide', 'extended', 'full'];
export var animationTimingFunction = function animationTimingFunction() {
  return easeOut;
};
export var defaultFocusLockSettings = {
  isFocusLockEnabled: true,
  shouldReturnFocus: true,
  autoFocusFirstElem: true
};