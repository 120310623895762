import { InsertDraftPosition } from '../types';
export var calcInsertDraftPositionOnText = function calcInsertDraftPositionOnText(_ref, position) {
  var startTextPosition = _ref.start,
    endTextPosition = _ref.end;
  var isRangePositionAroundText = position.from <= startTextPosition && position.to >= endTextPosition;
  if (isRangePositionAroundText) {
    return InsertDraftPosition.AROUND_TEXT;
  }
  var isRangePositionInsideText = position.from > startTextPosition && position.from < endTextPosition && position.to < endTextPosition && position.to > startTextPosition;
  if (isRangePositionInsideText) {
    return InsertDraftPosition.INSIDE;
  }
  var isRangePositionStartingAtText = position.from >= startTextPosition && position.from <= endTextPosition && position.to >= endTextPosition;
  if (isRangePositionStartingAtText) {
    return InsertDraftPosition.END;
  }
  var isRangePositionEndingAtText = position.from <= startTextPosition && position.to >= startTextPosition && position.to <= endTextPosition;
  if (isRangePositionEndingAtText) {
    return InsertDraftPosition.START;
  }
  return false;
};