import { isPerformanceAPIAvailable } from './is-performance-api-available';
var measureMap = new Map();
export function startMeasure(measureName) {
  if (!isPerformanceAPIAvailable()) {
    return;
  }
  performance.mark("".concat(measureName, "::start"));
  measureMap.set(measureName, performance.now());
}
export function stopMeasure(measureName, onMeasureComplete) {
  if (!isPerformanceAPIAvailable()) {
    return;
  }
  performance.mark("".concat(measureName, "::end"));
  var start = onMeasureComplete ? measureMap.get(measureName) : undefined;
  try {
    performance.measure(measureName, "".concat(measureName, "::start"), "".concat(measureName, "::end"));
  } catch (error) {} finally {
    if (onMeasureComplete) {
      var entry = performance.getEntriesByName(measureName).pop();
      if (entry) {
        onMeasureComplete(entry.duration, entry.startTime);
      } else if (start) {
        onMeasureComplete(performance.now() - start, start);
      }
    }
    clearMeasure(measureName);
  }
}
export function clearMeasure(measureName) {
  if (!isPerformanceAPIAvailable()) {
    return;
  }
  measureMap.delete(measureName);
  performance.clearMarks("".concat(measureName, "::start"));
  performance.clearMarks("".concat(measureName, "::end"));
  performance.clearMeasures(measureName);
}